import React from 'react';


import GroupsList from '../Groups/List';
import KeywordsGroups from '../Keywords/Groups';
import AllArticles from '../Groups/AllArticles';
import Wiki from '../Keywords/Wiki';
import Search from '../Navigation/Search';
import Graph from '../Timeline/Graph';

import U from '../Utilities/Utilities';
import API from '../Utilities/API';

import { Tag, Navbar, NavbarGroup, Text } from '@blueprintjs/core';

import Linkify from 'react-linkify';

import extractDomain from "extract-domain";

class Home extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            widthLeft: 2,
            widthCenter: 3,
            widthRight: 2,
            fullList: true,
            group: {},
            updateCount: 1,
            navUpdateCount: 1,
            selectedCountry: this.props.selectedCountry || {
                name: 'All Countries'
            },
            selectedLanguage: this.props.selectedLanguage || {
                name: 'All Languages'
            },
            selectedKeywords: this.props.selectedKeywords || [],
            query: this.props.query || '',
            highlightGroup: 0,
            selectedKeyword: false,
            errored: [],
            groupLoaded: false,
            groupsOnly: this.props.groupsOnly,
            isSearch: false,
            type: this.props.type || 'or',
            range: this.props.range || [null, null]
        }

        this.toggleFull = this.toggleFull.bind(this);
        this.setCurrentGroup = this.setCurrentGroup.bind(this);
        this.expandGroups = this.expandGroups.bind(this);

        this.addKeyword = this.addKeyword.bind(this);
        this.setKeyword = this.setKeyword.bind(this);

        this.fadeTimeout = 0;
        this.scrollToCurrentGroup = this.scrollToCurrentGroup.bind(this);
        this.fadeSelected = this.fadeSelected.bind(this);

        this.search = this.search.bind(this);

        this.duplicate = this.duplicate.bind(this);

        this.groupLoaded = this.groupLoaded.bind(this);
        this.setGroupsOnly = this.setGroupsOnly.bind(this);
    }

    errored(id) {
        let e = this.state.errored;
        e.push(id);
        this.setState({
            errored: e
        })
    }

    scrollToCurrentGroup() {
        if (this.state.group.group && this.state.group.group.id) {
            document.getElementById('SingleGroup' + this.state.group.group.id).scrollIntoView()
        }
    }

    toggleFull() {
        this.setState({
            fullList: !this.state.fullList,
        }, () => {
            this.scrollToCurrentGroup();
        })
    }


    setFull(full) {
        this.setState({
            fullList: full
        }, () => {
            this.scrollToCurrentGroup();
        })
    }

    setCurrentGroup(group) {
        if (this.state.group.group && this.state.group.group.id == group.group.id) {
            const openedID = this.state.group.group.id;
            this.setState({
                group: {},
                groupLoaded: false,
                fullList: true,
                highlightGroup: this.state.group.group.id
            }, () => {
                this.scrollToCurrentGroup();
                document.querySelector('.active-page #groupScroller').scrollTop -= 80;
                this.fadeSelected();
            });
        } else {
            this.setState({
                group: group,
                groupLoaded: false,
                fullList: false,
                highlightGroup: 0
            }, () => {
                this.scrollToCurrentGroup();
                if (document.querySelector('.active-page #groupScroller')) {
                    document.querySelector('.active-page #groupScroller').scrollTop -= 80;
                }
                this.fadeSelected();
            })
        }
    }

    groupLoaded() {
        this.setState({
            groupLoaded: true
        })
    }

    expandGroups() {
        this.setState({
            fullList: !this.state.fullList,
            highlightGroup: this.state.group.group ? this.state.group.group.id : 0,
            group: !this.state.fullList ? {} : this.state.group
        }, () => {
            if (this.state.group.group) {
                this.scrollToCurrentGroup();
                if (document.querySelector('.active-page #groupScroller')) {
                    document.querySelector('.active-page #groupScroller').scrollTop -= 80;
                }
                this.fadeSelected();
            }
            else if (this.state.highlightGroup) {
                let el = document.getElementById('SingleGroup' + this.state.highlightGroup);
                if (el) el.scrollIntoView();
                if (document.querySelector('.active-page #groupScroller')) {
                    document.querySelector('.active-page #groupScroller').scrollTop -= 80;
                }
                this.fadeSelected();
            }
        })
    }

    fadeSelected() {
        clearTimeout(this.fadeTimeout);
        this.fadeTimeout = setTimeout(() => {
            const el = document.querySelector('.active-page #groupScroller');
            if (!el) return false;
            const scroll = el.scrollTop;
            this.setState({
                highlightGroup: 0
            }, () => {
                setTimeout(function () {
                    let el = document.querySelector('.active-page #groupScroller');
                    if (el) el.scrollTop = scroll;
                });
            })
        }, 5000);
    }

    addKeyword(id) {
        this.setState({
            query: id,
            navUpdateCount: this.state.navUpdateCount + 1
        })
    }

    setKeyword(id) {
        this.setState({
            selectedKeyword: id
        })
    }

    search(country, language, keywords, lastq, type, range) {
        this.setState({
            fullList: true,
            group: {},
            updateCount: this.state.updateCount + 1,
            selectedCountry: country,
            selectedLanguage: language,
            selectedKeywords: keywords,
            type: type,
            range: range,
            isSearch: true
        });
    }

    duplicate() {
        const nid = 'home-' + new Date().getTime();
        this.props.addPage({
            id: nid,
            title: false,
            filter: {
                selectedCountry: this.state.selectedCountry,
                selectedLanguage: this.state.selectedLanguage,
                selectedKeywords: this.state.selectedKeywords,
                type: this.state.type,
                range: this.state.range
            },
            component: 'Home',
            props: {
                key: nid,
                selectedCountry: this.state.selectedCountry,
                selectedLanguage: this.state.selectedLanguage,
                selectedKeywords: this.state.selectedKeywords,
                type: this.state.type,
                hideSearch: true,
                range: this.state.range
            }
        })
    }

    setGroupsOnly(go) {
        this.setState({
            groupsOnly: go
        })
    }

    render() {
        const maxPercent = this.props.showGraph && this.state.fullList && !this.state.groupsOnly ? '80%' : '100%';
        return (
            <div style={{ height: '100%', width: '100%' }}>
                {this.props.hideSearch ? null : (
                    <Navbar className={'sub-nav'}>
                        <NavbarGroup className={'search-group'}>
                            <Search
                                search={this.search}
                                addPage={this.duplicate}
                                selectedCountry={this.state.selectedCountry}
                                selectedLanguage={this.state.selectedLanguage}
                                selectedKeywords={this.state.selectedKeywords}
                                type={this.state.type}
                                range={this.state.range}
                                searchButtons={this.props.searchButtons}
                                saveFunction={this.props.saveFunction}
                            />
                        </NavbarGroup>
                    </Navbar>
                )}
                <div style={{ width: '100%', height: this.props.hideSearch ? maxPercent : 'calc(' + maxPercent + ' - 50px)' }} className={this.state.fullList ? 'list-expanded' : 'list-not-expanded'}>
                    <div style={{ width: this.state.fullList ? '100%' : (window.screen.width < 1200 ? '100%' : '27%'), height: '100%', float: 'left' }}>
                        <GroupsList
                            toggleFull={this.toggleFull}
                            setCurrentGroup={this.setCurrentGroup}
                            currentGroup={this.state.group}
                            expandGroups={this.expandGroups}
                            expanded={this.state.fullList}
                            key={`update${this.state.updateCount}`}
                            country={this.state.selectedCountry}
                            language={this.state.selectedLanguage}
                            type={this.state.type}
                            range={this.state.range}
                            keywords={this.state.selectedKeywords}
                            highlightGroup={this.state.highlightGroup}
                            setNumber={this.props.setNumber}
                            setLoading={this.props.setLoading}
                            groupsOnly={this.props.groupsOnly}
                            hideGroupsOnly={this.props.hideGroupsOnly}
                            setGroupsOnly={this.setGroupsOnly}
                            number={this.props.number}
                            newList={this.props.newList}
                            newData={this.props.newData}
                            addToDraft={this.props.addToDraft}
                            removeFromDraft={this.props.removeFromDraft}
                            isInDraft={this.props.isInDraft}
                            data={this.props.data}
                            hideMenu={this.props.hideMenu}
                            noUpdate={this.props.noUpdate}
                            groupButtons={this.props.groupButtons}
                            titleButtons={this.props.titleButtons}
                            removeFromCase={this.props.removeFromCase}
                            isSearch={this.state.isSearch}
                            setRequestID={this.props.setRequestID}
                            hideMore={this.props.hideMore}
                            titleMenuButtons={this.props.titleMenuButtons}
                        />
                    </div>
                    {this.state.fullList ? null : (
                        <div style={{ width: '100%', height: '100%' }}>
                            <div style={{ width: '46%', height: '100%', float: 'left', overflowY: 'auto', overflowX: 'hidden', position: 'relative' }} className={'hide1300 newsScroller'}>
                                {this.state.group.group ? (
                                    <>
                                        {this.state.groupLoaded ? null : (
                                            <>
                                                <div style={{ padding: 10, width: '100%', clear: 'both' }}>
                                                    <h1 style={{ marginBottom: 0 }}>{U.strip_tags(this.state.group.news[0].title)}</h1>
                                                    <a style={{ marginBottom: 10, display: 'block' }} href={this.state.group.news[0].original_link} target={'_blank'}>{this.state.group.news[0].original_link}</a>
                                                    <div style={{ width: '100%' }}>
                                                        {this.state.errored.indexOf(this.state.group.news[0].id) == -1 && this.state.group.news[0].image.length ? (
                                                            <div className={'small-image'} style={{ backgroundImage: `url('${this.state.group.news[0].image}')`, marginRight: 10 }}>
                                                                <Tag fill interactive minimal style={{ marginTop: 5 }}>{extractDomain(this.state.group.news[0].image)}</Tag>
                                                                <img src={this.state.group.news[0].image} style={{ visibility: 'hidden' }} onError={this.errored.bind(this, this.state.group.news[0].id)} />
                                                            </div>
                                                        ) : null}
                                                        <div style={{ width: 'calc(100% - 170px)', float: 'left', marginRight: 0 }}>
                                                            <Text style={{ whiteSpace: 'pre-wrap' }}>
                                                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                    <a target="blank" href={decoratedHref} key={key}>
                                                                        {decodeURI(decoratedText)}
                                                                    </a>)}>
                                                                    {<span dangerouslySetInnerHTML={{ __html: this.state.group.news[0].text.trim() }}></span>}
                                                                </Linkify>
                                                            </Text>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div style={{ clear: 'both' }}></div>
                                            </>
                                        )}
                                        <AllArticles key={this.state.group.group.id} group_id={this.state.group.group.id} groupLoaded={this.groupLoaded} />
                                    </>
                                ) : null}
                            </div>
                            <div className="scroll-marker" />
                            <div style={{ width: '27%', height: '100%', float: 'left' }} className={'hide1300'}>
                                <div style={{ width: '100%', height: '50%', backgroundColor: '#30404d' }}>
                                    <Wiki keyword={this.state.selectedKeyword} key={this.state.selectedKeyword} />
                                </div>
                                <div style={{ width: '100%', height: '50%', backgroundColor: '#30404d' }}>
                                    <KeywordsGroups
                                        addKeyword={this.setKeyword}
                                        group={this.state.group}
                                        key={this.state.group.group ? this.state.group.group.id : ''}
                                        selectedKeywords={this.state.selectedKeywords}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {this.props.showGraph && this.state.fullList && !this.state.groupsOnly ? (
                    <div style={{ width: '100%', height: '20%' }}>
                        <Graph keywords={this.state.selectedKeywords} key={`Graph-${this.props.pageID}`} />
                    </div>
                ) : null}
            </div>
        )
    }

}

export default Home;