import React from 'react';

import API from '../Utilities/API';
import SingleResponse from '../AI/SingleResponse';

class GroupMonitor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            newData: [],
            newList: [],
            loaded: false,
            loading: false,
            lastDate: null,
            newCount: 0,
            response_id: ''
        }

        this.loadData = this.loadData.bind(this);
        this.loadNew = this.loadNew.bind(this);
    }

    loadData() {
        if (!this.props.filter) return false;
        if (this.props.setLoading) this.props.setLoading(true);
        API.getGroupsList({
            older: this.state.lastDate,
            country: this.props.filter && this.props.filter.selectedCountry ? this.props.filter.selectedCountry.id : null,
            language: this.props.filter && this.props.filter.selectedLanguage ? this.props.filter.selectedLanguage.id : null,
            keywords: this.props.filter && this.props.filter.selectedKeywords ? this.props.filter.selectedKeywords.map((k) => (k.id)) : null,
            kwtype: this.props.type,
            range: this.props.range
        }).then((response) => {
            if (response && response.data && response.data.Data) {
                this.setState({
                    data: this.state.data.concat(response.data.Data),
                    lastDate: response.data.Data && response.data.Data.length ? response.data.Data[response.data.Data.length - 1].group.created : '',
                    loaded: true,
                    loading: false,
                    response_id: response.data.ResponseID && response.data.ResponseID.length > 0 ? response.data.ResponseID : this.state.response_id
                }, () => {
                    this.loadNew();
                    if (this.props.setLoading) this.props.setLoading(false);
                    if (this.props.setRequestID) this.props.setRequestID(this.state.response_id);
                })
            } else {
                this.loadNew();
                if (this.props.setLoading) this.props.setLoading(false);
                if (this.props.setRequestID) this.props.setRequestID(this.state.response_id);
            }
        });
    }

    loadNew() {
        if (!this.props.filter) return false;
        if (this.state.data.length === 0) return false;
        if (this.props.range && this.props.range[0] && this.props.range[1]) return false;
        let firstDate = this.state.data[0].group.created;
        API.getGroupsList({
            newer: firstDate,
            country: this.props.filter && this.props.filter.selectedCountry ? this.props.filter.selectedCountry.id : null,
            language: this.props.filter && this.props.filter.selectedLanguage ? this.props.filter.selectedLanguage.id : null,
            keywords: this.props.filter && this.props.filter.selectedKeywords ? this.props.filter.selectedKeywords.map((k) => (k.id)) : null,
            kwtype: this.props.type
        }).then((response) => {
            if (response && response.data && response.data.Data) {
                if (response.data.Data && response.data.Data.length > 0 && response.data.Data.length != this.state.newData.length) {
                    this.setState({
                        newData: response.data.Data,
                        response_id: response.data.ResponseID && response.data.ResponseID.length > 0 ? response.data.ResponseID : this.state.response_id
                    }, () => {
                        if (this.props.setNumber) this.props.setNumber(this.state.newData.length, this.state.newData);
                        if (this.props.setRequestID) this.props.setRequestID(this.state.response_id);
                    })
                }
            }
        });
    }

    componentDidMount() {
        if (!this.state.loaded) this.loadData();
        this.newInterval = setInterval(() => {
            this.loadNew()
        }, 45 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.newInterval);
    }


    render() {
        return null;
    }
}

export default GroupMonitor;