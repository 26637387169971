import React from 'react';
import Uploads from '../Utilities/Uploads';

import UploadListSingle from './ListSingle';

import { Navbar, NavbarGroup, NavbarHeading, NavbarDivider } from '@blueprintjs/core';

class UploadList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            uploads: Uploads.List()
        }

        this.Reload = this.Reload.bind(this);
    }

    componentDidMount() {
        Uploads.onChange(this.Reload);
        this.Reload();
    }

    Reload() {
        this.setState({
            uploads: Uploads.List().filter((u) => (u.id || u.path))
        }, () => {
            Uploads.List();
            if (this.props.setNumber) this.props.setNumber(this.state.uploads.filter((u) => (!u.canceled && u.size > 0)).length);
        })
    }

    render() {
        return (
            <>
                {this.props.showTitle && this.state.uploads.length ? <h3 style={{ marginLeft: 10 }}>Uploads</h3> : (
                    this.props.hideTopBar ? null : <Navbar style={{ position: 'sticky', top: 0, paddingLeft: 0 }}>
                        <NavbarGroup>
                            <NavbarHeading>Upload</NavbarHeading>
                            <NavbarDivider />
                        </NavbarGroup>
                    </Navbar>
                )}
                {this.props.hideTopBar && this.state.uploads.length === 0 ? null : (
                    <>
                        <table class="bp5-html-table bp5-interactive bp5-html-table-striped">
                            <thead>
                                <tr>
                                    <th style={{ paddingTop: 16, paddingBottom: 15, textAlign: 'center' }}>Date</th>
                                    <th style={{ paddingTop: 16, paddingBottom: 15 }}>File</th>
                                    <th></th>
                                    <th style={{ width: 500, textAlign: 'right', paddingTop: 16, paddingBottom: 15 }}>Size</th>
                                    <th style={{ textAlign: 'center', paddingTop: 16, paddingBottom: 15 }}>Uploaded</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.uploads.map((file, i) => (
                                    file.upload.canceled ? null : <UploadListSingle key={file.id || i}
                                        button={this.props.button ? React.cloneElement(this.props.button, {
                                            onClick: () => {
                                                this.props.button.props.clicked(file.id);
                                            }
                                        }) : null}
                                        file={file}
                                        cancel={() => {
                                            if (window.confirm('Cancel upload?')) {
                                                Uploads.Cancel(i)
                                            }
                                        }}
                                    />
                                ))}
                            </tbody>
                        </table>
                        <div style={{ height: 50 }}></div>
                    </>
                )}
            </>
        )
    }
}

export default UploadList;