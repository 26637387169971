import React, { useState, useEffect } from 'react';

import API from '../Utilities/API';
import { Button, ButtonGroup } from '@blueprintjs/core';

export default ({ request, onDone, onStart, active }) => {

    const text = request.text;

    return (
        <>
            <ButtonGroup fill >

                <Button
                    fill
                    large
                    disabled={!active}
                    onClick={() => {
                        onStart && onStart();
                        API.AI.Translation(text).then((response) => {
                            onDone && onDone();
                        })
                    }}
                >Translation</Button>

                <Button
                    fill
                    large
                    disabled={!active}
                    onClick={() => {
                        onStart && onStart();
                        API.AI.Analysis(text).then((response) => {
                            onDone && onDone();
                        })
                    }}
                >Analysis</Button>

                <Button
                    fill
                    large
                    disabled={!active}
                    onClick={() => {
                        onStart && onStart();
                        API.AI.Titles(text).then((response) => {
                            onDone && onDone();
                        })
                    }}
                >Titles</Button>

                <Button
                    fill
                    large
                    disabled={!active}
                    onClick={() => {
                        onStart && onStart();
                        API.AI.Incipit(text).then((response) => {
                            onDone && onDone();
                        })
                    }}
                >Incipit</Button>

                <Button
                    fill
                    large
                    disabled={!active}
                    onClick={() => {
                        onStart && onStart();
                        API.AI.Highlights(text).then((response) => {
                            onDone && onDone();
                        })
                    }}
                >Highlights</Button>

                <Button
                    fill
                    large
                    disabled={!active}
                    onClick={() => {
                        onStart && onStart();
                        API.AI.Contradictions(text).then((response) => {
                            onDone && onDone();
                        })
                    }}
                >Contradict.</Button>

                <Button
                    fill
                    large
                    disabled={!active}
                    onClick={() => {
                        onStart && onStart();
                        API.AI.Website(text).then((response) => {
                            onDone && onDone();
                        })
                    }}
                >Site</Button>

            </ButtonGroup>
        </>
    )

}