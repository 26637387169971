import KeywordRequest from './Request';

import { useState } from 'react'

import { Button } from '@blueprintjs/core'

import { Tooltip2 } from "@blueprintjs/popover2";

export default function () {

    const [show, setShow] = useState(false);

    return (
        <>
            {show && (
                <KeywordRequest
                    onHide={() => {
                        setShow(false);
                    }}
                />
            )}
            <div style={{ marginLeft: 4, marginTop: 5, position: 'absolute', left: 0, bottom: 192 }}>
                <Tooltip2 content={'Request keyword'} hoverOpenDelay={0} >
                    <Button
                        onClick={() => {
                            setShow(true)
                        }}
                        icon={'new-text-box'}
                        large
                    >
                    </Button>
                </Tooltip2>
            </div>
        </>
    )
}