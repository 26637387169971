import React, { Component } from 'react';

import API from '../Utilities/API';
import U from '../Utilities/Utilities';

import { Card, Spinner, Navbar, NavbarGroup } from '@blueprintjs/core';

import { SocialIcon } from 'react-social-icons';

import Linkify from 'react-linkify';


class Wiki extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            data: {
                id: '',
                title: '',
                image: '',
                description: ''
            }
        }

        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        if (!this.state.loaded && this.props.keyword) {
            U.removeKeywordMarkers();
            this.loadData();
        }
    }

    loadData() {
        API.Wiki(this.props.keyword).then((response) => {
            this.setState({
                loaded: true,
                data: response.data.Data
            }, () => {
                U.unhighlightKeywords();
                U.highlightKeyword(response.data.Data.keyword_id);
                U.showKeywordMarkers();
            })
        })
    }

    render() {

        const links = [
            'facebook',
            'twitter',
            'email',
            'instagram',
            'site',
            'telegram',
            'skype',
            'linkedin',
            'wikipedia'
        ];

        return this.state.loaded ? (
            <>
                <Navbar className={'scrollX1300 fakeBar'} style={{ textAlign: 'right', position: 'sticky', top: 1 }}>
                    <NavbarGroup>
                        {links.map((network) => (
                            this.state.data[network] && this.state.data[network].length
                                ?
                                (network === 'wikipedia' ? (
                                    <a target="_blank" href={this.state.data[network]} class="social-icon" style={{ "display": "inline-block", "width": "25px", "height": "25px", "position": "relative", "overflow": "hidden", "vertical-align": "middle", "margin-right": "10px" }}>
                                        <div class="social-container" style={{ "position": "absolute", "top": "0px", "left": "0px", "width": "100%", "height": "100%" }}>
                                            <img src={'/wikipedia.svg'} style={{ height: '100%', width: '100%' }} />
                                        </div>
                                    </a>
                                ) : (
                                    <SocialIcon target={'_blank'} url={this.state.data[network]} style={{ height: 25, width: 25, marginRight: 10 }} />
                                )) : null

                        ))}
                    </NavbarGroup>
                </Navbar>
                <Card style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'horizontal' }}>
                        {this.state.data.image && this.state.data.image.length ? (
                            <div style={{ display: 'flex', flex: 1 }}>
                                <div style={{ display: 'block', width: '100%', padding: 10, paddingTop: 17 }}>
                                    <img src={this.state.data.image} style={{ width: '100%' }} />
                                </div>
                            </div>
                        ) : null}
                        <div style={{ display: 'flex', flex: 3 }}>
                            <div style={{ display: 'block' }}>
                                <h2 style={{ marginTop: 10, marginBottom: 5 }}>{this.state.data.title}</h2>
                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                    <a target="blank" href={decoratedHref} key={key}>
                                        {decodeURI(decoratedText)}
                                    </a>)}>
                                    {this.state.data.description}
                                </Linkify>
                            </div>
                        </div>
                    </div>
                    <a
                        href={`https://etleboro.org/wiki/${this.state.data.id}`}
                        className={'bp5-button bp5-button bp5-intent-primary'}
                        target={'_blank'}
                        style={{ float: 'right', display: 'none' }}>
                        <div class="bp5-button-text">Wiki</div>
                        <span icon="share" aria-hidden="true" class="bp5-icon bp5-icon-share"><svg data-icon="share" width="16" height="16" viewBox="0 0 16 16"><path d="M10.99 13.99h-9v-9h4.76l2-2H.99c-.55 0-1 .45-1 1v11c0 .55.45 1 1 1h11c.55 0 1-.45 1-1V7.24l-2 2v4.75zm4-14h-5c-.55 0-1 .45-1 1s.45 1 1 1h2.59L7.29 7.28a1 1 0 00-.3.71 1.003 1.003 0 001.71.71l5.29-5.29V6c0 .55.45 1 1 1s1-.45 1-1V1c0-.56-.45-1.01-1-1.01z" fill-rule="evenodd"></path></svg></span>
                    </a>
                </Card>
            </>
        ) : (this.props.keyword ? <Spinner /> : null);
    }
}

export default Wiki;