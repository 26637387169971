import React, { useState } from 'react';

import API from '../Utilities/API';
import U from '../Utilities/Utilities';
import IP from '../Utilities/IP';

import { Spinner } from '@blueprintjs/core'

export default (props) => {

    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);

    if (!loaded) {
        API.LinkViews(props.link_id).then((response) => {
            setLoaded(true);
            setData(response.data.Data || []);
        })
    }

    return loaded ? (
        <>
            <table class="bp5-html-table bp5-html-table-striped bp5-interactive" style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th style={{ width: 100, textAlign: 'center' }}>Date/Time</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((l) => (
                        <>
                            <tr>
                                <td style={{ width: 100, textAlign: 'center' }}>{U.prettyStampFull(l.datetime)}</td>
                                <td>
                                    Browser: {l.data.browser}<br />
                                    IP: {l.data.ip} <br />
                                    Location: <IP ip={l.data.ip} hideIP />
                                </td>
                            </tr>
                        </>
                    ))}
                </tbody>
            </table>
        </>
    ) : <Spinner />;

}