import React, { useState, useEffect } from 'react';

import { Drawer, Position, DrawerSize, Spinner, Classes } from '@blueprintjs/core';

import API from '../Utilities/API';
import U from '../Utilities/Utilities';

export default ({ id, onClose }) => {

    const [loaded, setLoaded] = useState(false);
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        if (!loaded && id) {
            API.ExpandGroup(id).then((response) => {
                setArticles(response.data.Data);
                setLoaded(true);
            })
        }
    }, [loaded])

    return (
        <Drawer
            icon={'clip'}
            title={'Expand group'}
            autoFocus
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            enforceFocus
            position={Position.LEFT}
            size={DrawerSize.LARGE}
            onClose={() => {
                onClose();
            }}
            isOpen={true}
            className={Classes.DARK}
        >{
                loaded ? (
                    <div style={{
                        width: '100%',
                        height: '100%',
                        overflow: 'auto'
                    }}>
                        {articles.map((news) => (
                            <div style={{ marginTop: 10, marginBottom: 10, borderBottom: '1px solid white', padding: 10 }}>
                                <div style={{ fontWeight: 'bold' }}>{U.prettyStampFull(news.timestamp)} - {news.title}</div>
                                <a rel="noreferrer noopener" href={news.original_link} target={'_blank'}>{news.original_link}</a>
                                <pre style={{ whiteSpace: 'pre-line' }}>{news.intro}</pre>
                            </div>
                        ))}
                    </div>
                ) : <Spinner />}
        </Drawer>
    );

}