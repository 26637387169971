import React, { useState, useEffect } from 'react';

import { Button, Drawer, Position, DrawerSize, Classes } from '@blueprintjs/core'

import MediaWindow from './Window'

export default (props) => {

    const [showDrawer, setShowDrawer] = useState(false)

    return (
        <>
            <Button icon={'media'} onClick={() => {
                if (props.onClick) props.onClick();
                setShowDrawer(true)
            }} />
            <Drawer
                icon={'media'}
                title={'Browse media'}
                autoFocus
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                enforceFocus
                hasBackdrop
                position={Position.TOP}
                size={DrawerSize.LARGE}
                onClose={() => {
                    setShowDrawer(false)
                }}
                isOpen={showDrawer}
                className={'bp5-dark'}
            >
                <MediaWindow addToCase={(id, title) => {
                    if (props.addToCase) props.addToCase(id, title);
                    setShowDrawer(false);
                }} />
            </Drawer>
        </>
    )

}