import { MenuItem, Tag } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import { TurnedIn } from "@material-ui/icons";
import { useState, useEffect } from 'react'

export default (props) => {
    const NoteSelect = MultiSelect.ofType();

    const [selected, setSelected] = useState(props.selected && props.notes && props.notes.filter((note) => (props.selected.indexOf(note.id) > -1)) || []);
    const [notes, setNotes] = useState(props.notes || []);
    const [changed, setChanged] = useState(false);

    useEffect(() => {
        if (changed) {
            props.onSelectedChange && props.onSelectedChange(selected.map((s) => (s.id)));
        }
    }, [selected])

    return (
        <>
            <NoteSelect
                fill
                large
                resetOnSelect
                tagMinimal
                matchTargetWidth
                intent={'warning'}
                tagInputProps={{
                    minimal: true,
                    intent: 'warning',
                    tagProps: {
                        intent: 'warning',
                        minimal: true
                    }
                }}
                placeholder={'Select notes to assign to'}
                itemRenderer={(item, { handleClick }) => {
                    return (
                        <MenuItem
                            icon={null}
                            key={item.id}
                            text={item.title}
                            shouldDismissPopover
                            onClick={handleClick}
                        />
                    );
                }}
                tagRenderer={(item) => (item.title)}
                items={notes}
                selectedItems={selected}
                onItemSelect={(item) => {
                    setChanged(true);
                    setSelected([
                        ...selected,
                        item
                    ])
                }}
                onRemove={(item) => {
                    setChanged(true);
                    setSelected(selected.filter((selected_item) => (selected_item.id != item.id)));
                }}
                menuProps={{ className: 'limit-popup' }}
            />
        </>
    )
}