import React from 'react';

import { ControlGroup, Spinner, Button, Input } from '@blueprintjs/core'

import Uploads from '../Utilities/Uploads';

class Paste extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }

        this.onPaste = this.onPaste.bind(this);
    }

    onPaste(e) {
        e.preventDefault();
        for (let i = 0; i < e.clipboardData.items.length; ++i) {
            let item = e.clipboardData.items[i];
            if (item.kind === 'file') {
                this.props.hide();
                Uploads.Add([item.getAsFile()]);
            }
        }
    }

    render() {
        return (
            <>
                <ControlGroup>
                    <input class="bp5-input bp5-large bp5-fill" type="text" placeholder="Paste here" onPaste={this.onPaste} dir="auto" />
                    <Button icon={'cross'} style={{ paddingLeft: 15, paddingRight: 15 }} onClick={this.props.hide} />
                </ControlGroup>
            </>
        )
    }
}

export default Paste;