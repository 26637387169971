import React, { useState, useEffect } from 'react';

import { Button, Collapse, Classes, InputGroup, TextArea, ControlGroup } from '@blueprintjs/core';
import Summary from '../Utilities/Summary';

import LanguageSelect from '../Language/LanguageSelect'

export default (props) => {
    const [show, setShow] = useState(props.show || false);

    const [newTitle, setNewTitle] = useState(props.title || '');
    const [newText, setNewText] = useState(props.text || '');
    const [language, setLanguage] = useState(props.language || {
        name: 'Italian',
        shortcode: 'it'
    });

    const LOCAL_NAME = `ND_SAVEDTEXT_${props.content_id ?? '0'}_${props.note_id ?? '0'}`;

    useEffect(() => {
        const storageTitle = localStorage.getItem(`${LOCAL_NAME}_title`);
        if (storageTitle && storageTitle.length > 0) setNewTitle(storageTitle);

        try {
            setLanguage(localStorage.getItem(`${LOCAL_NAME}_language`) ? JSON.parse(localStorage.getItem(`${LOCAL_NAME}_language`)) : {
                name: 'Italian',
                shortcode: 'it'
            });
        } catch (e) {
            //
        }

        const storageText = localStorage.getItem(`${LOCAL_NAME}_text`)
        if (storageText && storageText.length > 0) setNewText(storageText);

        if (localStorage.getItem(`${LOCAL_NAME}_editting`) == '1') {
            setShow(true);
        }

    }, []);

    useEffect(() => {
        if (show) localStorage.setItem(`${LOCAL_NAME}_editting`, '1');
        else localStorage.removeItem(`${LOCAL_NAME}_editting`);
    }, [show]);

    const clearLocalCache = () => {
        localStorage.removeItem(`${LOCAL_NAME}_title`);
        localStorage.removeItem(`${LOCAL_NAME}_language`);
        localStorage.removeItem(`${LOCAL_NAME}_text`);
        localStorage.removeItem(`${LOCAL_NAME}_editting`);
    }

    return (
        <>
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row'
            }}>

                {!props.hideNew && (
                    <div style={{
                        flexGrow: 1
                    }}>
                        <Button icon={'add'} onClick={() => {
                            setShow(!show)
                        }} text={'New note'} />
                    </div>
                )}

                {props.rightHtml && (
                    <div style={{
                        flexGrow: 1,
                        textAlign: 'right'
                    }}>{props.rightHtml}</div>
                )}
            </div>

            {props.summary ? (
                <div style={{ clear: 'both' }}>
                    <Summary
                        summaryData={props.summary}
                        auto={false}
                        visible={!!props.summary}
                    />
                </div>
            ) : null}
            <Collapse isOpen={show}>
                <div style={{ marginTop: 20 }}>
                    <p>
                        <ControlGroup fill={true}>
                            <InputGroup
                                value={newTitle}
                                onChange={(v) => {
                                    localStorage.setItem(`${LOCAL_NAME}_title`, v.target.value);
                                    setNewTitle(v.target.value)
                                }}
                                placeholder={'Note title'}
                                style={{
                                    marginBottom: 10
                                }}
                            />
                            <LanguageSelect
                                style={{ maxWidth: 300, maxHeight: 30 }}
                                language={language}
                                onChange={(language) => {
                                    localStorage.setItem(`${LOCAL_NAME}_language`, JSON.stringify(language));
                                    setLanguage(language);
                                }}
                            />
                        </ControlGroup>
                    </p>
                    <p>
                        <TextArea
                            large
                            value={newText}
                            onChange={(v) => {
                                localStorage.setItem(`${LOCAL_NAME}_text`, v.target.value);
                                setNewText(v.target.value)
                            }}
                            placeholder={'Note text'}
                            fill
                            style={{
                                height: 300
                            }}
                        />
                    </p>
                </div>
                <div style={{ marginBottom: 20 }}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            onClick={() => {
                                setShow(false);
                                setNewTitle('');
                                setNewText('');
                                setLanguage({
                                    name: 'Italian',
                                    shortcode: 'it'
                                });
                                clearLocalCache();
                                props.onClose && props.onClose();
                            }}
                            text={'Close'}
                        />
                        <Button
                            onClick={() => {
                                if (props.onSend) props.onSend(newTitle, newText, language);
                                clearLocalCache();
                                setNewTitle('');
                                setNewText('');
                                setLanguage(null);
                                setShow(false);
                            }}
                            intent={'primary'}
                            icon={'add'}
                            text={'Save'}
                        />
                    </div>
                </div>

            </Collapse>
        </>
    )

}