import React from 'react';

import { useParams } from 'react-router-dom';

export default function Wrapped(props) {
    const params = useParams();
    let pr = { ...props.additionalProps, routeParams: params };
    //if (props.keyParam && params[props.keyParam]) pr['key'] = params[props.keyParam];
    let CW = React.cloneElement(props.component, pr)
    return CW;
}