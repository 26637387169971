import React from 'react';

import Home from '../Pages/Home';
import DraftCheck from './DraftCheck';
import CaseSelect from '../Cases/SelectAdd'

import { Tag, Button, Navbar, NavbarGroup, NavbarHeading, NavbarDivider } from '@blueprintjs/core'
import API from '../Utilities/API';

class DraftList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            lastUpdate: 0
        }

        this.onCheck = this.onCheck.bind(this);
        this.checkChecked = this.checkChecked.bind(this);
    }

    onCheck(group, state) {
        let selected = this.state.selected;
        if (state === true) {
            selected.push(group);
        } else {
            selected = selected.filter((s) => (s.group.id != group.group.id));
        }

        this.setState({
            selected: selected
        });
    }

    checkChecked(group) {
        return this.state.selected.filter((s) => (s.group.id === group.group.id)).length > 0;
    }

    render() {
        return (
            <>
                <div style={{
                    width: '100%',
                    height: 50
                }}>
                    <Navbar style={this.props.isPopup ? {} : { position: 'sticky', top: 0, paddingLeft: 0 }}>
                        {this.props.isPopup ? null : (
                            <NavbarGroup>
                                <NavbarHeading>Drafts</NavbarHeading>
                                <NavbarDivider />
                            </NavbarGroup>
                        )}
                        <NavbarGroup>
                            <Button
                                icon={'minus'}
                                disabled={this.state.selected.length === 0}
                                onClick={() => {
                                    this.props.removeDrafts(this.state.selected);
                                }}
                            >
                                Remove&nbsp;
                                {this.state.selected.length > 0 ? <Tag>{this.state.selected.length}</Tag> : null}
                            </Button>
                            <CaseSelect articles={this.state.selected.map((g) => (g.group.id))} onSelect={() => {
                                this.setState({
                                    lastUpdate: new Date().getTime()
                                }, () => {
                                    if (this.state.selected.length > 0) this.props.removeDrafts(this.state.selected);
                                })
                            }} />
                        </NavbarGroup>
                    </Navbar>
                </div>
                <div key={'div-draftlist'} className={'active-page hide-header'} style={{ display: 'block', width: '100%', height: 'calc(100% - 50px)', overflow: 'hidden' }}>
                    <Home
                        key={`${this.props.DRAFT.datetime}-${this.state.lastUpdate}`}
                        data={this.props.DRAFT.data}
                        hideSearch
                        hideMenu
                        hideGroupsOnly
                        noUpdate
                        addToDraft={this.props.addToDraft}
                        removeFromDraft={this.props.removeFromDraft}
                        isInDraft={this.props.isInDraft}
                        groupButtons={
                            <DraftCheck onCheck={this.onCheck} checkChecked={this.checkChecked} />
                        }
                        titleButtons={null}
                        hideMore
                        removeFromCase={(article_id, case_id) => {
                            if (window.confirm('Remove from case?')) {
                                API.RemoveFromCase(article_id, case_id).then(() => {
                                    delete window.nd.cases[article_id];
                                    this.setState({
                                        lastUpdate: new Date().getTime()
                                    })
                                })
                            }
                        }}
                    />
                </div>
            </>
        )
    }
}

export default DraftList;