import React, { useState, useEffect } from 'react';
import { Alert, Button, Classes } from '@blueprintjs/core'

import { Tooltip2 } from "@blueprintjs/popover2";

export default (props) => {

    const [isOpen, setOpen] = useState(false)

    return (
        <>
            <div style={{ marginLeft: 4, marginTop: 5, position: 'absolute', left: 0, bottom: 6 }}>
                <Tooltip2 content={`Logout ${window.nd?.User?.name ?? ''}`} hoverOpenDelay={0} >
                    <Button
                        onClick={() => {
                            setOpen(true)
                        }}
                        icon={'log-out'}
                        intent={'danger'}
                        large
                    />
                </Tooltip2>
            </div>
            <Alert
                className={Classes.DARK}
                cancelButtonText="Cancel"
                confirmButtonText="Logout"
                icon="log-out"
                intent={'danger'}
                isOpen={isOpen}
                onCancel={() => {
                    setOpen(false);
                }}
                onConfirm={() => {
                    window.askUnload = false;
                    localStorage.removeItem('agenzia-api-user');
                    window.nd = {};
                    window.location.href = '/';
                }}
            >
                <p>
                    Logout?
                </p>
            </Alert>
        </>
    )

}