import React, { useState, useEffect } from 'react';

import { Alert, Button, Classes, Drawer, Position, DrawerSize, Tag, Spinner } from '@blueprintjs/core'

import { Tooltip2 } from "@blueprintjs/popover2";

import RequestList from './RequestList'
import SingleRequest from './SingleRequest'

import NewChat from './NewChat';

import API from '../Utilities/API';

export default () => {

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState({});

    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);

    let timer = null;

    useEffect(() => {
        if (loading === true) {
            API.AI.Requests().then((response) => {
                setRequests(response.data.Data);
                setLoading(false);

                clearTimeout(timer);
                timer = setTimeout(() => {
                    setLoading(true);
                }, 10000);
            });
        }
        return () => {
            clearTimeout(timer);
        }
    }, [loading]);

    let working = false;
    if (requests && requests.length > 0) working = requests.filter(r => r.working).length > 0;

    return (
        <>
            <div style={{ marginLeft: 4, marginTop: 5, position: 'absolute', left: 0, bottom: 146 }}>
                <Tooltip2 content={'Assistant'} hoverOpenDelay={0} >
                    <Button
                        onClick={() => {
                            setOpen(true)
                        }}
                        icon={'predictive-analysis'}
                        large
                        intent={working ? 'success' : 'none'}
                    >
                    </Button>
                </Tooltip2>
            </div>
            <Drawer
                icon={'predictive-analysis'}
                title={'Assistant'}
                autoFocus
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                enforceFocus
                hasBackdrop={open}
                portalClassName={open ? null : 'hidden'}
                position={Position.LEFT}
                size={selected && selected.id ? DrawerSize.LARGE : DrawerSize.SMALL}
                onClose={() => {
                    setOpen(false);
                }}
                isOpen={true}
                className={Classes.DARK}
                style={{
                    visibility: open ? 'visible' : 'hidden'
                }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: '1',
                    overflow: 'hidden',
                    fontSize: '1.1rem',
                    lineHeight: '150%'
                }}>
                    <div className='AIList' style={{
                        padding: 10,
                        flex: 1
                    }}>
                        <NewChat
                            onSend={(data) => {
                                setSelected(data);
                            }}
                        />
                        <RequestList
                            requests={requests}
                            loading={loading}
                            selected={selected}
                            onSelect={(req) => {
                                setSelected(req);
                            }}
                            key={`RequestList-${selected.id}`}
                        />
                    </div>
                    {selected && selected.id && (
                        <>
                            <div style={{
                                flex: 2,
                                padding: 10,
                                height: '100%',
                                whiteSpace: 'pre-line',
                                backgroundColor: '#202b33',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    overflow: 'auto',
                                    flexGrow: 1,
                                    flexBasis: 0
                                }}>{selected.text}</div>
                            </div>
                            <div style={{
                                flex: 2,
                                padding: 10,
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <SingleRequest
                                    request={selected}
                                    key={`SingleRequest-${selected.id}`}
                                    reload={() => {
                                        setLoading(true);
                                    }}
                                    closeAI={() => {
                                        setOpen(false);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>

            </Drawer>
        </>
    )

}