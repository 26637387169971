
import React, { useState, useEffect } from 'react';
import { Button, Drawer, Position, DrawerSize, Classes } from '@blueprintjs/core';

export default ({ draft, loaded, active, onClick, children }) => {

    const [showDrawer, setShowDrawer] = useState(false)

    return (
        <>
            <Button
                icon={draft.data.length > 0 ? null : 'inbox'}
                large
                loading={!loaded}
                active={active}
                style={{ marginLeft: 4, marginTop: 5 }}
                onClick={() => {
                    setShowDrawer(true);
                    if (onClick) onClick();
                }}
                intent={draft.data.length > 0 ? 'primary' : null}
                key={draft.datetime}
                className={'btn-no-padding'}
            >{draft.data.length > 0 ? draft.data.length : null}</Button>
            <Drawer
                icon={'inbox'}
                title={'Drafts'}
                autoFocus
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                enforceFocus
                hasBackdrop
                position={Position.LEFT}
                size={DrawerSize.LARGE}
                onClose={() => {
                    setShowDrawer(false)
                }}
                isOpen={showDrawer}
                className={Classes.DARK}
            >
                {children}
            </Drawer>
        </>
    )

}