import { ContactSupportOutlined } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';

import KeywordsGroups from '../Keywords/Groups';
import Wiki from '../Keywords/Wiki';
import OneArticle from '../Groups/OneArticle';

import ResearchBar from '../Research/Bar';
import Cases from '../Cases/Cases';

import { Drawer, Position, Classes } from '@blueprintjs/core';

import API from '../Utilities/API';

export default (props) => {

    const article = props.article;

    const [selectedKeyword, setSelectedKeyword] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [drawerCase, setDrawerCase] = useState(null);

    const selectedCase = window.nd.cases && window.nd.cases[`Intelligence:${article._id}`] ? window.nd.cases[`Intelligence:${article._id}`][0] : null;
    const selectedCases = window.nd.cases && window.nd.cases[`Intelligence:${article._id}`] ? window.nd.cases[`Intelligence:${article._id}`] : [];

    const art = {
        id: props.article_id,
        ...props.article._source
    };

    return (
        <div style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden'
        }}>
            <div style={{
                width: '60%',
                height: '100%',
                float: 'left',
                overflow: 'auto'
            }}>
                <ResearchBar
                    selectedCase={selectedCase}
                    selectedCases={selectedCases}
                    articles={[art.id]}
                    fullList={[art]}
                    group_id={`Intelligence:${props.article._id}`}
                    removeFromCase={(article_id, case_id) => {
                        if (window.confirm('Remove from case?')) {
                            API.RemoveFromCase(article_id, case_id).then(() => {
                                delete window.nd.cases[article_id];

                            })
                        }
                    }}
                    onChangeCase={(casedata) => {
                        if (window.confirm('View case?')) {
                            setDrawerCase(casedata);
                            setShowDrawer(true);
                        }
                    }}
                    hideSend
                />
                <OneArticle
                    key={`OneArticle-${article._id}`}
                    article={article._source}
                    hideSelect
                />
            </div>
            <div style={{
                width: '40%',
                height: '100%',
                float: 'left',
                overflow: 'hidden'
            }}>
                <div style={{
                    height: '50%',
                    width: '100%',
                    overflow: 'auto'
                }}>
                    {selectedKeyword ? (
                        <Wiki keyword={selectedKeyword} key={`Wiki:${selectedKeyword}`} />
                    ) : null}
                </div>
                <div style={{
                    height: '50%',
                    width: '100%',
                    overflow: 'auto'
                }}>
                    <KeywordsGroups
                        addKeyword={(id) => {
                            setSelectedKeyword(id);
                        }}
                        group={{
                            group: {
                                id: `Intelligence:${props.article._id}`
                            }
                        }}
                        key={`KeywordGroup:${props.article._id}`}
                    />
                </div>
            </div>
            {showDrawer && (
                <Drawer
                    icon={'box'}
                    title={drawerCase.title}
                    autoFocus
                    canEscapeKeyClose={false}
                    canOutsideClickClose={false}
                    enforceFocus
                    hasBackdrop
                    position={Position.RIGHT}
                    size={'100%'}
                    isOpen={true}
                    className={Classes.DARK}
                    onClose={() => {
                        setDrawerCase(null);
                        setShowDrawer(false);
                    }}
                >
                    <Cases
                        popup
                        case={drawerCase}
                        key={drawerCase.id}
                        setCaseText={(text) => {

                        }}
                        reloadCases={() => {

                        }}
                        onCloseCase={() => {
                            setDrawerCase(null);
                            setShowDrawer(false);
                        }}
                    />
                </Drawer>
            )}
        </div>
    )

}