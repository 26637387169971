import React, { useState, useEffect } from 'react';
import OneArticle from '../Groups/OneArticle'

import API from '../Utilities/API';

import { Spinner, Card } from '@blueprintjs/core'

export default function (props) {

    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        API.IntelligenceArticle(props.load_id).then((result) => {
            if (result.data && result.data.Data && result.data.Data.id) {
                setArticle(result.data.Data);
                setLoading(false);
            }
        })
    }, []);

    return loading ? <Spinner /> : (article && article.id && <Card><OneArticle article={article} hideSelect hideSummaryButton /></Card>)

}