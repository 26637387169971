import React from 'react';

import API from '../Utilities/API';
import SingleSource from './SingleSource';
import { Spinner } from '@blueprintjs/core'

import EditText from './EditText';
import MapButton from '../Map/Button';
import CasePopup from '../Cases/CasePopup';

import prettyBytes from 'pretty-bytes';

class Uploads extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            data: [],
            showCasePopup: false,
            moveSourceID: null
        }

        this.loadData = this.loadData.bind(this);
        this.moveSourceToCase = this.moveSourceToCase.bind(this);
    }

    componentDidMount() {
        if (!this.state.loaded) {
            this.loadData();
        }
    }

    loadData() {
        API.CaseUploads(this.props.case_id).then((response) => {
            this.setState({
                loaded: true,
                data: response.data.Data
            }, () => {
                if (this.props.setNumber) this.props.setNumber(this.state.data.length);
            })
        })
    }

    DeleteUpload(id) {
        if (window.confirm('Delete source?')) {
            API.DeleteUploadFromCase(this.props.case_id, id).then(() => {
                this.setState({
                    loaded: false,
                    data: []
                }, () => {
                    this.props.doReload();
                })
            })
        }
    }

    moveSourceToCase(c) {
        API.MoveSource('Uploads', this.state.moveSourceID, this.props.case_id, c.id).then((response) => {
            this.setState({
                loaded: false,
                data: []
            }, () => {
                this.loadData();
            })
        });
    }

    render() {
        return !this.state.loaded ? <Spinner /> : (
            <>
                {this.props.showTitle && this.state.data.length ? <h3 style={{ marginLeft: 10 }}>Uploaded sources</h3> : null}
                {this.state.data.map((file) => (
                    <>
                        <div style={{ marginBottom: 10 }}>
                            <SingleSource
                                data={{
                                    id: file.id,
                                    case_id: file.case_id,
                                    title: file.title ? file.title : file.original_name,
                                    url: file.location,
                                    description: `Size: ${prettyBytes(file.size)}`,
                                    saved_text: file.saved_text,
                                    image: {
                                        url: (file.meta.thumbnails ? file.meta.thumbnails['300'] : file.location)
                                    },
                                    timestamp: file.insert_datetime,
                                    folder: file.folder,
                                    folders: file.folders
                                }}
                                case_id={this.props.case_id}
                                source_id={file.id}
                                showTitle
                                buttons={
                                    <>
                                        <MapButton
                                            style={{ marginRight: 10 }}
                                            content_id={`${this.props.case_id}-${file.id}`}
                                            Save={(data) => {
                                                return API.SaveGeo(`${this.props.case_id}-${file.id}`, data);
                                            }}
                                        />
                                        <EditText text={file.saved_text} style={{ marginRight: 10 }} Save={(text) => {
                                            API.SaveUploadText(this.props.case_id, file.id, text).then(() => {
                                                this.setState({
                                                    loaded: false,
                                                    data: []
                                                }, () => {
                                                    this.loadData();
                                                })
                                            })
                                        }} />
                                    </>
                                }
                                cancel={this.DeleteUpload.bind(this, file.id)}
                                move={() => {
                                    this.setState({
                                        moveSourceID: file.id,
                                        showCasePopup: true
                                    })
                                }}
                                folders={this.props.folders}
                                onItemFolderSelect={(item) => {
                                    API.SetUploadNote(this.props.case_id, file.id, item.id);
                                    this.props.onFolderChange && this.props.onFolderChange();
                                }}

                                onSelectedFolderChange={(folders) => {
                                    API.SaveContentFolders(this.props.case_id, file.id, folders).then(() => {
                                        this.setState({
                                            loaded: false,
                                            data: []
                                        }, () => {
                                            this.loadData();
                                            this.props.onFolderChange && this.props.onFolderChange();
                                        })
                                    })
                                }}
                            />
                        </div>
                        {
                            this.state.showCasePopup ? (
                                <CasePopup
                                    onShow={() => {
                                        this.setState({ showCasePopup: true })
                                    }}
                                    onHide={() => {
                                        this.setState({ showCasePopup: false })
                                    }}
                                    onSelect={(c) => {
                                        this.moveSourceToCase(c);
                                    }}
                                />
                            ) : null
                        }
                    </>
                ))}
            </>
        )
    }
}

export default Uploads;