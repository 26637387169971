import React, { useState, useEffect } from 'react';

import { Button, ButtonGroup } from '@blueprintjs/core'

export default (props) => {

    return (
        <>
            <ButtonGroup
                alignText={'left'}
                vertical
            >
                {props.deleteComment ? (
                    <Button minimal icon={'delete'} intent={'danger'} text={'Delete'} onClick={() => {
                        if (window.confirm('Delete')) props.deleteComment();
                    }} />
                ) : null}
            </ButtonGroup>
        </>
    )

}