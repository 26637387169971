import React, { useState, useEffect } from 'react';

import { Button } from '@blueprintjs/core';

import List from './List'

export default ({ id }) => {

    const [show, setShow] = useState(false);

    return (
        <>
            <Button icon={'clip'} style={{ float: 'right' }} text={'Expand'} onClick={() => {
                setShow(true);
            }} />
            {show && <List id={id} onClose={() => { setShow(false) }} />}
        </>
    )

}