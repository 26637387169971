import React, { useState, useEffect } from 'react';

import { Icon, Alignment, AnchorButton, Button, Navbar, Collapse, NavbarGroup, NavbarHeading, NavbarDivider, Spinner, ControlGroup, PanelStack2 } from '@blueprintjs/core'

import SimpleSearch from '../Navigation/SimpleSearch'
import API from '../Utilities/API';
import U from '../Utilities/Utilities';

const ReportSingle = ({ data }) => {

    const files = {
        'pdf': '',
        'docx': ''
    }
    data.files.map((file) => {
        const ext = file.name.split('.').pop();
        files[ext] = file;
    })

    return (
        <>
            <Button
                alignText={Alignment.LEFT}
                fill
                large
                id={data.id}
                style={{ display: 'flex', justifyContent: 'space-between' }}
                className={'singleGroupButton expanded'}
            >
                <div style={{ whiteSpace: 'nowrap' }}>
                    <span style={{ color: '#62D96B' }} className={'bp5-monospace-text'}>{data.datetime_nice}</span>
                    &nbsp;
                    <Icon icon={'symbol-circle'} />
                    &nbsp;
                </div>
                <div style={{ whiteSpace: 'nowrap', flexGrow: 1, overflowX: 'clip', textOverflow: 'ellipsis', minWidth: 0 }}>
                    {data.title}
                </div>
                <div>
                    {Object.keys(files).filter((ext) => (files[ext].name && files[ext].name.length)).map((ext) => (
                        <AnchorButton href={'https://static.pub.cesno.org' + files[ext].location} target={'_blank'} rel={'noreferrer noopener'} intent={ext === 'pdf' ? 'danger' : 'primary'} small active style={{ marginRight: 5 }} >{ext}</AnchorButton>
                    ))}
                </div>

            </Button>
        </>
    )
}

export default () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading) {
            API.Reports(data.length).then((res) => {
                setData([...data, ...res.data.Data]);
                setLoading(false);
            });
        }
    }, [loading]);

    const renderList = () => {
        return <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
            {data.map((hit) => (
                <ReportSingle data={hit} />
            ))}
            <div style={{ padding: 10 }}>
                <Button large fill loading={loading} onClick={() => { setLoading(true); }}>Load more</Button>
            </div>
        </div >
    }

    return (
        <>
            <div style={{
                width: '100%',
                height: 50
            }}>
                <Navbar style={{ position: 'sticky', top: 0, paddingLeft: 0 }}>
                    <NavbarGroup>
                        <NavbarHeading>Report</NavbarHeading>
                        <NavbarDivider />
                    </NavbarGroup>
                    <NavbarGroup align={Alignment.CENTER} style={{ justifyContent: 'center' }}>

                    </NavbarGroup>
                </Navbar>
            </div>
            <div style={{
                width: '100%',
                height: 'calc(100% - 50px)'
            }}>
                <PanelStack2
                    stack={[
                        {
                            renderPanel: renderList,
                            className: 'panel-groupList',
                            title: <div style={{ width: '100%' }}>Reports</div>
                        }
                    ]}
                    className={'full-height'}
                />
            </div>
        </>
    )

}