import React, { useState } from 'react';

import { Button } from '@blueprintjs/core'
import API from '../Utilities/API';
import U from '../Utilities/Utilities';

import { CopyToClipboard } from 'react-copy-to-clipboard';

export default (props) => {

    const data = props.data;
    if (!data || data.length === 0) return null;

    const [copied, setCopied] = useState('');
    const linkPrefix = props.linkPrefix ? props.linkPrefix : 'https://pub.cesno.org/l/';

    return (
        <>
            <table class="bp5-html-table bp5-html-table-striped bp5-interactive" style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th style={{ width: 100, textAlign: 'center' }}>Date/Time</th>
                        <th>Link</th>
                        <th style={{ width: 50, textAlign: 'right' }}>Views</th>
                        <th style={{ width: 50, textAlign: 'center' }}>Enabled</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data.map((l) => (
                        <tr>
                            <td style={{ width: 100, textAlign: 'center' }}>{U.prettyStampFull(l.datetime)}</td>
                            <td>
                                <a href={`${linkPrefix}${l.short}`} target={'_blank'}>{l.title && l.title.length ? l.title : `(${l.short})`}</a>
                                <CopyToClipboard text={`${linkPrefix}${l.short}`} onCopy={() => {
                                    setCopied(l.short);
                                }}>
                                    <Button
                                        icon={'clipboard'}
                                        small
                                        minimal
                                        text={copied === l.short ? 'Copied!' : null}
                                        style={{ marginLeft: 10 }}
                                        intent={copied === l.short ? 'success' : null}
                                    />
                                </CopyToClipboard>
                            </td>
                            <td style={{ width: 50, textAlign: 'center' }}><Button small fill onClick={() => {
                                if (props.showViews) props.showViews(l.id);
                            }}>{l.views}</Button></td>
                            <td style={{ width: 50, textAlign: 'center' }}>
                                <Button small intent={l.active === 1 ? 'success' : 'warning'} icon={l.active === 1 ? 'tick' : 'stop'} onClick={() => {
                                    if (window.confirm((l.active === 1 ? 'Deactivate' : 'Activate') + ' link?')) {
                                        API.LinkToggle(l.id).then((response) => {
                                            if (props.loadData) props.loadData();
                                        })
                                    }
                                }} />
                            </td>
                            <td style={{ width: 50, textAlign: 'right' }}>
                                {window.nd.User.id != l.user_id ? null : (
                                    <Button small intent={'danger'} icon={'trash'} onClick={() => {
                                        if (window.confirm('Delete link?')) {
                                            API.LinkDelete(l.id).then((response) => {
                                                if (props.loadData) props.loadData();
                                            })
                                        }
                                    }} />
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )

}