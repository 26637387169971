import React from 'react';

import { Button, Dialog, Classes, EditableText } from '@blueprintjs/core';

import U from '../Utilities/Utilities'
import API from '../Utilities/API';

class Send extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            text: '',
            sending: false
        }

        this.onClose = this.onClose.bind(this);
        this.Send = this.Send.bind(this);
        this.introText = this.introText.bind(this);
        this.preview = this.preview.bind(this);
    }

    onClose() {
        this.setState({
            open: false
        })
    }

    Send() {
        if (this.props.articles.length == 1) {
            let article = this.props.fullList.filter((a) => (this.props.articles.indexOf(a.id) > -1))[0];
            this.setState({
                sending: true
            }, () => {
                API.SendMessage({
                    title: U.strip_tags(article.title),
                    link: decodeURI(article.original_link),
                    id: article.id,
                    intro: article.intro ? article.intro.trim() : '',
                    text: this.state.text
                }).then(() => {
                    this.setState({
                        sending: false,
                        open: false
                    })
                })
            })
        } else {
            let articles = this.props.fullList.filter((a) => (this.props.articles.indexOf(a.id) > -1));
            this.setState({
                sending: true
            }, () => {
                API.SendMessage({
                    articles: articles.map((a) => ({
                        id: a.id,
                        title: U.strip_tags(a.title),
                        link: decodeURI(a.original_link)
                    })),
                    text: this.state.text
                }).then(() => {
                    this.setState({
                        sending: false,
                        open: false
                    })
                })
            })
        }
    }

    introText() {
        return this.props.articles.length > 1 ? (
            null
        ) : (
            this.props.articles.length == 0 ? null : (
                this.props.fullList.filter((a) => (this.props.articles.indexOf(a.id) > -1))[0].intro
            )
        )
    }

    preview() {
        return <ol style={{ paddingLeft: 25 }}>
            {
                this.props.fullList.filter((a) => (this.props.articles.indexOf(a.id) > -1)).map((article) => (
                    <li key={`send-${article.id}`}>
                        <h3 style={{ marginBottom: 5 }}>
                            {U.strip_tags(article.title)}
                        </h3>
                        <p>
                            <a href={article.original_link} target={'_blank'}>{decodeURI(article.original_link)}</a>
                        </p>
                    </li>
                ))
            }
        </ol>
    }

    render() {
        return (
            <>
                <Button
                    icon={'send-message'}
                    onClick={() => {
                        this.setState({
                            open: true
                        })
                    }}
                    disabled={this.props.articles.length == 0}
                >
                    {this.props.text || 'Send'}{this.props.articles.length > 0 ? ' (' + this.props.articles.length + ')' : ''}
                </Button>
                <Dialog
                    isOpen={this.state.open}
                    onClose={this.onClose}
                    autoFocus={true}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    enforceFocus={true}
                    usePortal={true}
                    className={Classes.DARK}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <p>
                            <EditableText
                                multiline
                                minLines={6}
                                maxLines={12}
                                value={this.state.text}
                                onChange={(v) => {
                                    this.setState({
                                        text: v
                                    })
                                }}
                                isEditing
                            />
                            <div style={{ maxHeight: 300, overflow: 'auto', wordWrap: 'break-word', marginTop: 20 }}>
                                {this.preview()}
                            </div>
                            <p>
                                {this.introText()}
                            </p>
                        </p>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button onClick={this.Send} loading={this.state.sending} intent={'primary'} icon={'send-message'}>Send</Button>
                            <Button onClick={this.onClose}>Close</Button>
                        </div>
                    </div>

                </Dialog>
            </>
        )
    }
}

export default Send;