import React from 'react';

import { MapContainer, MapConsumer, TileLayer, Marker, FeatureGroup, Circle, Tooltip } from 'react-leaflet';
import { DivIcon } from 'leaflet';

import MapGeocoder from './Geocoder';
import L from "leaflet";

import { EditControl } from "react-leaflet-draw"

import { Button, Navbar, NavbarGroup, Alignment, ControlGroup, InputGroup, Card } from '@blueprintjs/core'
import { Tooltip2 } from "@blueprintjs/popover2";

import Popup from 'react-leaflet-editable-popup';

import MapSearch from './Search';

class MapPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            center: props.geoData && props.geoData.map && props.geoData.map.center ? props.geoData.map.center : [44.7748162, 17.2014206],
            o_center: props.geoData && props.geoData.map && props.geoData.map.center ? props.geoData.map.center : [44.7748162, 17.2014206],
            zoom: props.geoData && props.geoData.map && props.geoData.map.zoom ? props.geoData.map.zoom : 5,
            o_zoom: props.geoData && props.geoData.map && props.geoData.map.zoom ? props.geoData.map.zoom : 5,
            savedData: {},
            drawing: false,
            fullscreen: false,
            layersAdded: false,
            comments: props.geoData && props.geoData.comments ? props.geoData.comments : [],
            mapType: 'satellite',
            lastUpdate: 0
        }
        this.mapRef = React.createRef();
        this.fgRef = React.createRef();
        this.editRef = React.createRef();
        this.centerMap = this.centerMap.bind(this);
        this.saveLayers = this.saveLayers.bind(this);
        this.addComment = this.addComment.bind(this);
        this.setCommentPosition = this.setCommentPosition.bind(this);
    }

    centerMap() {
        this.mapRef.current.setView(this.state.center, this.state.zoom, { animate: true });
    }

    saveLayers() {
        if (this.props.saveLayers) this.props.saveLayers({
            map: {
                center: this.mapRef.current.getCenter(),
                zoom: this.mapRef.current.getZoom()
            },
            comments: this.state.comments,
            data: this.fgRef.current.toGeoJSON()
        });
        this.setState({
            drawing: false
        })
    }

    addComment() {
        let c = this.state.comments;
        c.push({
            text: 'Comment',
            position: this.mapRef.current.getCenter(),
            open: true
        });
        this.setState({
            comments: c
        }, () => {
            this.saveLayers();
        })
    }

    deleteComment(i) {
        if (window.confirm('Delete comment?')) {
            let c = this.state.comments;
            delete c[i];
            this.setState({
                comments: Object.values(c)
            }, () => {
                this.saveLayers();
            });
        }
    }

    saveComment(i, text) {
        let c = this.state.comments;
        c[i].text = text;
        this.setState({
            comments: c
        }, () => {
            this.saveLayers();
        })
    }

    setCommentPosition(i, latlng) {
        let c = this.state.comments;
        c[i].position = latlng;
        this.setState({
            comments: c
        }, () => {
            this.saveLayers();
        })
    }

    render() {
        return (
            <>
                <Navbar>
                    <NavbarGroup align={Alignment.LEFT} style={{ width: 490 }}>
                        <MapSearch
                            showClear={this.state.resultLayer !== null}
                            itemSelect={(item) => {
                                this.setState({
                                    resultLayer: item.geojson,
                                    resultsAdded: false,
                                    center: [parseFloat(item.lat), parseFloat(item.lon)],
                                    lastUpdate: new Date().getTime()
                                }, () => {
                                    setTimeout(() => {
                                        this.mapRef.current.fitBounds([
                                            [parseFloat(item.boundingbox[0]), parseFloat(item.boundingbox[2])],
                                            [parseFloat(item.boundingbox[1]), parseFloat(item.boundingbox[3])]
                                        ]);
                                    }, 300);
                                })
                            }}
                            clearResults={() => {
                                this.setState({
                                    resultLayer: null,
                                    resultsAdded: true,
                                    center: this.state.o_center,
                                    zoom: this.state.o_zoom,
                                    lastUpdate: new Date().getTime()
                                }, () => {
                                    this.centerMap();
                                });
                            }}
                        />
                    </NavbarGroup>
                    {this.state.drawing ? (<>
                        <NavbarGroup align={Alignment.RIGHT}>
                            <Tooltip2 content={'Finish'} placement={'bottom'}>
                                <Button icon={'tick'} style={{ marginRight: 10 }} text={'Finish'} intent={'success'} onClick={() => {
                                    window.document.querySelector('.leaflet-draw-actions li:first-child a').click()
                                    this.setState({
                                        drawing: false,
                                        deleting: false
                                    });
                                }} />
                            </Tooltip2>
                            <Tooltip2 content={'Cancel'} placement={'bottom'}>
                                <Button icon={'cross'} intent={'danger'} text={'Cancel'} onClick={() => {
                                    if (!this.state.deleting) window.document.querySelector('.leaflet-draw-actions li:last-child a').click()
                                    else window.document.querySelector('.leaflet-draw-actions li:nth-child(2) a').click()
                                    this.setState({
                                        drawing: false,
                                        deleting: false
                                    });
                                }} />
                            </Tooltip2>
                        </NavbarGroup>
                    </>
                    ) : (
                        <NavbarGroup align={Alignment.RIGHT}>
                            <ControlGroup style={{ marginRight: 10 }}>
                                <Tooltip2 content={'Satellite map'} placement={'bottom'}>
                                    <Button icon={'satellite'} active={this.state.mapType === 'satellite' ? true : undefined} onClick={() => {
                                        this.setState({
                                            mapType: this.state.mapType === 'satellite' ? 'street' : 'satellite'
                                        }, () => {
                                            //if (this.mapRef.current) this.mapRef.current.invalidateSize();
                                        })
                                    }}></Button>
                                </Tooltip2>
                            </ControlGroup>
                            <ControlGroup style={{ marginRight: 10 }}>
                                <Tooltip2 content={'Add comment'} placement={'bottom'}>
                                    <Button icon={'comment'} onClick={this.addComment} />
                                </Tooltip2>
                            </ControlGroup>
                            <ControlGroup style={{ marginRight: 10 }}>
                                <Tooltip2 content={'Line'} placement={'bottom'}>
                                    <Button icon={'layout-linear'} onClick={() => {
                                        if (!this.state.drawing) window.document.querySelector('.leaflet-draw-draw-polyline').click();
                                        else window.document.querySelector('.leaflet-draw-actions li:last-child a').click()
                                        this.setState({
                                            drawing: true,
                                            deleting: false
                                        });
                                    }} />
                                </Tooltip2>
                                <Tooltip2 content={'Polygon'} placement={'bottom'}>
                                    <Button icon={'polygon-filter'} onClick={() => {
                                        if (!this.state.drawing) window.document.querySelector('.leaflet-draw-draw-polygon').click();
                                        else window.document.querySelector('.leaflet-draw-actions li:last-child a').click()
                                        this.setState({
                                            drawing: true,
                                            deleting: false
                                        });
                                    }} />
                                </Tooltip2>
                                <Tooltip2 content={'Rectangle'} placement={'bottom'}>
                                    <Button icon={'widget'} onClick={() => {
                                        if (!this.state.drawing) window.document.querySelector('.leaflet-draw-draw-rectangle').click();
                                        else window.document.querySelector('.leaflet-draw-actions li:last-child a').click()
                                        this.setState({
                                            drawing: true,
                                            deleting: false
                                        });
                                    }} />
                                </Tooltip2>
                                <Tooltip2 content={'Marker'} placement={'bottom'}>
                                    <Button icon={'map-marker'} onClick={() => {
                                        if (!this.state.drawing) window.document.querySelector('.leaflet-draw-draw-marker').click();
                                        else window.document.querySelector('.leaflet-draw-actions li:last-child a').click()
                                        this.setState({
                                            drawing: true,
                                            deleting: false
                                        });
                                    }} />
                                </Tooltip2>
                            </ControlGroup>
                            <ControlGroup style={{ marginRight: 10 }}>
                                <Tooltip2 content={'Edit'} placement={'bottom'}>
                                    <Button icon={'edit'} onClick={() => {
                                        if (!this.state.drawing) window.document.querySelector('.leaflet-draw-edit-edit').click()
                                        this.setState({
                                            drawing: true,
                                            deleting: false
                                        })
                                    }} />
                                </Tooltip2>
                                <Tooltip2 content={'Trash'} placement={'bottom'}>
                                    <Button icon={'trash'} onClick={() => {
                                        if (!this.state.drawing) window.document.querySelector('.leaflet-draw-edit-remove').click()
                                        this.setState({
                                            drawing: true,
                                            deleting: true
                                        })
                                    }} />
                                </Tooltip2>
                            </ControlGroup>
                            <ControlGroup>
                                <Button icon={'fullscreen'} active={this.state.fullscreen ? true : undefined} onClick={() => {
                                    if (!this.state.fullscreen) {
                                        if (!window.document.fullscreenElement) window.document.querySelector('.bp5-dialog .bp5-dialog-body').requestFullscreen();
                                    }
                                    else {
                                        if (window.document.fullscreenElement) window.document.exitFullscreen();
                                    }

                                    this.setState({
                                        fullscreen: !this.state.fullscreen
                                    })
                                }} />
                            </ControlGroup>
                        </NavbarGroup>
                    )}
                </Navbar>
                <div style={{ width: '100%', height: '100%', minWidth: 900, minHeight: 700, backgroundColor: '#293742' }}>
                    <div style={{ width: this.state.comments.length ? 'calc(100% - 250px)' : '100%', height: '100%', float: 'left', overflow: 'hidden', minHeight: 700 }}>
                        <MapContainer
                            style={{ width: '100%', height: '100%', minHeight: 700 }}
                            center={this.state.center}
                            zoom={this.state.zoom}
                            scrollWheelZoom={true}
                            whenCreated={(mapInstance) => {
                                this.mapRef.current = mapInstance;
                                mapInstance.invalidateSize();
                            }}
                            key={`Map-${this.state.lastUpdate}`}
                        >
                            <TileLayer
                                opacity={this.state.mapType === 'satellite' ? 1 : 0}
                                maxZoom={17}
                                url={"https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"}
                            />
                            <TileLayer
                                opacity={this.state.mapType !== 'satellite' ? 1 : 0}
                                maxZoom={17}
                                url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
                            />
                            {this.state.comments.map((c, i) => (
                                <Marker
                                    position={c.position}
                                    draggable
                                    eventHandlers={{
                                        dragend: (e) => {
                                            this.setCommentPosition(i, e.target._latlng)
                                        }
                                    }}
                                    icon={new DivIcon({
                                        iconSize: [40, 0], html: `<div style="background: #293742; width: 40px; height: 40px; padding: 8px; border: 2px solid #BFCCD6; border-radius: 15px;"><svg style="width: 24px; height: 24px;" class="bp5-icon"><g id="comment_1_">
                                    <g>
                                        <path  style="fill: white;" fill-rule="evenodd" clip-rule="evenodd" d="M19,1H1C0.45,1,0,1.45,0,2v12c0,0.55,0.45,1,1,1h3v4c0,0.55,0.45,1,1,1
                                            c0.28,0,0.53-0.11,0.71-0.29L10.41,15H19c0.55,0,1-0.45,1-1V2C20,1.45,19.55,1,19,1z M4,10c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2
                                            s2,0.9,2,2C6,9.1,5.1,10,4,10z M10,10c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C12,9.1,11.1,10,10,10z M16,10c-1.1,0-2-0.9-2-2
                                            c0-1.1,0.9-2,2-2s2,0.9,2,2C18,9.1,17.1,10,16,10z"/>
                                    </g>
                                </g></svg></div>`})}
                                >
                                    <Popup
                                        closeButton={false}
                                        autoClose={false}
                                        closeOnEscapeKey={false}
                                        closeOnClick={false}
                                        editable
                                        removable
                                        open={c.open}
                                        removalCallback={this.deleteComment.bind(this, i)}
                                        saveContentCallback={this.saveComment.bind(this, i)}
                                        nametag={'comment'}
                                    >{c.text}</Popup>
                                </Marker>
                            ))}
                            <FeatureGroup ref={(rf) => {
                                if (rf === null) return;
                                if (this.state.resultLayer && !this.state.resultsAdded) {
                                    let leafletGeoJSON = new L.GeoJSON(this.state.resultLayer);
                                    leafletGeoJSON.eachLayer((layer) => {
                                        rf.addLayer(layer);
                                    });
                                    this.setState({
                                        resultsAdded: true
                                    })
                                }
                            }} />
                            <FeatureGroup ref={(reactFGref) => {
                                this.fgRef.current = reactFGref;
                                if (reactFGref === null) return;
                                if (!this.state.layersAdded && this.props.geoData && this.props.geoData.data && this.props.geoData.data.features) {
                                    let leafletGeoJSON = new L.GeoJSON(this.props.geoData.data.features);
                                    leafletGeoJSON.eachLayer((layer) => {
                                        reactFGref.addLayer(layer);
                                    });
                                    this.setState({
                                        layersAdded: true
                                    })
                                }
                            }}>
                                <EditControl
                                    position='topright'
                                    onEdited={this.saveLayers}
                                    onCreated={this.saveLayers}
                                    onDeleted={this.saveLayers}
                                    ref={(editRef) => { this.editRef.current = editRef; }}
                                    draw={{
                                        circle: false,
                                        circlemarker: false
                                    }}
                                />
                                <Circle center={[51.51, -0.06]} radius={200} />
                            </FeatureGroup>
                        </MapContainer>
                    </div>
                    {this.state.comments.length ? (
                        <div style={{ width: 250, height: '100%', float: 'left', minHeight: 700, overflow: 'auto', padding: 10 }}>
                            {this.state.comments.map((c) => (
                                <Card interactive>
                                    <p style={{ marginBottom: 20 }} dangerouslySetInnerHTML={{ __html: c.text }}></p>
                                    <Button intent={'primary'} icon={'path-search'} text={'Center map'} fill onClick={() => {
                                        this.mapRef.current.setView(c.position, this.state.zoom, { animate: true });
                                    }} />
                                </Card>
                            ))}
                        </div>
                    ) : null}
                </div>
            </>
        )
    }
}

export default MapPopup;