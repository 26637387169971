import React from 'react';

import { Card, Icon, Button } from '@blueprintjs/core'

import U from '../Utilities/Utilities'

import SingleSource from '../Sources/SingleSource';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Popover2 } from '@blueprintjs/popover2'
import CommentTools from './CommentTools'

import API from '../Utilities/API';

class SingleComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showCopy: false,
            copied: false,
            comment: this.props.comment
        }

    }

    render() {
        if (!this.state.comment.id) return null;
        let type = this.state.comment.type.charAt(0).toUpperCase() + this.state.comment.type.slice(1);
        let color = '#8ABBFF';
        if (type === 'Translate') color = '#72CA9B';
        return (
            <Card style={{
                borderLeft: '5px solid ' + color
            }}>
                <div style={{ height: 20 }} className={'bp5-text-muted bp5-text-small'}>
                    <div style={{ float: 'left', textAlign: 'left', width: '50%' }}><span style={{ color: color }}>{type}:</span> {this.state.comment.user_name}</div>
                    <div style={{ float: 'left', textAlign: 'right', width: '50%' }}>
                        {U.prettyStampFull(this.state.comment.datetime)}
                        <div style={{ float: 'right' }}>
                            <Popover2
                                content={
                                    <CommentTools
                                        comment={this.state.comment}
                                        deleteComment={() => {
                                            API.DeleteComment(this.state.comment.id).then(() => {
                                                this.setState({
                                                    comment: {}
                                                })
                                            })
                                        }}
                                    />
                                }
                                minimal
                                autoFocus={false}
                                enforceFocus={false}
                            >
                                <Button minimal icon={'more'} style={{
                                    minHeight: 16,
                                    height: 16,
                                    marginLeft: 10
                                }} />
                            </Popover2>
                        </div>
                    </div>
                </div>
                <div style={{ clear: 'both' }}></div>
                <div style={{ whiteSpace: 'pre-wrap' }} onMouseEnter={() => {
                    this.setState({ showCopy: true, copied: false })
                }} onMouseLeave={() => {
                    this.setState({ showCopy: false, copied: false })
                }}>
                    {this.state.comment.text}
                    {this.state.showCopy ? (
                        <CopyToClipboard onCopy={() => { this.setState({ showCopy: false, copied: true }) }} text={this.state.comment.text}><small style={{ marginLeft: 10, cursor: 'pointer' }}><Icon icon={'duplicate'} size={16} /></small></CopyToClipboard>
                    ) : null}
                    {this.state.copied ? <small style={{ marginLeft: 10, cursor: 'pointer' }}><Icon icon={'tick-circle'} /></small> : null}

                </div>
                {this.state.comment.og && this.state.comment.og.url ? (
                    <SingleSource data={this.state.comment.og} hideComments />
                ) : null}
            </Card>
        );
    }
}

export default SingleComponent;