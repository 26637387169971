import React, { useState, useEffect } from 'react';

import { Spinner, Navbar, NavbarGroup, NavbarHeading, NavbarDivider, Button } from '@blueprintjs/core';
import NoteInput from './NoteInput'

import API from '../Utilities/API';

import SingleNote from './Single'
import SharedNote from './Shared'

import Uploads from '../Utilities/Uploads';
import U from '../Utilities/Utilities'

export default (props) => {

    const [notes, setNotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (loaded === false) {
            API.GetNotes().then((response) => {
                setNotes(response.data.Data);
                setLoading(false);
                setLoaded(true);
            });
        }
    }, [loaded])

    useEffect(() => {
        Uploads.onChange(() => {
            setLoaded(false);
            setLoading(true);
        });
    }, []);

    let date = '';

    return (
        <>
            <Navbar style={{ position: 'sticky', top: 0, paddingLeft: 0 }}>
                <NavbarGroup>
                    <NavbarHeading>Notes</NavbarHeading>
                    <NavbarDivider />
                </NavbarGroup>
            </Navbar>
            {loading ? <Spinner /> : (
                <>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        height: 'calc(100% - 96px)',
                        overflow: 'auto',
                        flexDirection: 'column-reverse',
                        padding: 10
                    }}>
                        {notes.map((note) => {
                            let time = U.date(note.datetime);
                            let showDate = false;
                            if (time != date) {
                                date = time;
                                showDate = true;
                            }
                            return (
                                <>
                                    {note.mine ? <SingleNote note={note} key={`SingleNote-${note.id}`} reloadNotes={() => {
                                        setLoading(true);
                                        setLoaded(false);
                                    }} /> : <SharedNote note={note} key={`SingleNote-${note.id}`} />}
                                    {showDate ? <Button
                                        fill
                                        minimal
                                        disabled
                                        large
                                        icon={'calendar'}
                                        style={{ color: '#FFB366', cursor: 'default', backgroundColor: 'transparent', zIndex: 1, marginBottom: 10, borderBottom: '1px solid #30404d' }}
                                    >{date}</Button> : null}
                                </>
                            )
                        })}
                    </div>
                    <div style={{
                        display: 'flex',
                        width: '100%'
                    }}>
                        <NoteInput
                            sendNote={(note) => {
                                API.SaveNote(note).then((r) => {
                                    setNotes([
                                        r.data.Data,
                                        ...notes
                                    ]);
                                });
                            }}
                        />
                    </div>
                </>
            )}
        </>
    )

}