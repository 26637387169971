import React, { useState, useEffect } from 'react';

import API from '../Utilities/API';

import { InputGroup, FormGroup, Card, EditableText } from '@blueprintjs/core'

export default (props) => {

    const [data, setData] = useState(false);

    useEffect(() => {
        API.WikiEdit(props.id).then((response) => {
            setData(response.data.Data);
        })
    }, [])

    const contacts = {
        'telefono': 'Telephone',
        'facebook': 'Facebook',
        'cellulare': 'Mobile',
        'twitter': 'Twitter',
        'email': 'E-mail',
        'instagram': 'Instagram',
        'sito_ufficiale': 'Official site',
        'telegram': 'Telegram',
        'rss': 'RSS',
        'linkedin': 'LinkedIn',
        'wikipedia': 'Wikipedia',
        'vk': 'VKontakte'
    }

    return (
        <>
            <div style={{ width: '100%', display: 'flex', padding: 10 }}>
                <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                    <div style={{ flex: '1 0 45%' }}>
                        <FormGroup label={'Name'} style={{ width: '100%' }}>
                            <InputGroup fill value={data.title} />
                        </FormGroup>
                    </div>
                    <div style={{ flex: '1 0 45%' }}>
                        <FormGroup label={'Name in original language'} style={{ width: '100%' }}>
                            <InputGroup fill value={data.data ? data.data.nome_originale : ''} />
                        </FormGroup>
                    </div>
                    <div style={{ flex: '1 0 45%' }}>
                        <Card>
                            <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                                {
                                    Object.keys(contacts).map((c) => (
                                        <div style={{ flex: '1 0 45%' }}>
                                            <FormGroup label={contacts[c]} style={{ width: '100%' }}>
                                                <InputGroup fill value={data.data ? data.data[c] : ''} />
                                            </FormGroup>
                                        </div>
                                    ))
                                }
                                <div style={{ flex: '1 0 45%' }}>
                                    <FormGroup label={'Other links'} style={{ width: '100%' }}>
                                        <EditableText
                                            alwaysRenderInput
                                            isEditing
                                            minLines={5}
                                            multiline
                                            fill
                                            value={data.data ? data.data.other_links : ''}
                                            placeholder={''}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                <div style={{ flex: 1 }}>

                </div>
            </div>
        </>
    )

}