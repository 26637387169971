import API from './API';

const Uploads = {

    loadFromServer(event) {
        API.Uploads().then((response) => {
            Uploads.setList(response.data.Data);
            Uploads.reloadFunction(event || {});
        });
    },

    setList: function (list) {
        window.nd.files = list
    },

    reloadFunctions: {
        default: function () {
        }
    },

    Add: function (files) {
        if (!window.nd) window.nd = {};
        if (!window.nd.files) window.nd.files = [];

        for (let i = 0; i < files.length; i++) {
            files[i].upload = {
                progress: 0
            }
            files[i].isNote = (window.location.pathname.substr(0, 6) == '/notes');
            const index = window.nd.files.length;
            window.nd.files.unshift(files[i]);
            Uploads.startUpload(files[i], 0);
        }

        Uploads.reloadFunction({
            type: 'uploadAdd',
            files: files
        });
    },

    onChange: function (f, fid) {
        if (!fid) fid = new Date().getTime() * (Math.random() + 1);
        Uploads.reloadFunctions[fid] = f;
        return fid;
    },

    onChangeStop(fid) {
        delete Uploads.reloadFunctions[fid];
    },

    reloadFunction: function (event) {
        let fids = Object.keys(Uploads.reloadFunctions);
        for (let i = 0; i < fids.length; i++) {
            try {
                Uploads.reloadFunctions[fids[i]](event);
            } catch (e) {
                console.error('Unable to execute', fids[i], Uploads.reloadFunctions[fids[i]], e);
            }
        }
    },

    List: function () {
        return window.nd.files || [];
    },

    startUpload: function (file, index) {
        var xhrObj = new XMLHttpRequest();
        xhrObj.upload.addEventListener("loadstart", function (evt) {
            file.upload.progress = 0;
            window.nd.files[index] = file;
            Uploads.reloadFunction({
                type: 'uploadStart',
                file: file
            });
            window.dispatchEvent(new Event('nd_uploadStart'));
        }, false);
        xhrObj.upload.addEventListener("progress", function (evt) {
            file.upload.progress = evt.loaded / evt.total;
            window.nd.files[index] = file;
            Uploads.reloadFunction({
                type: 'uploadProgress',
                file: file
            });
        }, false);
        xhrObj.upload.addEventListener("load", function (evt) {
            file.upload.progress = 1;
            window.nd.files[index] = file;
            Uploads.reloadFunction({
                type: 'uploadLoad',
                file: file
            });
        }, false);
        xhrObj.onreadystatechange = function () {
            if (xhrObj.readyState == 4) {
                file.upload.response = xhrObj.response;
                setTimeout(function () {
                    Uploads.loadFromServer({
                        type: 'uploadComplete',
                        file: file
                    });
                }, 1000);
                window.dispatchEvent(new Event('nd_uploadComplete'));
            }

        }
        const API_PATH = process.env.REACT_APP_API_PATH.replace(/{TS}/, '0000');
        xhrObj.open("POST", `${API_PATH}/NewsDesk/Upload/${file.name}?output=json`, true);
        xhrObj.setRequestHeader("Content-type", "application/json");
        xhrObj.setRequestHeader("X-API-KEY", window.nd ? window.nd['api-key'] : '');
        var reader = new FileReader();
        reader.onload = function (event) {
            xhrObj.send(JSON.stringify({
                name: file.name,
                size: file.size,
                type: file.type,
                data: reader.result,
                isNote: file.isNote ? '1' : '0'
            }));
        }
        reader.readAsDataURL(file);

        file.upload.xhr = xhrObj;
    },

    getTotalProgress: function () {
        let total = 0;
        let files = Uploads.List();
        for (let i = 0; i < files.length; i++) if (!files[i].upload.canceled && files[i].upload.progress < 1) {
            total += files[i].upload.progress;
        }
        return total / files.filter(function (a) { return !a.upload.canceled && a.upload.progress < 1 }).length;
    },

    Cancel(i) {
        if (window.nd.files[i].upload.progress < 1) {
            window.nd.files[i].upload.progress = 0;
            window.nd.files[i].upload.xhr.abort();
            window.nd.files[i].upload.canceled = true;
            Uploads.reloadFunction({
                type: 'uploadCancel',
                file: window.nd.files[i]
            });
        } else {
            API.DeleteUpload(window.nd.files[i].id).then(() => {
                Uploads.loadFromServer({
                    type: 'uploadLoaded'
                });
            });
        }

    }

}

export default Uploads;