import React, { useState, useEffect } from 'react';

import { Button, Icon } from '@blueprintjs/core'
import U from '../Utilities/Utilities';

import CaseTitle from '../Cases/CaseTitle';
import DraftCheck from '../Draft/DraftCheck';

export default (props) => {

    const article = props.article;
    if (!article._source) return null;

    const group = U.GroupFromArticle(article);

    const time = U.prettyStamp(article._source.timestamp);

    const selected = props.checkChecked && props.checkChecked({ group: { id: `Intelligence:${article._id}` } });

    return (
        <Button
            style={{
                display: 'flex',
                flexDirection: 'row',
                textDecoration: 'none',
                paddingTop: 15,
                paddingBottom: 15,
                backgroundColor: article.isNew && !props.active ? '#1F4B99' : null
            }}
            fill
            large
            className={`singleGroupButton expanded ${props.active ? 'currentlyOpenedArticle' : ''}`}
            onClick={(e) => {
                if (!e.target.tagName || (e.target.tagName != 'svg' && e.target.tagName != 'path')) props.onClick()
            }}
            intent={props.active || selected ? 'primary' : 'none'}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1
            }}>
                <div style={{
                    width: '100%',
                    fontSize: '.85rem'
                }}>
                    <span style={{ color: '#62D96B' }} className={'bp5-monospace-text'}>{time}</span>
                    &nbsp;
                    <DraftCheck onCheck={props.onCheck} checkChecked={props.checkChecked} checkData={{ group: { id: `Intelligence:${article._id}` } }} />
                    &nbsp;
                    <span style={{ color: '#FFB366' }} dangerouslySetInnerHTML={{ __html: article?._source?.languageData?.name }} />
                    &nbsp;
                    <Icon icon={'dot'} />
                    &nbsp;
                    <span style={{ color: '#FFB366' }} dangerouslySetInnerHTML={{ __html: article?._source?.countryData?.name }} />
                    &nbsp;
                    <Icon icon={'dot'} />
                    &nbsp;
                    <span style={{ color: '#FFB366' }} dangerouslySetInnerHTML={{ __html: article?._source?.linkData?.publish_title }} />
                </div>
                <div style={{ overflowX: 'clip', textOverflow: 'ellipsis', minWidth: 0, marginTop: 3 }} className={'articleText'}>
                    <span className={'translate'} dangerouslySetInnerHTML={{ __html: article?._source?.title }}></span>
                    <div style={{ float: 'right', whiteSpace: 'nowrap' }}>
                        <span style={{ marginRight: 20 }}>
                            <CaseTitle group_id={`Intelligence:${article._id}`} removeFromCase={() => { }} />
                        </span>
                    </div>
                </div>
            </div>
        </Button>
    );

}