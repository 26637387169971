import React from 'react';

import { Card } from '@blueprintjs/core'

import U from '../Utilities/Utilities'

import { Link } from "react-router-dom";

class NotificationsList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data || []
        }

    }

    render() {
        if (this.state.data && this.state.data.length === 0) {
            return <Card interactive style={{ padding: 8 }}>
                <h5 style={{ margin: 0 }}><a>Notifications</a></h5>
                <p>You have no notifications</p>
            </Card>
        }
        return (
            <>
                {
                    this.state.data ? this.state.data.map((entry) => (
                        <Link to={entry.target} style={{ textDecoration: 'none' }} onClick={() => {
                            if (this.props.onClick) this.props.onClick(entry);
                        }}>
                            <Card interactive style={{ padding: 8, minWidth: 300, backgroundColor: entry.seen && entry.seen.length > 0 ? null : '#0E5A8A' }}>
                                <h5 style={{ margin: 0 }}><a>{entry.from_name}</a></h5>
                                <p style={{ color: 'white' }}>{entry.text}</p>
                                <small className={'bp5-text-small bp5-text-muted'} style={{ margin: 0, display: 'block', textAlign: 'right' }}>{U.prettyStampFull(entry.sent)}</small>
                            </Card>
                        </Link>
                    )) : null
                }
            </>
        );
    }
}

export default NotificationsList;