import React, { useState, useEffect } from 'react';

import { Button, ButtonGroup } from '@blueprintjs/core'

import ShareButton from '../Share/Button';
import LinkButton from '../Links/Button';

export default (props) => {

    return (
        <>
            <ButtonGroup
                alignText={'left'}
                vertical
            >
                {props.editNote ? (
                    <Button icon={'edit'} onClick={props.editNote} text={'Edit'} minimal />
                ) : null}

                <ShareButton minimal content_id={`Note:${props.note.id}`} onShare={props.onShare} />
                <LinkButton minimal content_id={`Note:${props.note.id}`} />

                {props.deleteNote ? (
                    <Button minimal icon={'delete'} intent={'danger'} text={'Delete'} onClick={() => {
                        if (window.confirm('Delete')) props.deleteNote();
                    }} />
                ) : null}
            </ButtonGroup>
        </>
    )

}