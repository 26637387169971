import React from 'react';

import API from '../Utilities/API';

import WriteComment from './Write';
import SingleComment from './SingleComment';

class Comments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            data: []
        }

        this.loadData = this.loadData.bind(this)
        this.Send = this.Send.bind(this);
        this.Close = this.Close.bind(this);
    }

    componentDidMount() {
        if (!this.state.loaded) {
            this.loadData();
        }
    }

    loadData() {
        API.Comments(this.props.content_id).then((response) => {
            this.setState({
                loaded: true,
                data: response.data.Data
            }, () => {
                if (this.props.setNumber) this.props.setNumber(this.state.data.length);
            })
        });
    }

    Send(text, type, callback) {
        if (text.length === 0) return;
        API.AddComment(this.props.content_id, text, type).then((response) => {
            this.loadData();
            if (callback) callback();
            if (this.props.increaseNumber) this.props.increaseNumber();
            if (this.props.onSend) this.props.onSend();
            this.Close();
        });
    }

    Close() {
        if (this.props.Close) this.props.Close();
    }

    render() {
        return this.state.loaded ? (
            <>
                {this.props.showWriting ? (
                    <WriteComment hideTypes={this.props.hideTypes} Send={this.Send} Close={this.Close} />
                ) : null}
                <div className={'comments-list'}>
                    {this.state.data.map((comment) => (<SingleComment key={comment.id} comment={comment} />))}
                </div>
            </>
        ) : null
    }
}

export default Comments;