import React from 'react';

import { InputGroup, Spinner, Button } from '@blueprintjs/core'

import API from '../Utilities/API';

import SingleSource from './SingleSource';

class OG extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            url: '',
            loading: false,
            data: null
        }

        this.handleChange = this.handleChange.bind(this);
        this.SaveData = this.SaveData.bind(this);
    }

    handleChange(e) {
        if (!e.target.value || e.target.value.trim().length === 0) return false;
        this.setState({
            url: e.target.value,
            sent_url: e.target.value,
            loading: true,
            data: null
        }, () => {
            this.props.setLoading();
            API.fetchExternal(this.state.url).then((result) => {
                if (result.data.Data.existing && result.data.Data.existing.id) {
                    API.SetCase([result.data.Data.existing.id], { id: this.props.case_id }).then(() => {
                        this.props.onExisting && this.props.onExisting();
                    });
                } else {
                    API.OG(this.state.url).then((result) => {
                        if (result.data.Data.url) this.SaveData(result.data.Data);
                        /* this.setState({
                            data: result.data.Data,
                            loading: false,
                            url: '',
                            sent_url: this.state.url
                        }, () => {
                            if (this.state.data.title) {
                                this.SaveData();
                            }
                        }); */
                    });
                }
            })

        });
    }

    SaveData(data) {
        API.AddSourceToCase(this.props.case_id, data).then((response) => {
            this.props.reloadExternal();
        }).catch(() => {
            this.props.setLoaded();
        });
    }

    render() {
        return (
            <>
                <InputGroup
                    placeholder={'Paste link here'}
                    value={this.state.url}
                    onChange={this.handleChange}
                    large
                    disabled={this.state.loading}
                    rightElement={this.state.loading ? <Spinner size={20} /> : null}
                />
            </>
        )
    }
}

export default OG;