import React, { useState, useEffect } from 'react';

import {
    Tree,
    getBackendOptions,
    MultiBackend,
} from "@minoru/react-dnd-treeview";
import { DndProvider } from "react-dnd";
import { Icon } from '@blueprintjs/core';

import { Menu, MenuItem, MenuDivider, Dialog, Classes, Button, InputGroup, Spinner } from "@blueprintjs/core";
import { ContextMenu2 } from "@blueprintjs/popover2";

import API from '../Utilities/API';

export default (props) => {

    const [treeData, setTreeData] = useState([]);
    const [activeNode, setActiveNode] = useState({});
    const [showNewFolder, setShowNewFolder] = useState(false);
    const [showEditFolder, setShowEditFolder] = useState(false);
    const [newFolderTitle, setNewFolderTitle] = useState('');
    const [editFolderTitle, setEditFolderTitle] = useState('');
    const [loaded, setLoaded] = useState(false);
    const handleDrop = (newTreeData) => setTreeData(newTreeData);

    useEffect(() => {
        API.SourceTree(props.case_id).then((response) => {
            setTreeData(response.data.Data);
            setLoaded(true);
        });
    }, [])

    useEffect(() => {
        if (loaded) {
            API.SaveTree(`Case:${props.case_id}`, treeData);
        }
    }, [treeData]);

    useEffect(() => {
        setEditFolderTitle(activeNode.text);
    }, [activeNode])

    const deleteFolder = () => {
        if (window.confirm('Delete folder?')) {
            setTreeData(treeData
                .map((node) => {
                    if (node.id == activeNode.id) return node;
                    else {
                        if (node.parent == activeNode.id) node.parent = activeNode.parent;
                        return node;
                    }
                })
                .filter((node) => (node.id !== activeNode.id))
            );
        }
    }

    return loaded ? (
        <>
            <ContextMenu2
                content={
                    <Menu>
                        <MenuItem text={'New folder'} icon={'folder-new'} onClick={() => {
                            setShowNewFolder(true);
                        }} />
                        <MenuDivider />
                        <MenuItem text={'Rename folder'} icon={'edit'} onClick={() => {
                            setShowEditFolder(true);
                        }} />
                        <MenuDivider />
                        <MenuItem text={'Delete folder'} intent={'danger'} icon={'trash'} onClick={() => {
                            deleteFolder();
                        }} />
                    </Menu>
                }
            >
                <div className={'sourceTree'}>
                    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                        <Tree
                            tree={treeData}
                            rootId={0}
                            onDrop={handleDrop}
                            initialOpen={true}
                            render={(node, { depth, isOpen, onToggle }) => {
                                return (
                                    <div
                                        style={{ marginLeft: depth * 10, padding: 5, backgroundColor: (activeNode.id === node.id ? '#404854' : 'transparent') }}
                                        onClick={() => {
                                            setActiveNode(node);
                                            onToggle();
                                        }}
                                        onContextMenu={() => {
                                            setActiveNode(node);
                                        }}
                                        onMouseDown={() => {
                                            setActiveNode(node);
                                        }}
                                    >
                                        {node.droppable ? (
                                            <span>
                                                {isOpen ? (
                                                    <Icon icon="folder-open" style={{ marginRight: 20 }} intent={'warning'} />
                                                ) : (
                                                    <Icon icon="folder-close" style={{ marginRight: 20 }} intent={'warning'} />
                                                )}
                                            </span>
                                        ) : (
                                            <Icon icon={node.icon} style={{ marginRight: 20 }} intent={'primary'} />
                                        )}
                                        <span>
                                            {node.text}
                                        </span>
                                    </div>
                                )
                            }}
                            sort={false}
                            insertDroppableFirst={false}
                            canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
                                if (dragSource?.parent === dropTargetId || (dropTarget && dropTarget.droppable)) {
                                    return true;
                                }
                            }}
                            placeholderRender={(node, { depth }) => {
                                return (
                                    <div style={{ marginLeft: depth * 10, padding: 5 }}>
                                        <Icon icon={'double-chevron-right'} style={{ marginRight: 20 }} intent={'warning'} />
                                        <span>{node.text}</span>
                                    </div>
                                )
                            }}
                        />
                    </DndProvider>
                </div>
            </ContextMenu2>
            <Dialog
                isOpen={showNewFolder}
                onClose={() => {
                    setShowNewFolder(false);
                }}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                enforceFocus={true}
                usePortal={true}
                className={Classes.DARK}
            >
                <div className={Classes.DIALOG_BODY}>
                    <p>
                        <InputGroup
                            value={newFolderTitle}
                            onChange={(v) => {
                                setNewFolderTitle(v.target.value);
                            }}
                            placeholder={'Folder title'}
                            style={{
                                marginBottom: 10
                            }}
                        />
                    </p>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            intent={'primary'}
                            icon={'add'}
                            text={'Save'}
                            onClick={() => {
                                setTreeData([
                                    {
                                        id: new Date().getTime(),
                                        parent: 0,
                                        text: newFolderTitle,
                                        icon: 'folder-open',
                                        droppable: true
                                    },
                                    ...treeData
                                ])
                                setNewFolderTitle('');
                                setShowNewFolder(false);
                            }}
                        />
                        <Button
                            onClick={() => {
                                setShowNewFolder(false);
                            }}
                            text={'Close'}
                        />
                    </div>
                </div>

            </Dialog>
            <Dialog
                isOpen={showEditFolder}
                onClose={() => {
                    setShowEditFolder(false);
                }}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                enforceFocus={true}
                usePortal={true}
                className={Classes.DARK}
            >
                <div className={Classes.DIALOG_BODY}>
                    <p>
                        <InputGroup
                            value={editFolderTitle}
                            onChange={(v) => {
                                setEditFolderTitle(v.target.value);
                            }}
                            placeholder={'Folder title'}
                            style={{
                                marginBottom: 10
                            }}
                        />
                    </p>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            intent={'primary'}
                            icon={'add'}
                            text={'Save'}
                            onClick={() => {
                                setTreeData(
                                    treeData.map((node) => (node.id === activeNode.id ? { ...activeNode, text: editFolderTitle } : node))
                                );
                                setEditFolderTitle('');
                                setShowEditFolder(false);
                            }}
                        />
                        <Button
                            onClick={() => {
                                setShowEditFolder(false);
                            }}
                            text={'Close'}
                        />
                    </div>
                </div>

            </Dialog>
        </>
    ) : <Spinner />;

}