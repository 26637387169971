import React from 'react';

import API from '../Utilities/API';

import { Spinner, PanelStack2, Button } from '@blueprintjs/core';
import { Chart } from 'react-charts'

class TimelineGraph extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loaded: false,
            series:{}
        };

        this.loadData = this.loadData.bind(this);
        this.renderGraph = this.renderGraph.bind(this);
    }

    componentDidMount() {
        if (!this.state.loaded) {
            this.loadData();
        }
    }

    loadData() {
        return false;
        API.Graph(this.props.keywords.map((k) => (k.id))).then((response) => {
            let graphData = [];

            this.props.keywords.forEach((k) => {
                graphData.push({
                    label: k.title,
                    data: response.data.Data[k.id] ? response.data.Data[k.id].map((d) => ({
                        date: new Date(d.date),
                        value: d.news
                    })) : [],
                    secondaryAxisId: undefined
                });
                graphData.push({
                    label: k.title + ' Normal',
                    data: response.data.Data[k.id] ? response.data.Data[k.id].map((d) => ({
                        date: new Date(d.date),
                        value: d.average.average
                    })) : [],
                    secondaryAxisId: 'average'
                });
            });

            this.setState({
                data: graphData,
                loaded: true
            })
        })
    }

    renderGraph() {
        return <div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
                    <Chart
                        options={{
                            initialWidth: 100,
                            dark: true,
                            tooltip: true,
                            data: this.state.data,
                            primaryAxis: {
                                getValue: (e) => (e.date),
                                show: false
                            },
                            secondaryAxes: [
                                {
                                    getValue: (e) => (e.value),
                                    padding: 0,
                                    elementType: 'bar',
                                    show: false
                                },
                                {
                                    id: 'average',
                                    getValue: (e) => (e.value),
                                    padding: 0,
                                    elementType: 'line',
                                    show: false
                                }
                            ],
                            getSeriesStyle: (s) => {
                                if (s.style && s.style.stroke && !this.state.series[s.label]) {
                                    let cs = this.state.series;
                                    cs[s.label] = s.style.stroke;
                                    this.setState({
                                        series: cs
                                    })
                                }
                            }
                        }}
                    />
            </div>
    }

    render() {
        return null;
        return !this.state.loaded ? <Spinner /> : (
            <PanelStack2
                stack={[
                    {
                        renderPanel: this.renderGraph,
                        title: <div style={{width: '100%', position: 'absolute', top: 0, left: 0}}>
                            { Object.keys(this.state.series).map((serie) => (
                                serie.indexOf(' Normal') == -1 ? <Button
                                    minimal
                                ><span style={{ fontSize: '2rem', color: this.state.series[serie], display: 'inline-block', height: 16, overflow: 'hidden', width: 12, lineHeight: '14px' }}>&bull;</span>&nbsp; {serie}</Button> : null
                            ))}
                        </div>
                    }
                ]}
                className={'full-height'}
            />
        );
    }

}

export default TimelineGraph;