import React from 'react';


import { startRegistration } from '@simplewebauthn/browser';
import { Alert, Button, Classes } from '@blueprintjs/core'
import { Tooltip2 } from "@blueprintjs/popover2";

import API from '../Utilities/API';

export default (props) => {
    return (
        <div style={{ marginLeft: 4, marginTop: 5, position: 'absolute', left: 0, bottom: 100 }}>
            <Tooltip2 content={'Protect your account'} hoverOpenDelay={0} >
                <Button
                    large
                    icon={'lock'}
                    onClick={() => {
                        API.StartRegistration().then((response) => {
                            startRegistration(response.data).then((result) => {
                                API.VerifyRegistration(result).then((response) => {
                                    if (response.data.success) {
                                        alert('Registration successful!\n\nTry logging in again to see the new device.');
                                    } else {
                                        alert('There has been a problem with your registration. Please try again or contact administrator.');
                                    }
                                });
                            })
                        });
                    }}
                />
            </Tooltip2>
        </div>
    )
}