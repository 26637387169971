import React, { useState, useEffect } from 'react';

import { Card } from '@blueprintjs/core'

export default (props) => {

    return props.data && props.data.thumbnail ? (
        <>
            <Card style={{ height: 375, textAlign: 'center' }}>
                <p><img src={props.data.thumbnail.thumbnail} style={{ maxHeight: 200 }} /></p>
                <h5><a>{props.data.name}</a></h5>
                {props.buttons}
            </Card>
        </>
    ) : null;

}