import { Menu, MenuItem } from '@blueprintjs/core';

export default (props) => {

    return (
        <Menu>
            {props.types.map((t) => (
                <MenuItem
                    text={t}
                    large
                    active={props.selected && props.selected.indexOf(t) > -1}
                    onClick={() => {
                        props.onClick && props.onClick(t);
                    }}
                />
            ))}
        </Menu>
    )

}