import React, { useState, useEffect, useRef } from 'react';

import TextareaAutosize from 'react-textarea-autosize';

export default (props) => {

    const [newTitle, setNewTitle] = useState('');
    const [key, setKey] = useState(0);
    const areaRef = useRef(null);

    return (
        <>
            <TextareaAutosize
                autoFocus
                key={key}
                minRows={1}
                maxRows={6}
                value={newTitle}
                ref={areaRef}
                onChange={(e) => {
                    setNewTitle(e.target.value)
                }}
                className={'bp5-input bp5-large bp5-fill'}
                style={{
                    resize: 'none'
                }}
                onKeyPress={(e) => {
                    const key = e.key;
                    if (key == 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                        if (props.sendNote) {
                            props.sendNote({
                                text: newTitle,
                                datetime: new Date().toLocaleTimeString(),
                                mine: true
                            });
                        }
                        setNewTitle('');
                        setKey(key + 1);
                    }
                }}
            />
        </>
    )

}