import React, { useState, useEffect } from 'react';

import { Button, Dialog, Classes, Popover, Tag, Menu, MenuItem, PopoverPosition } from '@blueprintjs/core'

import CountryList from '../Country/List';
import LanguageList from '../Language/List';
import Search from '../Navigation/Search'

import API from '../Utilities/API'
import AdvancedKeywordSearch from '../Keywords/List';

export default (props) => {

    const [initial, setInitial] = useState(true);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);

    const [countries, setCountries] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [categories, setCategories] = useState([]);

    const [loadingKey, setLoadingKey] = useState(0);


    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [openCountries, setOpenCountries] = useState(false);
    const [openLanguages, setOpenLanguages] = useState(false);

    const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);

    const [data, setData] = useState([]);
    const [Q, setQ] = useState('');

    const [saveQuery, setSaveQuery] = useState('');

    const [kwt, setKWType] = useState('or');

    const [range, setRange] = useState(props.range ?? [null, null]);

    useEffect(() => {

        if (props.initial) {
            const { country, language, keyword, q, kwtype } = props.initial;

            if (initial) {
                if (country) setSelectedCountries(country);
                if (language) setSelectedLanguages(language);
                if (q) setQ(q);
                if (keyword) setSelectedKeywords(keyword);
                if (kwtype) setKWType(kwtype);
                setInitial(false);
            }
        }

        API.getFilters().then((response) => {
            setCountries(response.data.Data.countries);
            setLanguages(response.data.Data.language);
            setCategories(response.data.Data.categories);
            setLoading(false);

            setLoadingKey(new Date().getTime());
        })
    }, [])

    useEffect(() => {
        props.setKeywords && props.setKeywords(selectedKeywords);
    }, [selectedKeywords]);

    useEffect(() => {
        props.setCountries && props.setCountries(selectedCountries);
    }, [selectedCountries]);

    useEffect(() => {
        props.setLanguages && props.setLanguages(selectedLanguages);
    }, [selectedLanguages]);

    useEffect(() => {
        props.setKwType && props.setKwType(kwt);
    }, [kwt]);


    return (
        <>
            <Popover
                hasBackdrop={true}
                placement={'bottom'}
                isOpen={openCountries}
                backdropProps={{
                    onClick: () => {
                        setOpenCountries(false)
                    }
                }}
                content={(
                    <>
                        <CountryList
                            key={`CountryList-${selectedCountries.length}`}
                            countries={countries}
                            selected={selectedCountries}
                            onClick={(sel_country) => {
                                if (selectedCountries.indexOf(sel_country.id) == -1) {
                                    setSelectedCountries([
                                        ...selectedCountries,
                                        ...countries.filter((country) => (
                                            country.id === sel_country.id || country.parent === sel_country.id
                                        )).map((country) => (
                                            country.id
                                        ))
                                    ])
                                } else {
                                    setSelectedCountries(selectedCountries.filter((country_id) => (country_id !== sel_country.id)))
                                }
                            }}
                        />
                    </>
                )}>
                <Popover
                    content={selectedCountries.length ? <>
                        <Menu>
                            {countries.filter((c) => selectedCountries.indexOf(c.id) > - 1).map((c) => (
                                <MenuItem text={<div dangerouslySetInnerHTML={{ __html: c.name }}></div>} />
                            ))}
                        </Menu>
                    </> : null}
                    interactionKind={'hover'}
                    placement={'bottom'}
                >
                    <Button
                        loading={loading && selectedCountries.length === 0}
                        icon={'globe'}
                        text={<>Country {selectedCountries.length ? <Tag minimal>{selectedCountries.length}</Tag> : null}</>}
                        onClick={() => {
                            setOpenCountries(!openCountries)
                        }}
                        rightIcon="double-caret-vertical"
                        style={{
                            whiteSpace: 'nowrap'
                        }}
                    />
                </Popover>
            </Popover>
            <Popover
                hasBackdrop={true}
                placement={'bottom'}
                isOpen={openLanguages}
                backdropProps={{
                    onClick: () => {
                        setOpenLanguages(false)
                    }
                }}
                content={(
                    <>
                        <LanguageList
                            key={`LanguageList-${selectedLanguages.length}`}
                            languages={languages}
                            selected={selectedLanguages}
                            onClick={(language) => {
                                if (selectedLanguages.indexOf(language.shortcode) == -1) {
                                    setSelectedLanguages([
                                        ...selectedLanguages,
                                        language.shortcode
                                    ])
                                } else {
                                    setSelectedLanguages(selectedLanguages.filter((language_id) => (language_id !== language.shortcode)))
                                }
                            }}
                        />
                    </>
                )}>
                <Popover
                    content={selectedLanguages.length ? <>
                        <Menu>
                            {languages.filter((c) => selectedLanguages.indexOf(c.shortcode) > - 1).map((c) => (
                                <MenuItem text={c.name} />
                            ))}
                        </Menu>
                    </> : null}
                    interactionKind={'hover'}
                    placement={'bottom'}
                >
                    <Button
                        loading={loading && selectedLanguages.length === 0}
                        icon={'translate'}
                        text={<>Language {selectedLanguages.length ? <Tag minimal>{selectedLanguages.length}</Tag> : null}</>}
                        onClick={() => {
                            setOpenLanguages(!openLanguages)
                        }}
                        rightIcon="double-caret-vertical"
                        style={{
                            whiteSpace: 'nowrap'
                        }}
                    />
                </Popover>
            </Popover>
            <Search
                hideCountry
                hideLanguage
                style={props.searchStyle || {}}
                hideDuplicate
                selectedKeywords={selectedKeywords}
                key={`Search-${selectedKeywords.length}`}
                kwtype={kwt}
                query={Q}
                range={range}
                onSelectKeyword={(kw) => {
                    setSelectedKeywords([
                        ...selectedKeywords.filter((k) => (k.id != kw.id)),
                        kw
                    ]);
                }}
                onRemoveKeyword={(kw) => {
                    setSelectedKeywords([
                        ...selectedKeywords.filter((k) => (k.id != kw.id))
                    ]);
                }}
                onChangeRange={(range) => {
                    props.onChangeRange && props.onChangeRange(range);
                    setRange(range);
                }}
                onChange={(q) => {
                    setQ(q);
                }}
                search={(country, language, keywords, lastq, type, range) => {

                    const searchParams = new URLSearchParams();
                    if (selectedCountries.length > 0) selectedCountries.forEach((selected_country) => searchParams.append('country[]', selected_country));
                    if (selectedLanguages.length > 0) selectedLanguages.forEach((selected_language) => searchParams.append('language[]', selected_language));
                    if (selectedCategories.length > 0) selectedCategories.forEach((selected_category) => searchParams.append('category[]', selected_category));
                    if (keywords.length > 0) keywords.forEach((kw) => searchParams.append('keyword[]', kw.id))
                    if (type && type.length > 0) searchParams.append('kwtype', type);
                    if (lastq && lastq.length > 0) searchParams.append('q', lastq);

                    console.log('SEARCH', range);

                    props.onSearch && props.onSearch(searchParams.toString());
                }}
                onReset={() => {
                    setSelectedCountries([]);
                    setSelectedLanguages([]);
                    setSelectedCategories([]);
                    setSelectedKeywords([]);
                    props.onReset && props.onReset('');
                    props.onSearch && props.onSearch('');
                }}
                noResultsAddon={<>
                    <Button
                        fill
                        icon={'search'}
                        text={'Advanced search'}
                        onClick={() => {
                            setOpenAdvancedSearch(true);
                        }}
                    />
                </>}
            />
            <Button onClick={() => {
                const searchParams = new URLSearchParams();
                if (selectedCountries.length > 0) selectedCountries.forEach((selected_country) => searchParams.append('country[]', selected_country));
                if (selectedLanguages.length > 0) selectedLanguages.forEach((selected_language) => searchParams.append('language[]', selected_language));
                if (selectedCategories.length > 0) selectedCategories.forEach((selected_category) => searchParams.append('category[]', selected_category));
                searchParams.append('q', Q);
                searchParams.append('searchDirect', 'Direct');

                props.onSearch && props.onSearch(searchParams.toString());
            }}>Direct</Button>

            {props.searching && props.onSave && (!props.search || props.search.user_id === window.nd.User.id || (window.nd.User && window.nd.User.permissions && window.nd.User.permissions.saved_search_edit_all)) && (
                <Button
                    intent={'success'}
                    icon={'saved'}
                    text={props.search && props.search.id ? 'Edit' : 'Save'}
                    onClick={props.onSave}
                />
            )}

            {openAdvancedSearch ? <>
                <Dialog
                    isOpen
                    autoFocus={true}
                    canEscapeKeyClose={false}
                    canOutsideClickClose={false}
                    enforceFocus={true}
                    usePortal={true}
                    className={Classes.DARK}
                    style={{
                        width: '90vw',
                        height: '90vh'
                    }}
                >
                    <AdvancedKeywordSearch
                        countries={countries}
                        languages={languages}
                        onSelectKeyword={(kw) => {
                            setSelectedKeywords([
                                ...selectedKeywords,
                                kw
                            ]);
                        }}
                        onClose={() => {
                            setOpenAdvancedSearch(false);
                        }}
                        selectedKeywords={selectedKeywords}
                    />
                </Dialog>
            </> : null}

        </>
    )

}