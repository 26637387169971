import React, { useState, useEffect } from 'react';

import { Button } from '@blueprintjs/core'

import API from '../Utilities/API';
import NoteInput from './NoteInput';

import Linkify from 'react-linkify';

export default (props) => {

    const [text, setText] = useState(props.note.text);
    const [editingText, setEditingText] = useState(props.note.text + ' ');
    const [editing, setEditing] = useState(props.editing || false);
    const areaRef = React.useRef(null);

    const normal = () => (
        <p
            style={{
                whiteSpace: 'pre-wrap',
                maxHeight: 300,
                overflow: 'auto'
            }}
            onDoubleClick={() => {
                setEditing(true)
                if (props.setEditing) props.setEditing(true, props.note);
            }}
        >
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                    {decodeURI(decoratedText)}
                </a>)}>
                {text}
            </Linkify>
        </p>
    )

    useEffect(() => {
        if (editing) {
            areaRef.current.setSelectionRange(editingText.length, editingText.length);
            areaRef.current.focus();
            areaRef.current.click();
        }
    }, [editing, editingText]);

    const editor = () => (
        <>
            <textarea
                ref={areaRef}
                className={"bp5-input bp5-large bp5-fill bp5-intent-primary"}
                onChange={(e) => {
                    setEditingText(e.target.value)
                }}
                style={{
                    height: 300
                }}>{editingText}</textarea>
            <Button intent={'success'} icon={'tick'} onClick={() => {
                API.SaveNoteText(props.note.id, editingText);
                setText(editingText.trim());
                setEditing(false);
                if (props.setEditing) props.setEditing(false, {
                    ...props.note,
                    text: editingText.trim()
                });
            }}>Save</Button>
            <Button icon={'cross'} onClick={() => {
                if (window.confirm('Cancel and loose all changes?')) {
                    setEditingText(text);
                    setEditing(false);
                    if (props.setEditing) props.setEditing(false, {
                        ...props.note,
                        text: text.trim()
                    });
                }
            }}>Cancel</Button>
        </>
    )

    return (
        <>
            {
                editing ? editor() : normal()
            }
        </>
    )

}