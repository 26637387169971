import React from 'react';

import { Overlay } from '@blueprintjs/core';

import Dropzone from 'react-dropzone';

import Uploads from '../Utilities/Uploads';

class UploadOverlay extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false
        }

        this.Show = this.Show.bind(this);
        this.Hide = this.Hide.bind(this);
        this.Drop = this.Drop.bind(this);
    }

    containsFiles(event) {
        if (event.dataTransfer.types) {
            for (var i = 0; i < event.dataTransfer.types.length; i++) {
                if (event.dataTransfer.types[i] == "Files") {
                    return true;
                }
            }
        }
        return false;
    }

    componentDidMount() {
        window.addEventListener('dragenter', (e) => {
            if (this.containsFiles(e)) this.Show();
        });
    }

    Show() {
        this.setState({
            open: true
        })
    }

    Hide() {
        this.setState({
            open: false
        })
    }

    Drop(files) {
        this.setState({
            open: false
        }, () => {
            Uploads.Add(files);
        })
    }

    render() {
        return (
            <>
                <Overlay
                    isOpen={this.state.open}
                    usePortal={false}
                    onClose={() => {
                        this.setState({
                            open: false
                        })
                    }}
                    lazy={false}
                >
                    <div
                        style={{
                            width: '100vw',
                            height: '100vh'
                        }}
                    >
                        <Dropzone
                            disableClick
                            onDrop={this.Drop}
                            onDragLeave={this.Hide}
                            multiple={true}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} style={{ width: '100vw', height: '100vh' }}>
                                    <input {...getInputProps()} hidden />
                                </div>
                            )}
                        </Dropzone>
                    </div>
                </Overlay>
            </>
        )
    }
}

export default UploadOverlay;