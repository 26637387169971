import React, { useState, useEffect } from 'react';

import API from '../Utilities/API'

import { Card, Dialog, Classes, Button } from '@blueprintjs/core'

export default (props) => {

    const url = props.url;

    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState({});
    const [showImage, setShowImage] = useState(true);
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        API.OG(url).then((response) => {
            setData(response.data.Data);
            setLoaded(true);
        })
    }, []);

    let image = false;
    if (data.image && data.image.url) {
        if (data.image.url[0].length > 1) image = data.image.url[0];
        else image = data.image.url;
    }

    const PhotoClick = () => {
        if (isVideo()) setShowDialog(true);
    }

    const isVideo = () => {
        return data.video && data.video.url;
    }

    return loaded && data.title && data.title.length > 0 ? (
        <>
            <a href={url} target={'_blank'} style={{ color: 'white', textDecoration: 'none' }} onClick={(e) => {
                if (isVideo()) {
                    e.stopPropagation();
                    e.preventDefault();
                    return false;
                }
            }}>
                <Card>
                    {
                        showImage && image && image.length ? (
                            <div className={isVideo() ? 'player' : ''} style={{ width: 150, maxHeight: 150, float: 'left', paddingRight: 10, paddingBottom: 10, paddingTop: 5 }} onClick={PhotoClick}>
                                {image ? (
                                    <img src={image} style={{ width: '100%', maxHeight: 150, objectFit: 'contain', background: 'black' }} onError={() => {
                                        setShowImage(false)
                                    }} />
                                ) : null}

                            </div>
                        ) : null
                    }
                    <h3 style={{ margin: 0 }}>{data.title}</h3>
                    <p style={{ marginTop: 10, whiteSpace: 'pre-wrap' }}>{data.description}</p>
                    <small>{data.site_name}</small>
                    <div style={{ clear: 'both', width: '100%' }}></div>
                </Card>
            </a>
            {data.video && data.video.url ? (
                <Dialog
                    isOpen={showDialog}
                    lazy={true}
                    onClose={() => {
                        setShowDialog(false);
                    }}
                    autoFocus={true}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    enforceFocus={true}
                    usePortal={true}
                    className={Classes.DARK + ' videoOverlay'}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <iframe width={800} height={600} frameBorder={0} src={data.video.url}></iframe>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button onClick={() => {
                                setShowDialog(false);
                            }}>Close</Button>
                        </div>
                    </div>
                </Dialog>
            ) : null}
        </>
    ) : null;

}