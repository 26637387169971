import React, { useState, useEffect } from 'react';

import { Dialog, Classes, Button, InputGroup } from '@blueprintjs/core';

import useClipboard from 'react-hook-clipboard'

import API from '../Utilities/API'

export default ({ onSend }) => {

    const [clipboard, copyToClipboard] = useClipboard();
    const [show, setShow] = useState(false);
    const [text, setText] = useState(null);

    return (
        <>
            <Button
                fill
                large
                style={{
                    marginBottom: 10
                }}
                onClick={() => {
                    setShow(true)
                }}
                intent={'primary'}
                icon={'plus'}
            >New Request</Button>
            {show && (
                <Dialog
                    title={'New Assistant Request'}
                    isOpen={show}
                    usePortal
                    className={'bp5-dark'}
                    onClose={() => {
                        setShow(false);
                    }}
                    style={{
                        width: 'auto'
                    }}
                >
                    <div className={Classes.DIALOG_BODY} style={{ width: 700 }}>
                        <textarea
                            rows={30}
                            style={{
                                width: '100%',
                                marginTop: 10
                            }}
                            className={Classes.INPUT}
                            placeholder={'Enter your request'}
                            onChange={(e) => {
                                setText(e.target.value);
                            }}
                        >{text || clipboard}</textarea>
                    </div>
                    <div className={Classes.DIALOG_FOOTER} style={{ textAlign: 'right' }}>
                        <Button large intent={'primary'} onClick={() => {
                            if (window.confirm('Send?')) {
                                API.AI.NewRequest(text || clipboard).then((response) => {
                                    onSend && onSend(response.data.Data);
                                    setShow(false);
                                    setText(null);
                                });
                            }
                        }}>Save</Button>
                        <Button large onClick={() => {
                            if (window.confirm('Close without sending?')) {
                                setShow(false);
                            }
                        }}>Close</Button>
                    </div>
                </Dialog>
            )}
        </>
    )

}