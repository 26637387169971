import React from 'react';

import { Button, Spinner, Navbar, NavbarGroup, Alignment } from '@blueprintjs/core'
import { Popover2 } from "@blueprintjs/popover2";

import NotificationsList from './List';
import API from '../Utilities/API';

class NotificationsButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            data: [],
            dataKey: 0,
            popupOpen: false
        }

        this.loadData = this.loadData.bind(this)
        this.clickNotification = this.clickNotification.bind(this);
    }

    componentDidMount() {
        if (!this.state.loaded) {
            this.loadData();
        }
    }

    loadData() {
        API.Notifications(this.state.data.length > 0 ? this.state.data[0].sent : '').then((response) => {
            if (!response || !response.data) return false;
            this.setState({
                loaded: true,
                data: response.data && response.data.Data && response.data.Data.length > 0 ? response.data.Data.concat(this.state.data) : this.state.data,
                dataKey: new Date().getTime()
            }, () => {
                setTimeout(() => {
                    this.loadData()
                }, 1000);
            })
        })
    }

    clickNotification(notification) {
        const read = new Date().toISOString().split('.')[0].replace(/T/, ' ');
        this.setState({
            popupOpen: false,
            data: this.state.data.map((entry) => ({ ...entry, seen: read }))
        }, () => {
            API.ReadNotifications(notification.sent).then((response) => {
                this.loadData();
            });
        })
    }

    render() {
        const newn = this.state.data.filter((n) => (!n.seen));
        return (
            <>
                <Popover2
                    isOpen={this.state.popupOpen}
                    content={this.state.loaded ? (
                        <div style={{ paddingBottom: 15, paddingLeft: 10, paddingRight: 0, background: '#394B59' }}>
                            <Navbar style={{ height: 48, padding: 0, paddingRight: 20, boxShadow: 'none' }}>
                                <NavbarGroup align={Alignment.RIGHT} style={{ height: 48 }}>
                                    <Button icon={'cross'} onClick={() => {
                                        this.setState({
                                            popupOpen: false
                                        })
                                    }} />
                                </NavbarGroup>
                            </Navbar>
                            <div style={{ maxHeight: 530, overflow: 'auto', paddingLeft: 5 }}>
                                <NotificationsList data={this.state.data} key={this.state.dataKey} onClick={this.clickNotification} />
                            </div>
                        </div>
                    ) : <Spinner />}
                    placement={'right-start'}
                >
                    <Button
                        large
                        icon={newn.length > 999999 ? null : 'notifications'}
                        style={{ marginLeft: 4, marginTop: 5 }}
                        intent={newn.length > 0 ? 'danger' : null}
                        onClick={() => {
                            this.setState({
                                popupOpen: !this.state.popupOpen,
                                dataKey: this.state.popupOpen ? this.state.dataKey : new Date().getTime()
                            })
                        }}
                        className={'btn-no-padding'}
                    >{newn.length > 999999 ? newn.length : null}</Button>
                </Popover2>
            </>
        )
    }
}

export default NotificationsButton;