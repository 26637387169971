import React from 'react';

import CountrySelect from '../Country/CountrySelect';
import CasesList from './CasesList';
import NewCase from './NewCase';
import OneCase from './OneCase';
import TypeSelect from './TypeSelect';

import { Alignment, Button, Navbar, NavbarDivider, NavbarGroup, NavbarHeading } from '@blueprintjs/core';

import API from '../Utilities/API';

import { Link } from 'react-router-dom';

import SimpleSearch from '../Navigation/SimpleSearch';
import CaseEditor from './Editor';

var saveTimeout = 0;

class Cases extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: props.case ? false : true,
            activeCase: props.case || {},
            editorText: '',
            analysisTitle: '',
            editorLoaded: false,
            dataKey: new Date().getTime(),
            noteKey: new Date().getTime(),
            closed: false,
            shared: true,
            alarmed: false,
            my: false,
            folders: false,
            filterCountry: {},
            filterCategory: {},
            filterQuery: '',
            archive: false
        }

        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.setActiveCase = this.setActiveCase.bind(this);
        this.saveText = this.saveText.bind(this);
    }

    componentDidMount() {
        document.title = 'NewsDesk';
    }


    toggleExpanded(state) {
        this.setState({
            expanded: state != null ? state : !this.state.expanded,
            activeCase: {}
        })
    }

    setActiveCase(c) {
        if (!this.props.setActiveCase) {
            this.setState({
                activeCase: c,
                editorText: c.text,
                analysisTitle: c.analysis_title,
                expanded: false
            })
        } else {
        }
    }

    saveText(text, title) {
        if (this.state.activeCase.id) {
            this.setState({
                editorText: text,
                analysisTitle: title
            }, () => {
                API.SaveCaseText(this.state.activeCase.id, text, title);
            })

        }
    }

    render() {

        return (
            <>
                {!this.props.popup && (
                    <div style={{
                        width: '100%',
                        height: 50
                    }}>
                        <Navbar style={{ position: 'sticky', top: 0, paddingLeft: 0, display: 'flex' }}>
                            <NavbarGroup>
                                <NavbarHeading>
                                    {this.state.activeCase.id ? this.state.activeCase.countryData.title : ''}
                                    {this.state.activeCase.id ? ` (${this.state.activeCase.categoryData.name}) » ` : ''}
                                    {this.state.activeCase.id ? this.state.activeCase.typeData.title : 'Research'}
                                    {this.state.activeCase.id ? ' » ' + this.state.activeCase.title : ''}
                                </NavbarHeading>
                                <NavbarDivider />
                            </NavbarGroup>
                            <NavbarGroup>
                                {this.state.activeCase && this.state.activeCase.id ? null : (
                                    <NewCase loadCases={() => {
                                        this.setState({
                                            dataKey: new Date().getTime()
                                        })
                                    }} />
                                )}
                            </NavbarGroup>
                            {this.state.activeCase.id ? (
                                <NavbarGroup align={Alignment.RIGHT} style={{
                                    marginLeft: 'auto'
                                }}>
                                    <Link to={'/cases'}>
                                        <Button icon={'cross'} onClick={this.toggleExpanded} />
                                    </Link>
                                </NavbarGroup>
                            ) : (
                                <>
                                    <NavbarGroup align={Alignment.LEFT} style={{
                                        flexGrow: 1
                                    }}>
                                        <div style={{
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            width: '50%'
                                        }}>
                                            <SimpleSearch
                                                text={this.state.filterQuery}
                                                onSearch={(text) => {
                                                    this.setState({
                                                        filterQuery: text,
                                                        dataKey: new Date().getTime()
                                                    })
                                                }}
                                            />
                                        </div>
                                    </NavbarGroup>
                                    <NavbarGroup align={Alignment.RIGHT}>
                                        <NavbarDivider />
                                        <TypeSelect
                                            selected={this.state.filterCategory}
                                            onChange={(data) => {
                                                this.setState({
                                                    filterCategory: data,
                                                    dataKey: new Date().getTime()
                                                })
                                            }}
                                            onReset={() => {
                                                this.setState({
                                                    filterCategory: {},
                                                    dataKey: new Date().getTime()
                                                })
                                            }}
                                        />
                                    </NavbarGroup>

                                    <NavbarGroup align={Alignment.RIGHT}>
                                        <NavbarDivider />
                                        <CountrySelect
                                            selected={this.state.filterCountry}
                                            onChange={(data) => {
                                                this.setState({
                                                    filterCountry: data,
                                                    dataKey: new Date().getTime()
                                                })
                                            }}
                                            onReset={() => {
                                                this.setState({
                                                    filterCountry: {},
                                                    dataKey: new Date().getTime()
                                                })
                                            }}
                                        />
                                    </NavbarGroup>
                                    <NavbarGroup align={Alignment.RIGHT}>
                                        <NavbarDivider />
                                        <Button minimal={!!this.state.my} icon={'inherited-group'} active={!this.state.my} onClick={() => { this.setState({ my: false }) }}>All cases</Button>
                                        <Button minimal={!this.state.my} icon={'user'} active={this.state.my} onClick={() => { this.setState({ my: true }) }}>Personal cases</Button>
                                    </NavbarGroup>
                                    <NavbarGroup align={Alignment.RIGHT}>
                                        <NavbarDivider />
                                        <Button minimal={!!this.state.closed || this.state.alarmed} icon={'tick'} active={!this.state.closed && !this.state.alarmed} onClick={() => { this.setState({ closed: false, archive: false, alarmed: false, dataKey: new Date().getTime() }) }}>Active cases</Button>
                                        <Button minimal={!this.state.closed || this.state.alarmed} icon={'cross'} active={this.state.closed && !this.state.alarmed} onClick={() => { this.setState({ closed: true, archive: false, alarmed: false, dataKey: new Date().getTime() }) }}>Closed cases</Button>
                                        <Button minimal={!this.state.alarmed} icon={'notifications'} active={this.state.alarmed} onClick={() => { this.setState({ closed: false, archive: false, alarmed: !this.state.alarmed, dataKey: new Date().getTime() }) }}>Alarm</Button>
                                        <Button minimal={!this.state.archive} icon={'trash'} active={this.state.archive} onClick={() => { this.setState({ closed: true, archive: !this.state.archive, alarmed: false, dataKey: new Date().getTime() }) }}>Archived</Button>
                                    </NavbarGroup>
                                    { /*
                                    <NavbarGroup align={Alignment.RIGHT}>
                                        <NavbarDivider />
                                        <Button minimal={!this.state.shared} icon={'user'} active={this.state.shared ? true : undefined} onClick={() => { this.setState({ shared: !this.state.shared, dataKey: new Date().getTime() }) }}>Shared</Button>
                                    </NavbarGroup>
                            */ }



                                </>
                            )}
                        </Navbar>
                    </div>
                )}
                <div style={{
                    width: '100%',
                    height: '100%'
                }}>
                    <div style={{
                        width: this.state.expanded ? '100%' : '0%',
                        height: 'calc(100% - 55px)',
                        overflowY: 'auto',
                        float: 'left'
                    }} className={'hide-header'}>
                        <CasesList
                            hideTopBar={!!this.props.popup}
                            toggleExpanded={this.toggleExpanded}
                            isExpanded={this.state.expanded}
                            setExpanded={this.toggleExpanded.bind(this, true)}
                            setCollapsed={this.toggleExpanded.bind(this, false)}
                            setActiveCase={this.setActiveCase}
                            activeCase={this.state.activeCase}
                            key={this.state.dataKey}
                            closed={this.state.closed}
                            archive={this.state.archive}
                            alarmed={this.state.alarmed}
                            my={this.state.my}
                            shared={this.state.shared}
                            setClosed={(c) => {
                                this.setState({
                                    closed: c,
                                    dataKey: new Date().getTime()
                                })
                            }}
                            filter={{
                                country: this.state.filterCountry,
                                category: this.state.filterCategory,
                                query: this.state.filterQuery
                            }}
                            routeCase={this.props.routeParams && this.props.routeParams['*'] && this.props.routeParams['*'].length > 0 && this.props.routeParams['*'] !== this.state.activeCase.id ? this.props.routeParams['*'] : null}
                        />
                    </div>
                    {!this.state.expanded && this.state.activeCase.id ? (
                        <div style={{
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            float: 'left'
                        }}>
                            <div style={{
                                width: '40%',
                                height: 'calc(100% - 55px)',
                                border: 0,
                                borderTop: 0,
                                overflowY: 'hidden',
                                paddingLeft: 1,
                                boxShadow: '0 0 0 1px rgb(16 22 26 / 40%), 0 0 0 rgb(16 22 26 / 0%), 0 0 0 rgb(16 22 26 / 0%)',
                                float: 'left',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <CaseEditor
                                    key={`CaseEditor-${this.state.activeCase.id}-${this.state.noteKey}`}
                                    showNotes={this.props.popup || true}
                                    doReload={() => {
                                        this.setState({
                                            dataKey: new Date().getTime(),
                                            noteKey: new Date().getTime()
                                        })
                                    }}
                                    noteKey={this.state.dataKey}
                                    text={this.state.editorText}
                                    analysisTitle={this.state.analysisTitle}
                                    saveText={this.saveText}
                                    onChange={(text, title) => {
                                        this.setState({ editorText: text, analysisTitle: title })
                                    }}
                                    case_id={this.state.activeCase.id}
                                    caseData={this.state.activeCase}
                                    setCaseData={(data) => {
                                        this.setState({
                                            activeCase: data
                                        })
                                    }}
                                    onNotesLoad={(notes) => {
                                        this.setState({
                                            folders: notes
                                        })
                                    }}
                                    editorLoaded={this.state.editorLoaded}
                                    reloadCases={() => {
                                        this.setState({
                                            dataKey: new Date().getTime(),
                                            noteKey: new Date().getTime()
                                        })
                                    }}
                                    onCloseCase={(closed) => {
                                        this.setState({
                                            expanded: false,
                                            activeCase: {
                                                ...this.state.activeCase,
                                                active: '0'
                                            },
                                            dataKey: new Date().getTime(),
                                            noteKey: new Date().getTime(),
                                            closed: closed ?? true
                                        })
                                    }}
                                    onUnarchiveCase={() => {
                                        this.setState({
                                            expanded: false,
                                            activeCase: {
                                                ...this.state.activeCase,
                                                active: '1',
                                                archive: '0'
                                            },
                                            dataKey: new Date().getTime(),
                                            noteKey: new Date().getTime(),
                                            closed: true
                                        })
                                    }}
                                />
                            </div>
                            <div style={{
                                width: '60%',
                                height: 'calc(100% - 50px)',
                                overflowY: 'hidden',
                                float: 'left'
                            }}>
                                <OneCase
                                    folders={this.state.folders}
                                    case={this.state.activeCase}
                                    text={this.state.editorText}
                                    analysisTitle={this.state.analysisTitle}
                                    activeCase={this.state.activeCase}
                                    key={this.state.activeCase.id + (this.folders ? this.folder.length : 0)}
                                    setCaseText={(text, title) => {
                                        this.setState({
                                            editorText: text,
                                            analysisTitle: title,
                                            editorLoaded: true
                                        })
                                    }}
                                    reloadCases={() => {
                                        this.setState({
                                            dataKey: new Date().getTime(),
                                            noteKey: new Date().getTime()
                                        })
                                    }}
                                    onFolderChange={() => {
                                        this.setState({
                                            noteKey: new Date().getTime()
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            </>
        )
    }
}

export default Cases;