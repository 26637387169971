import React from 'react';

import { Button, Dialog, Classes, InputGroup, ControlGroup, FormGroup } from '@blueprintjs/core';

import U from '../Utilities/Utilities'
import CountryLanguage from '../Utilities/CountryLanguage';
import API from '../Utilities/API';

class NewCase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            newTitle: this.props.title || '',
            newCountry: this.props.country || '',
            case: this.props.case || {},
            newReminder: this.props.case && this.props.case.reminder ? this.props.case.reminder : 48,
            sending: false,
            caseCountry: this.props.case && this.props.case.countryData ? this.props.case.countryData : false,
            caseLanguage: this.props.case && this.props.case.languageData ? this.props.case.languageData : false,
            caseCategory: this.props.case && this.props.case.categoryData ? this.props.case.categoryData : false,
            caseType: this.props.case && this.props.case.typeData ? this.props.case.typeData : false
        }

        this.onClose = this.onClose.bind(this);
        this.Save = this.Save.bind(this);
    }

    onClose() {
        this.setState({
            open: false,
            newTitle: '',
            newCountry: '',
            newReminder: 48,
            caseCountry: false,
            caseLanguage: false,
            caseCategory: false,
            caseType: false
        })
    }

    Save() {
        this.setState({
            sending: true
        }, () => {
            API.NewCase({
                id: this.props.id,
                title: this.state.newTitle,
                country: this.state.newCountry,
                caseCountry: this.state.caseCountry.id || 0,
                caseLanguage: this.state.caseLanguage.shortcode || 0,
                caseCategory: this.state.caseCategory.id || 0,
                caseType: this.state.caseType.id || 0,
                reminder: this.state.newReminder
            }).then((response) => {
                this.setState({
                    open: false,
                    sending: false,
                    newTitle: '',
                    newCountry: '',
                    newReminder: 48,
                    caseCountry: false,
                    caseLanguage: false,
                    caseCategory: false,
                    caseType: false
                }, () => {
                    this.props.loadCases();
                    if (this.props.onSave) this.props.onSave(response.data.Data);
                })
            });
        })
    }

    render() {
        return (
            <>
                <Button disabled={this.props.disabled} icon={this.props.icon ? this.props.icon : 'add'} onClick={() => { this.setState({ open: true }) }}>{this.props.small || this.props.hideText ? '' : (this.props.text ? this.props.text : 'New case')}</Button>
                <Dialog
                    isOpen={this.state.open}
                    onClose={this.onClose}
                    autoFocus={true}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    enforceFocus={true}
                    usePortal={true}
                    className={Classes.DARK}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <p>
                            <ControlGroup>
                                <FormGroup label={'Case title'} style={{ width: 360 }}>
                                    <InputGroup
                                        value={this.state.newTitle}
                                        onChange={(v) => {
                                            this.setState({
                                                newTitle: v.target.value
                                            })
                                        }}
                                        placeholder={'Case title'}
                                        style={{
                                            marginBottom: 10
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup label={'Reminder (h)'} style={{ width: 100 }}>
                                    <InputGroup
                                        value={this.state.newReminder}
                                        onChange={(v) => {
                                            this.setState({
                                                newReminder: v.target.value
                                            })
                                        }}
                                        placeholder={'Case reminder (h)'}
                                        style={{
                                            marginBottom: 10,
                                            textAlign: 'center'
                                        }}
                                    />
                                </FormGroup>
                            </ControlGroup>
                            <InputGroup
                                value={this.state.newCountry}
                                onChange={(v) => {
                                    this.setState({
                                        newCountry: v.target.value
                                    })
                                }}
                                placeholder={'Case description'}
                                style={{ display: 'none' }}
                            />
                        </p>
                        <p>
                            <CountryLanguage
                                vertical
                                country={this.state.caseCountry}
                                language={this.state.caseLanguage}
                                category={this.state.caseCategory}
                                case_type={this.state.caseType}
                                countrySelect={(ctr) => {
                                    this.setState({
                                        caseCountry: ctr
                                    })
                                }}
                                languageSelect={(ctr) => {
                                    this.setState({
                                        caseLanguage: ctr
                                    })
                                }}
                                categorySelect={(ctr) => {
                                    this.setState({
                                        caseCategory: ctr
                                    })
                                }}
                                typeSelect={(ctr) => {
                                    this.setState({
                                        caseType: ctr
                                    })
                                }}
                            />
                        </p>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button onClick={this.Save} loading={this.state.sending} intent={'primary'} icon={this.props.saveIcon ? this.props.saveIcon : 'add'}>{this.props.saveText ? this.props.saveText : 'Add Case'}</Button>
                            <Button onClick={this.onClose}>Close</Button>
                        </div>
                    </div>

                </Dialog>
            </>
        )
    }
}

export default NewCase;