import React, { useState, useEffect } from 'react';

import { Classes, Spinner, Button } from '@blueprintjs/core'

import API from '../Utilities/API';
import Search from '../Navigation/Search'
import Single from './Single';

export default (props) => {

    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [query, setQuery] = useState({});
    const [start, setStart] = useState(0);

    useEffect(() => {
        if (loaded == false) {
            API.Photo({ ...query, start }).then((result) => {
                if (result && result.data && result.data.Data) {
                    setData([...data, ...result.data.Data]);
                    setLoaded(true);
                }
            });
        }
    }, [loaded])

    return (
        <>
            <div className={Classes.DRAWER_HEADER}>
                <div style={{ width: '75%', margin: 'auto' }}>
                    <Search
                        hideLanguage
                        search={(country, language, keywords, query) => {
                            setData([]);
                            setStart(0);
                            setQuery({
                                country,
                                language,
                                keywords,
                                query
                            });
                            setLoaded(false);
                        }}
                        searchButtons={[]}
                    />
                </div>
            </div>
            <div className={Classes.DRAWER_BODY}>

                <div style={{
                    width: '75%',
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                }}>
                    {data.map((item, index) => (
                        <div style={{ width: '20%' }}>
                            <Single
                                data={item}
                                buttons={<>
                                    <Button icon={'plus'} text={'Add'} onClick={() => {
                                        if (props.addToCase) {
                                            if (window.confirm('Add photo to case?')) {
                                                props.addToCase(item.file_id, item.name);
                                            }
                                        }
                                    }} />
                                </>}
                            />
                        </div>
                    ))}
                    {!loaded && <div style={{ paddingTop: 20, paddingBottom: 20, width: '100%' }}><Spinner /></div>}
                    {loaded && data.length > 0 && data.length % 100 == 0 ? (
                        <div style={{ paddingTop: 20, paddingBottom: 20, width: '100%' }}>
                            <Button large fill onClick={() => {
                                setStart(start + 100);
                                setLoaded(false);
                            }}>Load more</Button>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className={Classes.DRAWER_FOOTER}>
                {loaded ? `Found ${data.length} results` : null}
            </div>
        </>
    )

}