import React, { useState, useEffect } from 'react';
import { Button } from '@blueprintjs/core'

import { Tooltip2 } from "@blueprintjs/popover2";

export default (props) => {

    return (
        <>
            <div style={{ marginLeft: 4, marginTop: 5, position: 'absolute', left: 0, bottom: 53 }}>
                <Tooltip2 content={'Notes'} hoverOpenDelay={0} >
                    <Button
                        icon={'asterisk'}
                        intent={'primary'}
                        large
                    />
                </Tooltip2>
            </div>
        </>
    )

}