import React, { useState, useEffect } from 'react';

import { Tag } from '@blueprintjs/core'

import API from '../Utilities/API';
import { Popover2 } from '@blueprintjs/popover2';
import WikiCard from './Card'

export default (props) => {

    const [wiki, setWiki] = useState([]);

    useEffect(() => {
        API.CaseWiki(props.case_id).then((response) => {
            setWiki(response.data.Data);
        })
    }, [])

    return (
        <>
            {
                Object.keys(wiki).map((type) => (
                    <>
                        <h3 style={{ marginBottom: 5, marginTop: 5 }}>{type}</h3>
                        {
                            wiki[type].map((kw) => (
                                <>
                                    <Popover2
                                        content={
                                            <div style={{ maxWidth: 500 }}>
                                                <WikiCard id={kw.id} />
                                            </div>
                                        }
                                        placement={'top'}
                                    >
                                        <Tag
                                            interactive
                                            minimal
                                            large
                                            style={{ marginRight: 10, marginBottom: 10 }}
                                            intent={kw.from === 'note' ? 'primary' : null}
                                        >{kw.title}</Tag>
                                    </Popover2>
                                </>
                            ))
                        }
                    </>
                ))
            }
        </>
    )

}