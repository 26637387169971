import React, { useState, useEffect } from 'react';

import { Button, Dialog, Classes } from '@blueprintjs/core'

import Edit from './Edit';

export default (props) => {

    const [showEdit, setShowEdit] = useState(false);

    return (
        <>
            <Button icon={'edit'} onClick={() => { setShowEdit(true) }}>Edit</Button>
            <Dialog
                title={props.wiki.title}
                isOpen={showEdit}
                autoFocus={true}
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                enforceFocus={false}
                usePortal={true}
                className={Classes.DARK}
                onClose={() => {
                    setShowEdit(false)
                }}
                style={{ width: 1600, maxHeight: 900 }}
            >
                <Edit id={props.wiki.id} />
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button icon={'cross'} onClick={() => {
                            setShowEdit(false);
                        }}>Cancel</Button>
                        <Button intent={'primary'} icon={'saved'}>Save & Close</Button>
                    </div>
                </div>
            </Dialog>
        </>
    )

}