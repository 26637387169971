import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, InputGroup } from '@blueprintjs/core'

export default (props) => {

    const [filter, setFilter] = useState('');

    return (
        <>
            <InputGroup
                large
                placeholder={'Filter'}
                leftIcon={'search'}
                onChange={(e) => {
                    setFilter(e.target.value)
                }}
            />
            <Menu style={{
                maxHeight: '75vh',
                overflow: 'auto'
            }}>
                {props.categories.filter((category) => (filter.length == 0 || category.name.toLowerCase().indexOf(filter.toLowerCase()) > -1)).map((category) => (
                    <MenuItem
                        text={category.name}
                        large
                        active={props.selected && props.selected.indexOf(category.id) > -1}
                        onClick={() => {
                            props.onClick && props.onClick(category);
                        }}
                    />
                ))}
            </Menu>
        </>
    )

}