import React from 'react';
import { ProgressBar, Button } from '@blueprintjs/core'
import U from '../Utilities/Utilities';

import prettyBytes from 'pretty-bytes';

class UploadListSingle extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            file: props.file
        }
    }

    render() {
        let response = this.state.file.upload && this.state.file.upload.response ? JSON.parse(this.state.file.upload.response) : false;
        return this.state.file.size > 0 ? (
            <>
                <tr>
                    <td style={{ width: 100, whiteSpace: 'nowrap', textAlign: 'center' }}>{U.prettyStampFull(this.state.file.insert_datetime)}</td>
                    <td style={{ width: '75%' }}>
                        {response || this.state.file.id ? <a href={this.state.file.location} target="_blank">{this.state.file.name}</a> : this.state.file.name}
                    </td>
                    <td>{this.props.button}</td>
                    <td style={{ width: 500, textAlign: 'right', whiteSpace: 'nowrap' }}>{prettyBytes(this.state.file.size || 0)}</td>
                    <td style={{ width: 500, textAlign: 'right', paddingTop: 15 }}>
                        {this.state.file.upload.canceled ? '' : (
                            <ProgressBar
                                animate={this.state.file.lastModified ? true : false}
                                stripes={this.state.file.lastModified ? true : false}
                                value={this.state.file.upload.progress}
                            />
                        )}
                    </td>
                    <td style={{ width: 20 }}>
                        {this.state.file.upload.canceled ? '' : (
                            <Button small intent={'danger'} onClick={this.props.cancel} icon={this.state.file.upload.progress < 1 ? 'stop' : 'trash'} />
                        )}
                    </td>
                </tr>
            </>
        ) : null
    }
}

export default UploadListSingle;