import React, { useState, useEffect } from 'react';

import { Button, Spinner } from '@blueprintjs/core';

import U from '../Utilities/Utilities';

export default ({ onSelect, selected, requests, loading }) => {



    const [text, setText] = useState('');
    const [response, setResponse] = useState('');

    let date = '';

    return (
        <>
            {loading && false && <Spinner />}
            {requests && requests.map((request) => {
                let showDate = false;
                if (date != U.date(request.last_update)) {
                    date = U.date(request.last_update);
                    showDate = true;
                }
                return (
                    <>
                        {showDate && (
                            <Button
                                fill
                                minimal
                                disabled
                                large
                                style={{ color: '#FFB366', cursor: 'default', position: 'sticky', top: 0, backgroundColor: '#30404d', zIndex: 1 }}
                            >{date}</Button>
                        )}
                        <Button
                            fill
                            large
                            active={selected.id === request.id}
                            onClick={() => {
                                onSelect && onSelect(request);
                            }}
                            style={{
                                marginBottom: 10
                            }}
                        >{request.text}</Button>
                    </>
                )
            })}
        </>
    )

}