import React, { useState, useEffect } from 'react';

import Actions from './Actions';
import API from '../Utilities/API';

import SingleResponse from './SingleResponse'

import { Spinner } from '@blueprintjs/core';

export default ({ request, reload, closeAI }) => {

    const [data, setData] = useState([]);
    const [update, setUpdate] = useState(0);

    useEffect(() => {
        API.AI.Request(request.id).then((response) => {
            setData(response.data.Data);
        });
    }, [update]);

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
            key={`SingleRequest-${request.id}-${update}`}
        >
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column-reverse',
                overflow: 'auto',
                flexBasis: 0,
                padding: 10
            }}>
                {data.filter((item) => (item.type != 'Original')).map((item) => (
                    <SingleResponse closeAI={closeAI} request={request} data={item} key={`Response-${item.id}-${update}`} />
                ))}
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexShrink: 0
            }}>
                <Actions
                    request={request}
                    onDone={() => {
                        setData([]);
                        setUpdate(new Date().getTime());
                        reload && reload();
                    }}
                    active
                />
            </div>
        </div>
    )

}