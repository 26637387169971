import React from 'react';

import { Card } from '@blueprintjs/core'

export default ({ note }) => {

    return (
        <Card>
            <h5>{note.title}</h5>
            <p>
                {note.fileData && note.fileData.id && (
                    <img src={note.fileData.location} align={'left'} style={{ width: 200, marginRight: 10 }} />
                )}
                {note.text}
            </p>
        </Card>
    )
}