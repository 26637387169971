import React from 'react';

import { Button, ProgressBar } from '@blueprintjs/core'
import { Tooltip2, Popover2 } from "@blueprintjs/popover2";
import Uploads from '../Utilities/Uploads';
import PasteUpload from './Paste';

class UploadButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            uploads: Uploads.List(),
            popupOpen: false
        }

        this.Reload = this.Reload.bind(this);
    }

    componentDidMount() {
        Uploads.onChange(this.Reload);
        Uploads.loadFromServer();
    }
    
    Reload() {
        this.setState({
            uploads: Uploads.List()
        })
    }

    render() {
        const progress = Math.round(Uploads.getTotalProgress()*100);
        return (
            <>
                <Tooltip2 content={'Uploads'} hoverOpenDelay={0}>
                    <Button
                        icon={this.state.uploads.length ? (progress < 100 ? (progress>0 ? null : 'cloud-upload') : 'confirm') : 'cloud-upload'}
                        large
                        active={this.props.active ? true : undefined}
                        style={{ marginLeft: 4, marginTop: 5 }}
                        onClick={this.props.onClick}
                        className={this.props.className}
                        intent={progress === 100 ? 'success' : null}
                    >{this.state.uploads.length ? (progress < 100 && progress > 0 ? progress +'%' : '') : ''}</Button>
                </Tooltip2>
                <Popover2
                    isOpen={this.state.popupOpen} 
                    content={
                        <div style={{ padding: 5, width: 300 }} >
                            <PasteUpload hide={() => {
                            this.setState({
                                popupOpen: false
                            })
                        }} />
                        </div>
                    }
                    placement={'right'}
                >
                    <Tooltip2 content={'Paste to upload'} hoverOpenDelay={0}>
                        <Button
                            icon={'clipboard'}
                            large
                            style={{ marginLeft: 4, marginTop: 5 }}
                            className={this.props.className}
                            onClick={() => {
                                this.setState({
                                    popupOpen: !this.state.popupOpen
                                })
                            }}
                        />
                    </Tooltip2>
                </Popover2>
            </>
        )
    }
}

export default UploadButton;