import React from 'react';

import { Button, MenuItem, Dialog, Classes, TextArea } from '@blueprintjs/core';

class EditText extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            text: props.text || ''
        }

        this.onClose = this.onClose.bind(this);
        this.setText = this.setText.bind(this);
        this.Save = this.Save.bind(this);
    }

    onClose() {
        this.setState({
            open: false,
            text: this.props.text || ''
        })
    }

    Save() {
        this.setState({
            open: false
        }, () => {
            if (this.props.Save) this.props.Save(this.state.text)
        });
    }

    setText(e) {
        this.setState({
            text: e.target.value
        })
    }

    render() {
        return (
            <>
                <MenuItem icon={'edit'} text={'Edit'} onClick={() => { this.setState({ open: true }) }} style={this.props.style} intent={this.state.text && this.state.text.length ? 'success' : null} />
                {this.state.open ? (
                    <Dialog
                        isOpen={true}
                        onClose={this.onClose}
                        autoFocus={true}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        enforceFocus={true}
                        usePortal={true}
                        className={Classes.DARK}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <TextArea
                                large
                                fill
                                style={{ height: 500, width: 750 }}
                                value={this.state.text}
                                onChange={this.setText}
                            />
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Button onClick={this.Save} loading={this.state.sending} intent={'primary'} icon={'upload'}>Send</Button>
                                <Button onClick={this.onClose}>Close</Button>
                            </div>
                        </div>

                    </Dialog>
                ) : null}
            </>
        )
    }
}

export default EditText;