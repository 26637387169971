import React from 'react';

import AllKeywords from '../Keywords/All';

import { Alignment, Button, Collapse, Tag, Checkbox } from '@blueprintjs/core';

import U from '../Utilities/Utilities';

import CaseTitle from '../Cases/CaseTitle';

class SingleGroup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: this.props.currentGroup && this.props.currentGroup.group && this.props.currentGroup.group.id === this.props.group.group.id,
            isNew: this.props.isNew
        }

        this.toggleExpanded = this.toggleExpanded.bind(this);
    }

    toggleExpanded(e) {
        this.setState({
            isNew: false
        }, () => {
            this.props.setCurrentGroup();
        });
    }

    render() {

        if (!this.props.group || !this.props.group.news || !this.props.group.news.length || !this.props.group.search) return null;

        //let time = U.prettyStamp(this.props.group.news[0].timestamp);
        let time = this.props.group.group.created > this.props.group.group.updated ? this.props.group.group.created : this.props.group.group.updated;
        time = this.props.showDate ? U.prettyStampFull(time) : U.prettyStamp(time);

        let color = null;
        if (this.state.expanded) color = '#182026';
        else {
            if (this.props.highlight) color = '#1F4B99';
            else if (this.state.isNew) color = '#1F4B99';
            else {
                if (this.props.group.group.news_count > 3) color = '#293742';
            }
        }

        let level = null;
        if (this.props.group.countries) {
            if (this.props.group.countries.length == 1) {
                if (this.props.group.group.news_count > 1) level = <Button intent={'success'} small style={{ marginRight: 20 }}>National</Button>;
            }
            else if (this.props.group.countries.length == 2) level = <Button intent={'warning'} small style={{ marginRight: 20 }}>2 Countries</Button>;
            else if (this.props.group.countries.length > 2) level = <Button intent={'danger'} small style={{ marginRight: 20 }}>International</Button>;
        }

        return (
            <>
                <Button
                    fill
                    alignText={Alignment.LEFT}
                    onClick={(e) => {
                        this.toggleExpanded(e);
                    }}
                    large
                    id={`SingleGroup${this.props.group.group.id}`}
                    style={{ backgroundColor: color, display: 'flex', borderLeft: '5px solid ' + (this.props.group.group.seen ? '#FFB366' : 'transparent') }}
                    className={'singleGroupButton' + (this.props.expanded ? ' expanded' : ' notexpanded')}
                >
                    {this.props.expanded ? (
                        <div style={{ whiteSpace: 'nowrap' }}>
                            <span style={{ color: '#62D96B' }} className={'bp5-monospace-text'}>{time}</span>&nbsp;&nbsp;
                            {this.props.buttons ? <>{this.props.buttons}&nbsp;&nbsp;</> : null}
                            <span style={{ color: '#FFB366' }}>{this.props.group.news[0].countryData ? this.props.group.news[0].countryData.name : ''}&nbsp;&raquo;&nbsp;
                                {this.props.group.search.title}</span>
                            &nbsp;-&nbsp;
                        </div>
                    ) : (
                        <div style={{ whiteSpace: 'nowrap' }}>
                            <h6 style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>
                                <span style={{ color: '#62D96B' }} className={'bp5-monospace-text'}>{time}</span>&nbsp;
                                <span style={{ color: '#FFB366' }}>{this.props.group.news[0].countryData.name}</span>&nbsp;-&nbsp;
                                {this.props.group.group.news_count}&nbsp;-&nbsp;
                                {this.props.group.search.title}
                            </h6>
                        </div>
                    )}
                    <div style={{ whiteSpace: 'nowrap', flexGrow: 1, overflowX: 'clip', textOverflow: 'ellipsis', minWidth: 0, direction: this.props.group.news[0].languageData && this.props.group.news[0].languageData.rtl == '1' ? 'rtl' : 'ltr' }} className={'articleText'}>
                        <span class={'translate'}>{U.strip_tags(this.props.group.news[0].title)}</span>
                    </div>
                    <div style={{ width: 150 }}></div>
                    {this.props.expanded ? (
                        <div style={{ float: 'right', whiteSpace: 'nowrap' }}>
                            <span style={{ marginRight: 20 }}>
                                <CaseTitle group_id={this.props.group.group.id} removeFromCase={this.props.removeFromCase} onClick={(casedata) => {
                                    if (this.props.casePopup) this.props.casePopup(casedata);
                                }} />
                            </span>
                            {level}{this.props.group.group.news_count}
                        </div>
                    ) : null}
                    <Collapse isOpen={this.state.expanded}>
                        <div>
                            <div className={'bp5-text-muted'} style={{ marginTop: '1rem', direction: this.props.group.news[0].languageData && this.props.group.news[0].languageData.rtl == '1' ? 'rtl' : 'ltr' }}>
                                {this.props.group.news[0].intro}
                            </div>
                            <p align={'right'}>
                                <a href={this.props.group.news[0].original_link} target={'_blank'}>read more...</a>
                            </p>
                            {true ? null : <AllKeywords keywords={this.props.group.news[0].keywords} />}
                        </div>
                    </Collapse>
                </Button>
            </>
        )
    }

}

export default SingleGroup;