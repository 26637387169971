import React, { useState, useEffect } from 'react';

import { Card, Icon } from '@blueprintjs/core';

import U from '../Utilities/Utilities';

import Linkify from 'react-linkify';

import OG from '../OG/Single'

import FileNote from './FileNote'

import Comments from '../Comments/Button';

export default (props) => {

    const note = props.note;

    return (
        <>
            <Card style={{ width: 'fit-content', marginBottom: 10, minWidth: '20%', maxWidth: '60%', backgroundColor: '#0C5174', paddingTop: 0 }}>
                <div style={{ width: '100%' }}>
                    <h5>{U.prettyStampFull(note.datetime)} <div style={{ float: 'right' }}><Icon icon={'user'} /> {note.user_name}</div></h5>
                    <p
                        style={{
                            whiteSpace: 'pre-wrap',
                            maxHeight: 300,
                            overflow: 'auto'
                        }}
                    >
                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={key}>
                                {decodeURI(decoratedText)}
                            </a>)}>
                            {note.file_id && note.file_id.length ? (
                                <FileNote note={note} />
                            ) : note.text}
                        </Linkify>
                    </p>
                </div>

                {note.link && note.link.length ? (
                    <OG url={note.link} />
                ) : null}

                <div style={{ marginTop: 20 }}>
                    <Comments content_id={`Note:${note.id}`} commentCount={note.commentCount} />
                </div>
            </Card>
        </>
    )

}