import React, { useState, useEffect } from 'react';
import { Callout, PopoverPosition, Spinner, Button } from '@blueprintjs/core';

import API from './API';

export default ({ id, auto, summaryData, buttons, request, visible, onDone }) => {

    const [data, setData] = useState(summaryData && summaryData.text ? summaryData : {});
    const [loading, setLoading] = useState(auto);
    const [expanded, setExpanded] = useState(visible || false);


    const SummarizeID = (id) => {
        API.Summarize(id).then((response) => {
            setData(response.data.Data);
            setLoading(false);
            if (response.data.Data && response.data.Data.text) {
                const x = response.data.Data.text.split(':');
                if (x[0] === 'OpenAI') {
                    setLoading(true);
                    setExpanded(true);
                    setTimeout(() => {
                        SummarizeID(id);
                    }, 3 * 1000);
                }
            }
            onDone && onDone(response.data.Data);
        })
    }

    useEffect(() => {
        if (auto) {
            if (id) {
                SummarizeID(id);
            }
            else if (request) {
                API.SummarizeRequest(request).then((response) => {
                    setData(response.data.Data);
                    setLoading(false);
                    onDone && onDone(response.data.Data);
                })
            }
        } else {
            if (data && data.text) {
                const x = data.text.split(':');
                if (x[0] === 'OpenAI') {
                    setLoading(true);
                    setExpanded(true);
                    if (id && id.length) {
                        setTimeout(() => {
                            SummarizeID(id);
                        }, 3 * 1000);
                    }
                }
            }
        }

    }, []);

    return (
        <>
            {loading ? <Spinner /> : <div style={{ marginBottom: 20 }}>
                {expanded && <Callout style={{ marginTop: 10, whiteSpace: 'pre-wrap', position: 'unset' }}>
                    {data.text || ''}
                    <div style={{ textAlign: 'right' }}>
                        {buttons && buttons.map((button) => (
                            <Button
                                text={button.text}
                                icon={button.icon}
                                onClick={() => {
                                    if (button.onClick) button.onClick(data.text);
                                }}
                            />
                        ))}
                    </div>
                </Callout>}
                <Button fill icon={!expanded ? 'expand-all' : 'collapse-all'} onClick={() => {
                    setExpanded(!expanded);
                }}>{expanded ? 'Hide' : 'Show'} AI Text</Button>
            </div>
            }
        </>
    )

}