import React, { useState, useEffect } from 'react';

import { ProgressBar, Tag } from '@blueprintjs/core';

import API from '../Utilities/API';

const SingleKeyword = (props) => {

    return (
        <Tag
            minimal
            interactive
            style={props.style}
            onClick={() => {
                if (props.toggleKeyword) props.toggleKeyword(props.keyword_id, props.active ? 0 : 1);
            }}
            intent={props.active ? 'primary' : null}
        >
            {props.title}
        </Tag>
    )
}

export default (props) => {

    const [data, setData] = useState({
        keywords: [],
        important: []
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        if (props.article_id) {
            API.Keywords(props.article_id).then((response) => {
                const keywords = response.data.Data;
                if (Object.keys(keywords).length) {
                    API.CaseIntelligenceKeywords(props.case_id, props.article_id).then((response) => {
                        setData({
                            keywords: keywords,
                            important: response.data.Data && response.data.Data.length ? response.data.Data.filter((k) => (k.important == '1')).map((k) => (k.keyword_id)) : []
                        });
                    });
                }
                setLoading(false);
            })
        }

        if (props.text) {
            API.PercolateText(props.text, props.text_id).then((response) => {
                const keywords = response.data.Data;
                if (Object.keys(keywords).length) {
                    setData({
                        keywords: keywords,
                        important: response.data.Data && response.data.Data.length ? response.data.Data.filter((k) => (k.important == '1')).map((k) => (k.keyword_id)) : []
                    });
                }
                setLoading(false);
            })
        }


    }, [props.article_id, props.text]);

    const toggleKeyword = (keyword_id, toggle) => {
        if (props.article_id) {
            API.CaseIntelligenceKeywords(props.case_id, props.article_id, keyword_id, toggle).then((response) => {
                setData({
                    keywords: data.keywords,
                    important: response.data.Data && response.data.Data.length ? response.data.Data.filter((k) => (k.important == '1')).map((k) => (k.keyword_id)) : []
                });
            });
        }
    }

    return !loading ? (
        <>
            {
                Object.keys(data.keywords).map((type) => (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: 20,
                        gap: 10,
                        width: '100%',
                        marginBottom: 20,
                        flexWrap: 'wrap'
                    }}>
                        <Tag
                            minimal
                            intent={'primary'}
                        >{type}</Tag>
                        {
                            data.keywords[type].map((kw) => (
                                <>
                                    <SingleKeyword
                                        title={kw.title}
                                        toggleKeyword={toggleKeyword}
                                        keyword_id={kw.id}
                                        active={data.important.indexOf(kw.id) > -1}
                                        style={{
                                            flexShrink: 0
                                        }}
                                    />
                                </>
                            ))
                        }
                    </div>
                ))
            }
        </>
    ) : <div style={{ marginBottom: 10 }}>
        <ProgressBar animate stripes />
    </div>;

}