import React from 'react';

import API from '../Utilities/API';
import SingleSource from './SingleSource';

import { Spinner, Button } from '@blueprintjs/core'

import MapButton from '../Map/Button';

import CasePopup from '../Cases/CasePopup';

class TravelSources extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            data: [],
            editArticle: {},
            showCasePopup: false,
            moveSourceID: null
        }

        this.loadData = this.loadData.bind(this);
        this.moveSourceToCase = this.moveSourceToCase.bind(this);
    }

    componentDidMount() {
        if (!this.state.loaded) {
            this.loadData();
        }
    }

    loadData() {
        API.CaseTravel(this.props.case_id).then((response) => {
            if (response && response.data && response.data.Data) {
                this.setState({
                    loaded: true,
                    data: response.data.Data ? response.data.Data : []
                }, () => {
                    this.props.setNumber(this.state.data.length);
                })
            }
        })
    }

    DeleteTravel(id) {
        if (window.confirm('Delete source?')) {
            API.DeleteTravelFromCase(this.props.case_id, id).then(() => {
                this.setState({
                    loaded: false,
                    data: []
                }, () => {
                    this.props.doReload();
                })
            })
        }
    }

    moveSourceToCase(c) {
        API.MoveSource('Travel', this.state.moveSourceID, this.props.case_id, c.id).then((response) => {
            this.setState({
                loaded: false,
                data: []
            }, () => {
                this.loadData();
            })
        });
    }

    render() {
        return !this.state.loaded ? <Spinner /> : (
            <>
                {this.props.showTitle && this.state.data.length ? <h3 style={{ marginLeft: 10 }}>Travel Security</h3> : null}
                {this.state.data.map((article) => (
                    <>
                        <SingleSource
                            data={{
                                title: article.title,
                                id: article.id,
                                url: article.original_link,
                                description: '',
                                rawContent: <><span dangerouslySetInnerHTML={{ __html: article.content }}></span></>,
                                saved_text: article.saved_text,
                                image: {
                                    url: article.image
                                },
                                timestamp: new Date(article.updated).toISOString().slice(0, 19).replace('T', ' '),
                                summary: article.summary,
                                folder: article.folder,
                                folders: article.folders
                            }}
                            type={'travel'}
                            case_id={this.props.case_id}
                            source_id={article.id}
                            key={`travel-${article.id}`}
                            buttons={
                                <>
                                    <MapButton
                                        content_id={`${this.props.case_id}-${article.id}`}
                                        Save={(data) => {
                                            return API.SaveGeo(`${this.props.case_id}-${article.id}`, data);
                                        }}
                                    />
                                </>
                            }
                            cancel={this.DeleteTravel.bind(this, article.id)}
                            move={() => {
                                this.setState({
                                    moveSourceID: article.id,
                                    showCasePopup: true
                                })
                            }}
                            folders={this.props.folders}
                            onItemFolderSelect={(item) => {
                                API.SetTravelNote(this.props.case_id, article.id, item.id).then(() => {
                                    this.setState({
                                        loaded: false,
                                        data: []
                                    }, () => {
                                        this.loadData();
                                    })
                                });
                            }}
                            onSelectedFolderChange={(folders) => {
                                API.SaveContentFolders(this.props.case_id, article.id, folders).then(() => {
                                    this.setState({
                                        loaded: false,
                                        data: []
                                    }, () => {
                                        this.loadData();
                                    })
                                })
                            }}
                            createNote={this.props.createNote}
                        />
                        {
                            this.state.showCasePopup ? (
                                <CasePopup
                                    onShow={() => {
                                        this.setState({ showCasePopup: true })
                                    }}
                                    onHide={() => {
                                        this.setState({ showCasePopup: false })
                                    }}
                                    onSelect={(c) => {
                                        this.moveSourceToCase(c);
                                    }}
                                />
                            ) : null
                        }
                    </>
                ))}
            </>
        )
    }
}

export default TravelSources;