import React, { useState, useEffect } from 'react';

import { Button } from '@blueprintjs/core'

import {
    File,
    convertInchesToTwip,
    Paragraph,
    HeadingLevel,
    TextRun,
    AlignmentType,
    Packer,
    ExternalHyperlink,
    TableOfContents,
    StyleLevel,
    Header,
    PageNumber,
    Footer,
    WidthType,
    Table,
    TableRow,
    TableCell,
    VerticalAlign,
    TableBorders
} from 'docx'
import U from '../Utilities/Utilities'
import { saveAs } from "file-saver";

const constructFromChild = (one_child, bold = false, italics = false, underline = false) => {
    const children = one_child.childNodes;
    let result = [];

    switch (one_child.nodeName.toLowerCase()) {
        case 'strong':
            bold = true;
            break;
        case 'u':
            underline = true;
            break;
        case 'em':
            italics = true;
            break;
        case '#text':
            result.push(
                new TextRun({
                    text: one_child.textContent,
                    bold,
                    italics,
                    underline
                })
            );
            break;
        default:
            break;
    }

    children.forEach((child) => {
        switch (child.nodeName.toLowerCase()) {
            case 'strong':
                bold = true;
            case 'u':
                underline = true;
            case 'em':
                italics = true;
            default:
                const tempResult = constructFromChild(child, bold, italics, underline);
                tempResult.forEach((tr) => {
                    result.push(tr);
                })
                break;
        }
    })

    return result;

}

const constructFromParagraph = (paragraph) => {

    const children = paragraph.childNodes;
    let result = [];

    children.forEach((child) => {
        const tempResult = constructFromChild(child, false, false, false);
        tempResult.forEach((tr) => {
            result.push(tr);
        })
    });

    return new Paragraph({
        children: result
    });

}

export default (props) => {

    const Download = () => {

        const el = window.document.createElement('html');
        el.innerHTML = props.text;
        const plist = Array.prototype.slice.call(el.getElementsByTagName('p'));
        const texts = plist.map((p) => (constructFromParagraph(p)));

        const notes = [];
        props.folders.forEach((note) => {

            notes.push(new Paragraph({
                text: note.title,
                heading: HeadingLevel.HEADING_1,
                widowControl: false
            }));

            notes.push(new Paragraph({
                text: note.text,
                widowControl: false
            }))

            note.news.forEach((article) => {
                notes.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `Intelligence: ${U.date(article.timestamp)} - ${article.title}`,
                                italics: true
                            })
                        ],
                        heading: HeadingLevel.HEADING_2,
                        widowControl: false
                    })
                );
                notes.push(
                    new Paragraph({
                        children: [
                            new ExternalHyperlink({
                                children: [
                                    new TextRun({
                                        text: article.original_link,
                                        style: "Hyperlink"
                                    }),
                                ],
                                link: article.original_link,
                            })
                        ],
                        widowControl: false
                    })
                );
                notes.push(
                    new Paragraph({
                        text: '',
                        widowControl: false
                    })
                );
            });

        });
        const document = new File({
            creator: 'NewsDesk',
            title: props.analysisTitle,
            features: {
                updateFields: true,
            },
            styles: {
                default: {
                    heading1: {
                        run: {
                            size: '11pt',
                            bold: true,
                            color: '#4472C4'
                        },
                        paragraph: {
                            alignment: AlignmentType.JUSTIFIED,
                            spacing: {
                                after: '8pt',
                                line: '12pt'
                            }
                        }
                    },
                    heading2: {
                        run: {
                            size: '11pt',
                            bold: false,
                            italics: true
                        },
                        paragraph: {
                            alignment: AlignmentType.JUSTIFIED,
                            spacing: {
                                after: '0pt',
                            }
                        }
                    },
                    document: {
                        run: {
                            size: '11pt',
                            font: 'Arial'
                        },
                        paragraph: {
                            alignment: AlignmentType.JUSTIFIED,
                            spacing: {
                                after: '8pt',
                            }
                        }
                    }
                },
                paragraphStyles: [
                    {
                        id: "TOC1",
                        name: "toc 1",
                        run: {
                            size: "9pt",
                            bold: true
                        }
                    },
                    {
                        id: "TOC2",
                        name: "toc 2",
                        run: {
                            size: "9pt",
                            italics: true
                        },
                        paragraph: {
                            indent: {
                                left: convertInchesToTwip(0.2),
                            }
                        }
                    },
                    {
                        id: "BigTitle",
                        name: "BigTitle",
                        run: {
                            size: '16pt',
                            bold: true,
                            color: '#4472C4'
                        },
                        paragraph: {
                            alignment: AlignmentType.JUSTIFIED,
                            spacing: {
                                after: '8pt',
                            }
                        }
                    },
                    {
                        id: "NoSpacingAfter",
                        name: "NoSpacingAfter",
                        paragraph: {
                            spacing: {
                                after: '0pt'
                            }
                        }
                    }
                ]
            },
            sections: [
                {
                    headers: {
                        default: new Header({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: props.activeCase.title,
                                            bold: true,
                                            color: '#4472C4',
                                            size: '8pt'
                                        }),
                                        new TextRun({
                                            text: ` - ${U.date(props.activeCase.last_update, true)}`,
                                            bold: true,
                                            color: '#000000',
                                            size: '8pt'
                                        })
                                    ]
                                })
                            ]
                        })
                    },
                    footers: {
                        default: new Footer({
                            children: [
                                new Table({
                                    borders: TableBorders.NONE,
                                    width: {
                                        size: 9070,
                                        type: WidthType.DXA,
                                    },
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    children: [PageNumber.CURRENT],
                                                                    bold: true,
                                                                    color: '#4472C4',
                                                                    size: '11pt'
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.RIGHT,
                                                            shading: {
                                                                fill: '#F2F2F2'
                                                            },
                                                            children: [
                                                                new TextRun({
                                                                    text: `${props.activeCase.countryData.name} - ${props.activeCase.title} - ${U.date(props.activeCase.last_update, true)}`
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER
                                                })
                                            ]
                                        })
                                    ]
                                })
                            ]
                        })
                    },
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    size: '16pt',
                                    text: `${props.activeCase?.countryData?.name}: ${props.activeCase?.analysis_title?.toUpperCase()}`
                                })
                            ],
                            style: 'BigTitle'
                        }),
                        new Paragraph({
                            text: ''
                        }),
                        new TableOfContents("Indice", {
                            hyperlink: true
                        }),
                        ...texts,
                        new Paragraph({
                            text: ''
                        }),
                        ...notes
                    ]
                }
            ]
        });

        Packer.toBlob(document).then(blob => {
            saveAs(blob, "Report.docx");
        });
    }

    return (
        <>
            <Button icon={'import'} style={{ marginRight: 6 }} onClick={() => {
                Download();
            }} />
        </>
    )
}