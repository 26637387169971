import React from 'react';

import { Alignment, Button, Icon } from '@blueprintjs/core';
import U from '../Utilities/Utilities';
import CaseTitle from '../Cases/CaseTitle';
import { PropTypes } from 'victory';

export default (props) => {
    const time = U.prettyStamp(props.data._source.updated);

    let category = props.data._source.category.join(' &raquo; ');
    if (props.data._source.content) category += ' ' + props.data._source.content.split("\n")[0].split(':')[1];

    return <Button
        active={props.isActive ? true : undefined}
        onClick={props.setActive}
        style={{ display: 'flex', textDecoration: 'none', paddingTop: 15, paddingBottom: 15 }}
        className={'singleGroupButton' + (props.expanded === false ? ' notexpanded' : ' expanded')}
        fill
        large
    >
        <span style={{ color: '#62D96B' }} className={'bp5-monospace-text'}>{time}</span>
        &nbsp;
        <Icon icon={'symbol-circle'} />
        &nbsp;
        <span style={{ color: '#FFB366', whiteSpace: 'nowrap' }} dangerouslySetInnerHTML={{ __html: category }} />
        {!props.expanded ? <div style={{ width: '100%', padding: 5 }} /> : (<>&nbsp;-&nbsp;</>)}
        <div style={{ whiteSpace: 'nowrap', flexGrow: 1, overflowX: 'clip', textOverflow: 'ellipsis', minWidth: 0 }} className={'articleText'} dangerouslySetInnerHTML={{ __html: props.data._source.title }} />
        <CaseTitle group_id={props.data._id} onClick={() => { }} cases={props.data._source.cases} />
        {props.expanded ? (
            <>
                <Button small intent={'success'} minimal disabled><span style={{ color: '#FFF' }} dangerouslySetInnerHTML={{ __html: props.data._source.content.split("\n")[2].split(':')[1] }} /></Button>
            </>
        ) : null}
    </Button>
}