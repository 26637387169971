import React from 'react';

import { Button } from '@blueprintjs/core'

class DraftCheck extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            checked: props.checkChecked ? props.checkChecked(props.checkData) : false
        }

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            checked: !this.state.checked
        }, () => {
            if (this.props.onCheck) this.props.onCheck(this.props.checkData, this.state.checked);
        });
    }

    render() {
        return (
            <Button
                small
                icon={this.state.checked ? 'tick-circle' : 'circle'}
                minimal
                intent={this.state.checked ? null : null}
                onClick={this.handleClick}
            />
        )
    }
}

export default DraftCheck;