import React from 'react';

import { TextArea, Button, Card, ButtonGroup } from '@blueprintjs/core'

class Write extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            text: '',
            type: 'comment',
            sending: false
        }

        this.setText = this.setText.bind(this);
        this.Send = this.Send.bind(this);
        this.Close = this.Close.bind(this);
    }

    setText(e) {
        this.setState({
            text: e.target.value
        })
    }

    Send() {
        let t = this.state.text;
        if (t.length === 0) return;
        let c = this.state.type;
        this.setState({
            text: '',
            type: 'comment',
            sending: true
        }, () => {
            if (this.props.Send) this.props.Send(t, c, () => {
                this.setState({
                    sending: false
                })
            });
        })
    }

    Close() {
        this.setState({
            text: '',
            type: 'comment'
        }, () => {
            if (this.props.Close) this.props.Close();
        })
    }

    render() {
        return (
            <Card>
                <div className={'comments-write'}>
                    <div>
                        <TextArea
                            large
                            fill
                            style={{ height: 100, width: '100%', minWidth: 350 }}
                            value={this.state.text}
                            onChange={this.setText}
                            disabled={this.state.sending}
                        />
                    </div>
                    <div style={{ marginTop: 20, textAlign: 'right' }}>
                        {this.props.hideTypes ? null : (
                            <ButtonGroup style={{ float: 'left' }}>
                                <Button active={this.state.type == 'comment'} text={'Comment'} onClick={() => { this.setState({ type: 'comment' }) }} intent={'primary'} />
                                <Button active={this.state.type == 'translate'} text={'Translation'} onClick={() => { this.setState({ type: 'translate' }) }} intent={'success'} />
                            </ButtonGroup>
                        )}
                        <Button onClick={this.Send} loading={this.state.sending} intent={'primary'} icon={'upload'} style={{ marginRight: 10 }}>Send</Button>
                        <Button onClick={this.Close} loading={this.state.sending} icon={'cross'}>Close</Button>
                    </div>
                    {this.props.hideTypes ? null : (
                        <div style={{ marginTop: 5, color: '#C5CBD3' }}>
                            * Article translation should be posted as "Translation", and everything else should be posted as "Comment".
                        </div>
                    )}
                </div>
            </Card>
        )
    }
}

export default Write;