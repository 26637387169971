import React, { useState } from 'react';
import API from '../Utilities/API';
import { Dialog, Button, Classes, InputGroup } from '@blueprintjs/core'

export default (props) => {

    const[newLinkTitle, setNewLinkTitle] = useState('');

    return (
        <>
            <Dialog
                    isOpen
                    onClose={props.onClose}
                    autoFocus={true}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    enforceFocus={true}
                    usePortal={true}
                    className={Classes.DARK}
                    style={{width: 650}}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <InputGroup placeholder={'Link title'} fill value={newLinkTitle} onChange={(e) => {
                            setNewLinkTitle(e.target.value);
                        }} />
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button intent={'success'} icon={'tick'} onClick={() => {
                                props.showNew(false);
                                API.NewLink(props.content_id, newLinkTitle).then((response) => {
                                    props.loadData();
                                })
                            }}>Save</Button>
                            <Button intent={'danger'} icon={'tick'} onClick={() => {
                                props.showNew(false);
                                props.setDoing(false);
                            }}>Cancel</Button>
                        </div>
                    </div>
                </Dialog>
        </>
    )

}