import React from 'react';

import API from '../Utilities/API'

import { Button } from '@blueprintjs/core'

class GroupCase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: true,
            selected: this.props.group && this.props.isInDraft ? this.props.isInDraft(this.props.group) : false
        }

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if (!this.state.selected) {
            if (this.props.addToDraft) {
                this.props.addToDraft({
                    ...this.props.group,
                    page: this.props.page
                });
                this.setState({
                    selected: true
                })
            }
        } else {
            if (this.props.removeFromDraft) {
                if (!window.confirm('Remove entry from draft?')) return false;
                this.props.removeFromDraft(this.props.group);
                this.setState({
                    selected: false
                })
            }
        }
    }

    render() {
        return (
            <>
                <Button
                    small
                    icon={this.state.selected ? 'full-circle' : 'circle'}
                    minimal
                    loading={!this.state.loaded}
                    intent={this.state.selected ? 'success' : null}
                    onClick={this.handleClick}
                />
            </>
        )
    }
}

export default GroupCase;