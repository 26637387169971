import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, MenuDivider, InputGroup } from '@blueprintjs/core'

import U from '../Utilities/Utilities'

export default (props) => {

    const [filter, setFilter] = useState('');

    const selected = props.languages.filter((language) => props.selected.indexOf(language.shortcode) > - 1);

    return (
        <>
            <InputGroup
                large
                placeholder={'Filter'}
                leftIcon={'search'}
                onChange={(e) => {
                    setFilter(e.target.value)
                }}
            />
            <Menu style={{
                maxHeight: '75vh',
                overflow: 'auto'
            }}>
                {selected.length > 0 && <MenuItem text={'Selected'} large />}
                {selected.map((language) => (
                    <MenuItem
                        text={<div dangerouslySetInnerHTML={{ __html: `&nbsp;&nbsp;&nbsp;&nbsp;${language.name}` }} />}
                        large
                        active
                        onClick={() => {
                            if (window.confirm('Remove?')) {
                                props.onClick && props.onClick(language);
                            }
                        }}
                    />
                ))}
                {selected.length > 0 && <MenuDivider />}
                {props.languages.filter((language) => (filter.length == 0 || U.startsWith(language.name, filter))).map((language) => (
                    <MenuItem
                        text={language.name}
                        large
                        active={props.selected && props.selected.indexOf(language.shortcode) > -1}
                        onClick={() => {
                            props.onClick && props.onClick(language);
                        }}
                    />
                ))}
            </Menu>
        </>
    )

}