import React from 'react';

import { Button, ControlGroup, Menu, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2';

import API from '../Utilities/API';

class CaseTitle extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: props.cases || (window.nd.cases && window.nd.cases[props.group_id]) ? true : false,
            case: props.cases ? props.cases : (window.nd.cases && window.nd.cases[props.group_id])
        }

        this.loadData = this.loadData.bind(this);
        this.renderCases = this.renderCases.bind(this);
        this.renderSingleCase = this.renderSingleCase.bind(this);
        this.renderMultipleCases = this.renderMultipleCases.bind(this);
        this.renderCaseList = this.renderCaseList.bind(this);
    }

    componentDidMount() {
        if (!this.state.loaded) this.loadData();
    }

    componentWillUnmount() {
        //delete window['reloadCaseTitle' + this.props.group_id];
    }

    loadData() {
        API.GroupCase(this.props.group_id).then((response) => {
            if (!window.nd.cases) window.nd.cases = {};
            if (!response || !response.data || !response.data.Data) return false;
            window.nd.cases[this.props.group_id] = response.data.Data;
            this.setState({
                loaded: true,
                case: response.data.Data
            })
        })
    }

    renderSingleCase() {
        return (
            <ControlGroup style={{ display: 'inline' }}>
                <Button
                    small
                    active
                    onClick={() => {
                        if (this.props.onClick) this.props.onClick(this.state.case[0]);
                    }}
                >
                    <span className={'case-title'}>{this.state.case[0].title}</span>
                </Button>
                {this.props.removeFromCase ? (
                    <Button small active icon={'cross'} onClick={() => {
                        this.props.removeFromCase(this.props.group_id, this.state.case[0].id);
                    }} />) : null}
            </ControlGroup>
        )
    }

    renderCaseList() {
        return (
            <Menu>
                {this.state.case.map((c) => (
                    <MenuItem
                        text={c.title}
                        label={(
                            this.props.removeFromCase ? <Button small icon={'cross'} onClick={() => { this.props.removeFromCase(this.props.group_id, c.id) }}></Button> : null
                        )}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (this.props.onClick) this.props.onClick(this.state.case[0]);
                        }}
                    />
                ))}
            </Menu>
        );
    }

    renderMultipleCases() {
        return (
            <Popover2
                interactionKind={'hover'}
                hoverOpenDelay={300}
                hoverCloseDelay={300}
                content={this.renderCaseList()}
            >
                <Button
                    active
                    small
                >{this.state.case[0].title} ({this.state.case.length})</Button>
            </Popover2>
        )
    }

    renderCases() {
        return <>
            {this.state.case.length > 1 ? this.renderMultipleCases() : this.renderSingleCase()}
        </>
    }

    render() {
        window['reloadCaseTitle' + this.props.group_id] = () => {
            this.setState({
                loaded: false
            }, () => {
                this.loadData();
            });
        }
        return !this.state.loaded || !this.state.case || this.state.case.length == 0 || !this.state.case[0] || !this.state.case[0].title ? <></> : this.renderCases()
    }
}

export default CaseTitle;