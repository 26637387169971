import { Alignment, Navbar, NavbarGroup, NavbarHeading, NavbarDivider } from '@blueprintjs/core'

export default function () {
    return (
        <>
            <div style={{
                width: '100%',
                height: 50
            }}>
                <Navbar style={{ position: 'sticky', top: 0, paddingLeft: 0 }}>
                    <NavbarGroup>
                        <NavbarHeading>Sanctions</NavbarHeading>
                        <NavbarDivider />
                    </NavbarGroup>
                </Navbar>
            </div>
            <div style={{
                width: '100%',
                height: 'calc(100% - 50px)'
            }}>
                <iframe src={'https://www.opensanctions.org/'} width={'100%'} height={'100%'} frameBorder={0} referrerPolicy='no-referrer'></iframe>
            </div>
        </>
    )
}