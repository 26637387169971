import React, { useState, useEffect } from 'react';
import { Select } from "@blueprintjs/select";
import { MenuItem, Button, ButtonGroup } from '@blueprintjs/core'
import U from '../Utilities/Utilities'

import API from '../Utilities/API';

export default (props) => {

    const TypeSelect = Select.ofType();

    const [data, setData] = useState([]);
    const [type, setType] = useState(props.selected && props.selected.title ? props.selected : {
        title: 'Select case type'
    });

    useEffect(() => {
        API.getFilters().then((response) => {
            setData([
                {
                    title: 'Select case type'
                },
                ...response.data.Data.case_types,
            ]);
        });
    }, [])

    const filter = (query, data, i, exact) => {
        let title = '';
        if (data.name) title = data.name.toLowerCase();
        if (data.title) title = data.title.toLowerCase();
        query = query.toLowerCase();
        if (exact) return title === query;
        else return title.length == 0 || U.startsWith(title, query);
    }

    return (
        <>
            <ButtonGroup>
                <TypeSelect
                    fill
                    items={data}
                    itemPredicate={filter}
                    itemRenderer={(c, { handleClick }) => {
                        return <MenuItem
                            text={<div dangerouslySetInnerHTML={{ __html: c.title }}></div>}
                            onClick={handleClick}
                        />
                    }}
                    onItemSelect={(id) => {
                        setType(id);
                        if (props.onChange) props.onChange(id);
                    }}
                    popoverProps={{ className: 'limit-popup' }}
                >
                    <Button minimal={!type.id} fill active={!!type.id} text={type.title.replace(/\&nbsp;/g, '').trim()} />
                </TypeSelect>
                {type.id && (
                    <Button
                        icon={'cross'}
                        onClick={() => {
                            setType(data[0]);
                            props.onReset && props.onReset()
                        }}
                    />
                )}
            </ButtonGroup>
        </>
    )

}