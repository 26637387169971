import React, { useState, useEffect } from 'react';

import { Button, Tag } from '@blueprintjs/core'

import CommentList from './Comments';

export default (props) => {

    const [showList, setShowList] = useState(false);
    const [num, setNum] = useState(props.commentCount || 0)

    return (
        <>
            {showList ? null : (
                <Button
                    fill
                    onClick={() => {
                        setShowList(!showList);
                    }}
                    icon={'comment'}
                    intent={num > 0 ? 'primary' : null}
                >Comments {num > 0 ? <Tag>{num}</Tag> : null}</Button>
            )}

            {showList ? (
                <CommentList
                    showWriting={true}
                    hideTypes
                    content_id={props.content_id}
                    setNumber={(n) => {
                        setNum(n)
                    }}
                    increaseNumber={() => {
                        setNum(num + 1);
                    }}
                    Close={() => {
                        setShowList(false);
                    }}
                    onSend={props.onSend}
                />
            ) : null}
        </>
    )

}