import React from 'react';

import { Alignment, Button, Classes, ControlGroup, Drawer, Navbar, NavbarGroup, Position, Tag } from '@blueprintjs/core';
import { Popover2 } from "@blueprintjs/popover2";
import API from '../Utilities/API';

import IntelligenceSources from '../Sources/Intelligence';
import TravelSources from '../Sources/Travel';

import OG from '../Sources/OG';

import ExternalSources from '../Sources/External';
import UploadList from '../Upload/List';
import Uploads from '../Utilities/Uploads';

import UploadSources from '../Sources/Uploads';


import TreeSources from '../Sources/Tree';
import CaseWiki from '../Wiki/CaseWiki';

import MediaButton from '../Media/Button';
import PasteUpload from '../Upload/Paste';

import SSButton from '../SavedSearch/Button';
import SSNews from '../SavedSearch/News';
import DOCX from './DOCX';

import IntelligenceNews from '../Intelligence/News';

class OneCase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sourceType: props.showNotes ? 'intelligence' : 'all',
            editorType: props.showNotes ? 'notes' : 'editor',
            numbers: {
                all: 0,
                intelligence: 0,
                external: 0,
                upload: 0,
                notes: 0,
                travel: 0
            },
            loadKey: 0,
            popupOpen: false,
            popupUploadOpen: false,
            loading: false,
            setF: false,
            showKeywords: false,
            folders: props.folders || false,
            ssOpen: false,
            ssID: false,
            ssTitle: ''
        }

        this.setLoading = this.setLoading.bind(this);
        this.setLoaded = this.setLoaded.bind(this);

        this.UploadStatus = this.UploadStatus.bind(this);
        this.doReload = this.doReload.bind(this);
        this.addMediaToCase = this.addMediaToCase.bind(this);

        window.doCaseReload = function () {
            this.setState({
                loadKey: new Date().getTime()
            })
        }
    }

    componentDidMount() {
        Uploads.onChange((e) => {
            this.UploadStatus(e)
        }, 'OneCase');
    }

    componentWillUnmount() {
        Uploads.onChangeStop('OneCase');
    }


    UploadStatus(e) {
        if (e.type === 'uploadStart' && this.state.sourceType != 'uploads') {
            this.setState({
                sourceType: 'uploads'
            })
        }
        else if (e.type === 'uploadComplete') {
            let x = JSON.parse(e.file.upload.response);
            if (x.Data.file_id) {
                if (window.confirm('File uploaded. Add to current case?')) {
                    API.AddUploadToCase(this.props.case.id, x.Data.file_id).then(() => {
                        API.DeleteUpload(x.Data.file_id).then(() => {
                            Uploads.loadFromServer({
                                type: 'uploadDeleted'
                            });
                        });
                    })
                }
            }
        }
        else if (e.type === 'uploadDeleted') {
            setTimeout(() => {
                this.setState({
                    loadKey: new Date().getTime()
                })
            });
        }
    }

    addMediaToCase(file_id, title) {
        API.AddUploadToCase(this.props.case.id, file_id, title).then(() => {
            Uploads.loadFromServer({
                type: 'uploadDeleted'
            });
        })
    }

    setNumber(n, v) {
        let numbers = this.state.numbers;
        let all = 0;

        numbers[n] = v;
        Object.keys(numbers).forEach((n) => {
            if (n != 'all') {
                all += numbers[n];
            }
        });
        numbers['all'] = all;
        this.setState({
            numbers: numbers
        })
    }

    setLoading() {
        this.setState({
            loading: true,
            popupOpen: false
        })
    }

    setLoaded() {
        this.setState({
            loading: false
        })
    }

    doReload() {
        this.setState({
            loadKey: new Date().getTime()
        })
    }

    static getDerivedStateFromProps(props, state) {
        if (props.folders !== state.folders) {
            return {
                folders: props.folders,
                loadKey: new Date().getTime()
            };
        }
        return null;
    }

    render() {
        return (
            <>
                {this.state.ssOpen && (
                    <Drawer
                        icon={'box'}
                        title={'Add Intelligence' + (this.state.ssTitle && this.state.ssTitle.length ? `: ${this.state.ssTitle}` : '')}
                        autoFocus
                        canEscapeKeyClose={false}
                        canOutsideClickClose={false}
                        enforceFocus
                        hasBackdrop
                        position={Position.RIGHT}
                        size={'100%'}
                        isOpen={true}
                        className={Classes.DARK}
                        onClose={() => {
                            this.setState({
                                ssOpen: false,
                                ssID: null,
                                ssTitle: null
                            })
                        }}
                    >

                        {this.state.ssID != '' ? (
                            <IntelligenceNews
                                searchID={this.state.ssID}
                                caseID={this.props.case}
                                onSelect={() => {
                                    this.setState({
                                        sourceType: 'intelligence',
                                        loadKey: new Date().getTime(),
                                        loading: false
                                    }, () => {
                                        if (window.confirm('News added to case. Close saved search?')) {
                                            this.setState({
                                                ssOpen: false,
                                                ssID: null,
                                                ssTitle: null
                                            })
                                        }
                                    })
                                }}
                                closeSavedSearch={() => {
                                    this.setState({
                                        ssOpen: false,
                                        ssID: '',
                                        ssTitle: ''
                                    })
                                }}
                                forceCase
                            />
                        ) : (
                            <IntelligenceNews
                                caseID={this.props.case}
                                onSelect={() => {
                                    this.setState({
                                        sourceType: 'intelligence',
                                        loadKey: new Date().getTime(),
                                        loading: false
                                    }, () => {
                                        if (window.confirm('News added to case. Close saved search?')) {
                                            this.setState({
                                                ssOpen: false,
                                                ssID: null,
                                                ssTitle: null
                                            })
                                        }
                                    })
                                }}
                                forceCase
                                onSavedSearchCreate={(saved_search) => {
                                    API.SavedSearch(saved_search.id).then((result) => {
                                        this.setState({
                                            ssOpen: true,
                                            ssID: result.data.Data.search.id,
                                            ssTitle: result.data.Data.search.title
                                        }, () => {
                                            if (window.confirm('Saved search created. Add to case?')) {
                                                API.ToggleSearchCase(this.props.case.id, result.data.Data.search.id).then(() => {
                                                    if (window.confirm('Search added to case. Close the search?')) {
                                                        this.setState({
                                                            ssOpen: false,
                                                            ssID: null,
                                                            ssTitle: null,
                                                            loadKey: new Date().getTime(),
                                                            loading: false
                                                        });
                                                    } else {
                                                        this.setState({
                                                            loadKey: new Date().getTime(),
                                                            loading: false
                                                        })
                                                    }
                                                });
                                            }
                                        })
                                    });
                                }}
                            />
                        )
                        }
                    </Drawer>
                )}
                <Navbar style={{ padding: 10, paddingTop: 0, paddingBottom: 7, marginTop: 1, boxShadow: 'none' }}>
                    <NavbarGroup>
                        <ControlGroup style={{ marginRight: 5 }}>
                            <Button active={this.state.sourceType == 'all' ? true : undefined} onClick={() => { this.setState({ sourceType: 'all' }) }}>
                                Sources <Tag minimal round>{this.state.numbers.all}</Tag>
                            </Button>
                            <Button active={this.state.sourceType == 'tree' ? true : undefined} onClick={() => { this.setState({ sourceType: 'tree' }) }} icon={'diagram-tree'} />
                        </ControlGroup>
                        <ControlGroup style={{ marginRight: 5 }}>
                            <Button active={this.state.sourceType == 'intelligence' ? true : undefined} onClick={() => { this.setState({ sourceType: 'intelligence' }) }}>
                                Intelligence <Tag minimal round>{this.state.numbers.intelligence}</Tag>
                            </Button>
                            <SSButton
                                showIntelligence
                                placement={'bottom'}
                                large={false}
                                style={{}}
                                onClick={(e, id, title) => {
                                    e.preventDefault();
                                    this.setState({
                                        ssOpen: true,
                                        ssID: id,
                                        ssTitle: title
                                    })
                                }}
                                onAddToCase={(e, id, title) => {
                                    if (window.confirm(`Add "${title}" to case?`)) {
                                        API.ToggleSearchCase(this.props.case.id, id).then(() => {
                                            this.setState({
                                                loadKey: new Date().getTime(),
                                                loading: false
                                            })
                                        })
                                    }
                                }}
                            />
                        </ControlGroup>
                        <ControlGroup style={{ marginRight: 5 }}>
                            <Button active={this.state.sourceType == 'external' ? true : undefined} onClick={() => { this.setState({ sourceType: 'external' }) }}>
                                www <Tag minimal round>{this.state.numbers.external}</Tag>
                            </Button>
                            <Popover2
                                isOpen={this.state.popupOpen}
                                content={
                                    <div style={{ padding: 5, width: 300 }} className={'pasteInput'}>
                                        <ControlGroup style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                            <OG
                                                case_id={this.props.case.id}
                                                reloadExternal={() => {
                                                    this.setState({
                                                        loadKey: new Date().getTime(),
                                                        loading: false
                                                    })
                                                }}
                                                onExisting={() => {
                                                    this.setState({
                                                        sourceType: 'intelligence',
                                                        loadKey: new Date().getTime(),
                                                        loading: false
                                                    })
                                                }}
                                                setLoading={this.setLoading}
                                                setLoaded={this.setLoaded}
                                            />
                                            <Button icon={'cross'} onClick={() => {
                                                this.setState({
                                                    popupOpen: false
                                                })
                                            }} />
                                        </ControlGroup>
                                    </div>
                                }
                                placement={'bottom'}
                            >
                                <Button loading={this.state.loading} icon={'clipboard'} onClick={() => { this.setState({ sourceType: 'external', popupOpen: !this.state.popupOpen }) }}></Button>
                            </Popover2>
                        </ControlGroup>
                        <ControlGroup style={{ marginRight: 5 }}>
                            <Button active={this.state.sourceType == 'uploads' ? true : undefined} onClick={() => { this.setState({ sourceType: 'uploads' }) }} icon={'cloud-upload'}>
                                <Tag minimal round>{this.state.numbers.upload}</Tag>
                            </Button>
                            <Popover2
                                isOpen={this.state.popupUploadOpen}
                                content={
                                    <div style={{ padding: 5, width: 300 }} >
                                        <PasteUpload hide={() => {
                                            this.setState({
                                                popupUploadOpen: false
                                            })
                                        }} />
                                    </div>
                                }
                                placement={'bottom'}
                            >
                                <Button loading={this.state.loading} icon={'clipboard'} onClick={() => { this.setState({ sourceType: 'uploads', popupUploadOpen: !this.state.popupUploadOpen }) }}></Button>
                            </Popover2>
                            <MediaButton
                                onClick={() => { this.setState({ sourceType: 'uploads' }) }}
                                addToCase={this.addMediaToCase}
                            />
                        </ControlGroup>
                        <ControlGroup style={{ marginRight: 5 }}>
                            <Button active={this.state.sourceType == 'travel' ? true : undefined} onClick={() => { this.setState({ sourceType: 'travel' }) }} icon={'airplane'}>
                                <Tag minimal round>{this.state.numbers.travel}</Tag>
                            </Button>
                        </ControlGroup>
                        <ControlGroup style={{ marginRight: 5 }}>
                            <Button active={this.state.sourceType == 'wiki' ? true : undefined} onClick={() => { this.setState({ sourceType: 'wiki' }) }}>W</Button>
                        </ControlGroup>
                    </NavbarGroup>
                    <NavbarGroup align={Alignment.RIGHT}>
                        <DOCX {...this.props} />
                        <Button style={{ display: 'none' }} active={this.state.showKeywords} onClick={() => {
                            this.setState({
                                showKeywords: !this.state.showKeywords,
                                loadKey: new Date().getTime()
                            })
                        }}>Keywords</Button>
                    </NavbarGroup>
                </Navbar>
                <div style={{ padding: 10, height: 'calc(100% - 50px)', overflowY: 'auto' }}>
                    <div style={{ display: (this.state.sourceType == 'tree' ? 'block' : 'none') }}>
                        <TreeSources
                            case_id={this.props.case.id}
                            key={'tree-' + this.state.loadKey}
                            doReload={this.doReload}
                        />
                    </div>
                    <div style={{ display: (this.state.sourceType == 'intelligence' || this.state.sourceType == 'all' ? 'block' : 'none') }}>
                        {this.state.folders !== false ? (
                            <IntelligenceSources
                                showKeywords={this.state.showKeywords}
                                case_id={this.props.case.id}
                                setCaseText={this.props.setCaseText}
                                setNumber={this.setNumber.bind(this, 'intelligence')}
                                showTitle={this.state.sourceType != 'intelligence'}
                                doReload={this.doReload}
                                key={'intelligence-' + this.state.loadKey}
                                folders={this.state.folders}
                                createNote={(text) => {
                                    this.setState({
                                        sourceType: 'notes'
                                    }, () => {
                                        if (window.createCaseNote) {
                                            window.createCaseNote(text)
                                        }
                                    })
                                }}
                                openSavedSearch={(e, id, title) => {
                                    e.preventDefault();
                                    this.setState({
                                        ssOpen: true,
                                        ssID: id,
                                        ssTitle: title
                                    })
                                }}
                                closeSavedSearch={() => {
                                    this.setState({
                                        ssOpen: false,
                                        ssID: '',
                                        ssTitle: ''
                                    })
                                }}
                                onFolderChange={() => {
                                    this.props.onFolderChange && this.props.onFolderChange();
                                }}
                            />
                        ) : null}
                    </div>
                    <div style={{ display: (this.state.sourceType == 'external' || this.state.sourceType == 'all' ? 'block' : 'none') }}>
                        <ExternalSources
                            case_id={this.props.case.id}
                            setNumber={this.setNumber.bind(this, 'external')}
                            key={'external-' + this.state.loadKey}
                            showTitle={this.state.sourceType != 'external'}
                            doReload={this.doReload}
                            onSave={() => {
                                this.setState({
                                    sourceType: 'intelligence',
                                    loadKey: new Date().getTime(),
                                    loading: false
                                })
                            }}
                        />
                    </div>
                    <div style={{ display: (this.state.sourceType == 'travel' || this.state.sourceType == 'all' ? 'block' : 'none') }}>
                        {this.state.folders !== false ? (
                            <TravelSources
                                case_id={this.props.case.id}
                                setNumber={this.setNumber.bind(this, 'travel')}
                                showTitle={this.state.sourceType != 'travel'}
                                doReload={this.doReload}
                                key={'travel-' + this.state.loadKey}
                                folders={this.state.folders}
                                createNote={(text) => {
                                    this.setState({
                                        sourceType: 'notes'
                                    }, () => {
                                        if (window.createCaseNote) {
                                            window.createCaseNote(text)
                                        }
                                    })
                                }}
                            />
                        ) : null}
                    </div>
                    <div style={{ display: (this.state.sourceType == 'wiki' ? 'block' : 'none') }}>
                        <CaseWiki case_id={this.props.case.id} />
                    </div>
                    <div style={{ display: (this.state.sourceType == 'uploads' || this.state.sourceType == 'all' ? 'block' : 'none') }}>
                        {this.state.sourceType == 'uploads' ? (
                            <UploadList
                                showTitle={this.state.sourceType != 'uploads'}
                                case_id={this.props.case.id}
                                key={'uploads-' + this.state.loadKey}
                                hideTopBar={true}
                                doReload={this.doReload}
                                button={
                                    <Button
                                        small
                                        icon={'plus'}
                                        style={{ whiteSpace: 'nowrap' }}
                                        clicked={(upload_id) => {
                                            API.AddUploadToCase(this.props.case.id, upload_id).then(() => {
                                                Uploads.reloadFunction();
                                                setTimeout(() => {
                                                    this.setState({
                                                        loadKey: new Date().getTime()
                                                    })
                                                }, 1000);
                                            })
                                        }}
                                    >Add to case</Button>
                                }
                                onFolderChange={() => {
                                    this.props.onFolderChange && this.props.onFolderChange();
                                }}
                            />
                        ) : null}
                        {this.state.folders !== false ? (
                            <UploadSources
                                case_id={this.props.case.id}
                                key={'case_uploads-' + this.state.loadKey}
                                setNumber={this.setNumber.bind(this, 'upload')}
                                showTitle={this.state.sourceType != 'uploads'}
                                doReload={this.doReload}
                                folders={this.state.folders}
                                onFolderChange={() => {
                                    this.props.onFolderChange && this.props.onFolderChange();
                                }}
                            />
                        ) : null}
                    </div>
                </div>
            </>
        )
    }
}

export default OneCase;