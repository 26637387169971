import React, { useState, useEffect } from 'react';

import { Button } from '@blueprintjs/core'
import API from '../Utilities/API';
import { Tooltip2 } from "@blueprintjs/popover2";

export default ({ data, onCreateAssignment = () => { } }) => {

    return (
        <>
            <Tooltip2 content={'Create assignment from note'}>
                <Button
                    icon={'annotation'}
                    intent={'success'}
                    onClick={() => {
                        if (data.text.length < 5 || data.title.length < 5) {
                            alert('You need to fill text and title to send as an assignment!');
                        } else {
                            if (window.confirm('Create assignment?')) {
                                API.AssignmentFromNote(data).then((response) => {
                                    if (response.data && response.data.Data && response.data.Data.error) alert(response.data.Data.error);
                                    else {
                                        onCreateAssignment && onCreateAssignment();
                                    }
                                });
                            }
                        }
                    }}
                />
            </Tooltip2>
        </>
    )

}