import React, { useState } from 'react';

import API from '../Utilities/API';
import SingleSource from './SingleSource';

import { Spinner, Button, Callout, ButtonGroup, Tag } from '@blueprintjs/core'

import EditText from './EditText';
import MapButton from '../Map/Button';

import Keywords from '../Intelligence/Keywords';
import CasePopup from '../Cases/CasePopup';


import U from '../Utilities/Utilities'
import { SearchSharp } from '@material-ui/icons';

function SingleSavedButton({ search, case_id, openSavedSearch, loadSavedSearches }) {

    const [hideNew, setHideNew] = useState(false);

    if (!search.title || !search.title.length) return null;

    return (
        <>
            <ButtonGroup style={{ marginRight: 10 }}>
                <Button
                    text={search.title}
                    onClick={(event) => {
                        setHideNew(true);
                        openSavedSearch && openSavedSearch(event, search.id, search.title);
                    }}
                    icon={
                        <>
                            {search.new && search.new != '0' && search.new != 0 && !hideNew ? <Tag round>{U.formatNumber(search.new)}</Tag> : null}
                        </>
                    }
                />
                <Button
                    icon={'cross'}
                    onClick={() => {
                        if (window.confirm('Delete saved search from case?')) {
                            API.ToggleSearchCase(case_id, search.id).then(() => {
                                loadSavedSearches && loadSavedSearches();
                            });
                        }
                    }}
                />
            </ButtonGroup>
        </>
    )
}

class IntelligenceSources extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            data: [],
            savedSearches: [],
            editArticle: {},
            showKeywords: props.showKeywords || false,
            showCasePopup: false,
            moveSourceID: null
        }

        this.loadData = this.loadData.bind(this);
        this.loadSavedSearches = this.loadSavedSearches.bind(this);
        this.moveSourceToCase = this.moveSourceToCase.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.showKeywords != state.showKeywords) {
            return {
                showKeywords: props.showKeywords
            }
        } else return null;
    }

    componentDidMount() {
        if (!this.state.loaded) {
            this.loadData();
            this.loadSavedSearches();
        }
    }

    loadSavedSearches() {
        API.CaseSavedSearches(this.props.case_id).then((result) => {
            result && result.data && result.data.Data && this.setState({
                savedSearches: result.data.Data
            })
        })
    }

    loadData() {
        API.CaseNews(this.props.case_id).then((response) => {
            this.setState({
                loaded: true,
                data: response.data.Data && response.data.Data.news ? response.data.Data.news : []
            }, () => {
                this.props.setCaseText(response.data.Data.text, response.data.Data.analysis_title)
                this.props.setNumber(this.state.data.length);
            })
        })
    }

    DeleteIntelligence(id) {
        if (window.confirm('Delete source?')) {
            API.DeleteIntelligenceFromCase(this.props.case_id, id).then(() => {
                this.setState({
                    loaded: false,
                    data: []
                }, () => {
                    this.props.doReload();
                })
            })
        }
    }

    moveSourceToCase(c) {
        API.MoveSource('Intelligence', this.state.moveSourceID, this.props.case_id, c.id).then((response) => {
            this.setState({
                loaded: false,
                data: []
            }, () => {
                this.loadData();
            })
        });
    }

    render() {
        return !this.state.loaded ? <Spinner /> : (
            <>
                {this.state.savedSearches.length ? (
                    <div style={{ marginBottom: 20, marginLeft: 10 }}>
                        <h3>Saved Searches</h3>
                        <p>
                            {this.state.savedSearches.map((search) => (
                                <>
                                    <SingleSavedButton
                                        search={search}
                                        case_id={this.props.case_id}
                                        openSavedSearch={this.props.openSavedSearch}
                                        loadSavedSearches={this.loadSavedSearches}
                                        closeSavedSearch={this.props.closeSavedSearch}
                                    />
                                </>
                            ))}
                        </p>
                    </div>
                ) : null}
                {this.props.showTitle && this.state.data.length ? <h3 style={{ marginLeft: 10 }}>Intelligence</h3> : null}
                {this.state.data.map((article) => (
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <SingleSource
                            data={{
                                title: article.title,
                                id: article.id,
                                url: article.original_link,
                                description: article.text,
                                saved_text: article.saved_text,
                                image: {
                                    url: article.image
                                },
                                timestamp: article.timestamp,
                                summary: article.summary,
                                folder: article.folder,
                                folders: article.folders,
                                languageData: article.languageData
                            }}
                            type={'intelligence'}
                            case_id={this.props.case_id}
                            source_id={article.id}
                            additional={this.state.showKeywords ? <Keywords case_id={this.props.case_id} article_id={article.id} /> : null}
                            key={`ss-${article.id}-${this.state.showKeywords}`}
                            buttons={
                                <>
                                    <MapButton
                                        content_id={`${this.props.case_id}-${article.id}`}
                                        Save={(data) => {
                                            return API.SaveGeo(`${this.props.case_id}-${article.id}`, data);
                                        }}
                                    />
                                </>
                            }
                            cancel={this.DeleteIntelligence.bind(this, article.id)}
                            move={() => {
                                this.setState({
                                    moveSourceID: article.id,
                                    showCasePopup: true
                                })
                            }}
                            folders={this.props.folders}
                            onItemFolderSelect={(item) => {
                                API.SetIntelligenceNote(this.props.case_id, article.id, item.id).then(() => {
                                    this.setState({
                                        loaded: false,
                                        data: []
                                    }, () => {
                                        this.loadData();
                                        this.props.onFolderChange && this.props.onFolderChange();
                                    })
                                });
                            }}
                            onSelectedFolderChange={(folders) => {
                                API.SaveContentFolders(this.props.case_id, article.id, folders).then(() => {
                                    this.setState({
                                        loaded: false,
                                        data: []
                                    }, () => {
                                        this.loadData();
                                        this.props.onFolderChange && this.props.onFolderChange();
                                    })
                                })
                            }}
                            createNote={this.props.createNote}
                        />
                        {
                            this.state.showCasePopup ? (
                                <CasePopup
                                    onShow={() => {
                                        this.setState({ showCasePopup: true })
                                    }}
                                    onHide={() => {
                                        this.setState({ showCasePopup: false })
                                    }}
                                    onSelect={(c) => {
                                        this.moveSourceToCase(c);
                                    }}
                                />
                            ) : null
                        }
                    </div>
                ))}
            </>
        )
    }
}

export default IntelligenceSources;