import React from 'react';

import { MenuItem, Dialog, Classes, Button } from '@blueprintjs/core';
import MapPopup from './MapPopup';

import API from '../Utilities/API';

class MapButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            geoData: null,
            loaded: false,
            dataKey: 0,
            comments: []
        }

        this.onClose = this.onClose.bind(this);
        this.Save = this.Save.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    Save() {
        this.setState({
            open: false
        }, () => {
            if (this.props.Save) this.props.Save(this.state.geoData).then(() => {
                this.setState({
                    dataKey: new Date().getTime()
                })
            });
        });
    }

    onClose() {
        this.setState({
            open: false
        })
    }

    componentDidMount() {
        if (!this.state.loaded) this.loadData();
    }

    loadData() {
        API.GetGeo(this.props.content_id).then((response) => {
            this.setState({
                loaded: true,
                geoData: response.data.Data.data || null,
                comments: response.data.Data.comments || [],
                dataKey: new Date().getTime()
            })
        })
    }

    render() {
        return (
            <>
                <MenuItem icon={'path-search'} intent={this.state.geoData ? 'success' : null} style={this.props.style} onClick={() => {
                    this.setState({
                        open: true
                    })
                }} text={'Location'} />
                {this.state.open ? (
                    <Dialog
                        isOpen={true}
                        onClose={this.onClose}
                        autoFocus={true}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        enforceFocus={true}
                        usePortal={true}
                        className={Classes.DARK}
                        style={{ width: 940 }}
                        inheritDarkTheme={true}
                        portalClassName={Classes.DARK}
                    >
                        <div className={Classes.DIALOG_BODY + ' ' + Classes.DARK}>
                            <MapPopup
                                key={`MapPopup-${this.state.dataKey}`}
                                saveLayers={(layers) => {
                                    this.setState({
                                        geoData: layers
                                    })
                                }}
                                geoData={this.state.geoData}
                                comments={this.state.comments}
                            />
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Button onClick={this.Save} loading={this.state.sending} intent={'primary'} icon={'upload'}>Send</Button>
                                <Button onClick={this.onClose}>Close</Button>
                            </div>
                        </div>

                    </Dialog>
                ) : null}
            </>
        )
    }
}

export default MapButton;