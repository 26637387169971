import { useEffect, useState } from 'react';

import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';

export default ({ data, onKeywordClick, selectedKeywords, countries, languages, categories, append }) => {

    const [selectedID, setSelectedID] = useState([]);
    useEffect(() => {
        if (selectedKeywords && selectedKeywords.length) {
            setSelectedID(selectedKeywords.map(k => k.id));
        }
    }, [selectedKeywords])

    const [countryMap, setCountryMap] = useState({});
    const [languageMap, setLanguageMap] = useState({});
    const [categoryMap, setCategoryMap] = useState({});

    useEffect(() => {
        let x = {};
        countries.forEach((country) => {
            x[country.id] = country.name.replace(/&nbsp;/g, '').trim()
        });

        let y = {};
        languages.forEach((language) => {
            y[language.shortcode] = language.name;
        });

        let z = {};
        categories.forEach((category) => {
            z[category.id] = category.name;
        });

        setCountryMap(x);
        setLanguageMap(y);
        setCategoryMap(z);
    }, []);

    return (
        <>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'auto'
                }}
                className={'bp5-html-table bp5-html-table-striped bp5-interactive'}
            >
                {data.filter((kw) => (!selectedID.length || selectedID.indexOf(kw.id) === -1)).map((kw, i) => (
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row'
                    }}
                        className={'tr'}
                    >
                        <div className={'td'} style={{
                            flex: 6,
                            overflow: 'hidden'
                        }}>
                            <Button
                                minimal
                                onClick={() => {
                                    onKeywordClick && onKeywordClick(kw);
                                }}
                                text={kw.title}
                            />
                            <div class="bp5-text-muted">{kw.note}</div>
                        </div>
                        <div className={'td'} style={{
                            flex: 1,
                            overflow: 'hidden'
                        }}>{kw.calculated_type}</div>
                        <div className={'td'} style={{
                            flex: 2,
                            overflow: 'hidden'
                        }}>{countryMap[kw.country] || kw.country}</div>
                        <div className={'td'} style={{
                            flex: 2,
                            overflow: 'hidden'
                        }}>{categoryMap[kw.category] || kw.category}</div>
                        <div className={'td'} style={{
                            flex: 1,
                            textAlign: 'center',
                            overflow: 'hidden'
                        }}>
                            <Popover
                                interactionKind={'hover'}
                                content={
                                    <Menu>
                                        {kw.keywords.map((k) => (
                                            <MenuItem
                                                text={k.keyword}
                                                large
                                            />
                                        ))}
                                    </Menu>
                                }
                            >
                                <Button minimal>{kw.keywords.length}</Button>
                            </Popover>
                        </div>
                    </div>
                ))}
                {append}
            </div>
        </>
    )

}