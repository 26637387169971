import React, { useState, useEffect } from 'react';

import { Button } from '@blueprintjs/core'

import API from '../Utilities/API';


import { useNavigate } from 'react-router-dom';

export default (props) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    return (
        <>
            <Button
                icon={'stop'}
                loading={loading}
                onClick={() => {
                    setLoading(true);
                    if (window.confirm('Finish case?')) {
                        API.CloseCase(props.case_id).then(() => {
                            setTimeout(() => {
                                props.onCloseCase();
                                window.askUnload = false;
                                //window.location.href = '/cases?_=' + new Date().getTime();
                                window.askUnload = true;
                                setLoading(false);
                            }, 1000);
                        });
                    } else {
                        setLoading(false);
                    }
                }}
            >Finish</Button>
        </>
    )

}