import { useEffect, useState } from 'react';

import { Button, Navbar, NavbarDivider, NavbarGroup, NavbarHeading, Popover, Spinner, Tag, Menu, MenuItem } from '@blueprintjs/core';

import LanguageList from '../Language/List';
import Search from '../Navigation/SimpleSearch';
import KeywordTable from './Table';
import TypesList from './TypesList';

import API from '../Utilities/API';

import CategoryList from '../Category/List';
import CountryList from '../Country/List';

export default (props) => {


    const [loading, setLoading] = useState(true);
    const [loadingData, setLoadingData] = useState(false);
    const [open, setOpen] = useState(false);

    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [query, setQuery] = useState('');

    const [countries, setCountries] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [types, setTypes] = useState([]);

    const [loadingKey, setLoadingKey] = useState(0);

    const [openCountries, setOpenCountries] = useState(false);
    const [openLanguages, setOpenLanguages] = useState(false);
    const [openCategories, setOpenCategories] = useState(false);
    const [openTypes, setOpenTypes] = useState(false);

    const [modifier, setModifier] = useState('');
    const [searchIt, setSearchIt] = useState(1);

    const [start, setStart] = useState(0);
    const [showMore, setShowMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    useEffect(() => {
        API.getFilters().then((response) => {
            if (response && response.data) {
                setCountries(response.data.Data.countries);
                setCategories(response.data.Data.categories);
                setLanguages(response.data.Data.language);
                setTypes(response.data.Data.keyword_types);
                setLoadingKey(new Date().getTime());
            }
            setLoading(false);
        })
    }, [])

    const [data, setData] = useState([]);

    const search = () => {
        API.ListKeywords(start, query, selectedCountries, selectedLanguages, selectedTypes, selectedCategories, modifier).then((result) => {
            if (result && result.data && result.data.Data) {
                if (start === 0) {
                    setData(result.data.Data);
                } else {
                    setData([
                        ...data,
                        ...result.data.Data
                    ]);
                }
                if (result.data.Data.length === 100) setShowMore(true);
                else setShowMore(false);
            }
            setLoadingData(false);
            setLoadingMore(false);
        })
    }

    useEffect(() => {
        setStart(0);
        setData([]);
    }, [selectedCountries, selectedLanguages, selectedTypes, selectedCategories, searchIt])

    useEffect(() => {
        setLoadingData(true);
        search();
    }, [selectedCountries, selectedLanguages, selectedTypes, selectedCategories, modifier, searchIt]);

    useEffect(() => {
        setLoadingMore(true);
        search();
    }, [start])

    return (
        <>
            <Navbar style={{ position: 'sticky', top: 0, paddingLeft: 0 }}>
                <NavbarGroup style={{ width: '100%' }}>
                    <NavbarHeading style={{ paddingLeft: 11 }}> Keywords</NavbarHeading>

                    <NavbarDivider />

                    <NavbarGroup style={{ margin: 'auto' }}>
                        <Popover
                            hasBackdrop={true}
                            placement={'bottom'}
                            isOpen={openCountries}
                            backdropProps={{
                                onClick: () => {
                                    setOpenCountries(false)
                                }
                            }}
                            content={(
                                <>
                                    <CountryList
                                        countries={countries}
                                        selected={selectedCountries}
                                        onClick={(sel_country) => {
                                            if (selectedCountries.indexOf(sel_country.id) == -1) {
                                                setSelectedCountries([
                                                    ...selectedCountries,
                                                    ...countries.filter((country) => (
                                                        country.id === sel_country.id || country.parent === sel_country.id
                                                    )).map((country) => (
                                                        country.id
                                                    ))
                                                ])
                                            } else {
                                                setSelectedCountries(selectedCountries.filter((country_id) => (country_id !== sel_country.id)))
                                            }
                                        }}
                                    />
                                </>
                            )}>
                            <Popover
                                content={selectedCountries.length ? <>
                                    <Menu>
                                        {countries.filter((c) => selectedCountries.indexOf(c.id) > - 1).map((c) => (
                                            <MenuItem text={<div dangerouslySetInnerHTML={{ __html: c.name }}></div>} />
                                        ))}
                                    </Menu>
                                </> : null}
                                interactionKind={'hover'}
                                placement={'bottom'}
                            >
                                <Button
                                    loading={loading}
                                    icon={'globe'}
                                    text={<>Country {selectedCountries.length ? <Tag minimal>{selectedCountries.length}</Tag> : null}</>}
                                    onClick={() => {
                                        setOpenCountries(!openCountries)
                                    }}
                                    rightIcon="double-caret-vertical"
                                />
                            </Popover>
                        </Popover>

                        <Popover
                            hasBackdrop={true}
                            isOpen={openLanguages}
                            backdropProps={{
                                onClick: () => {
                                    setOpenLanguages(false)
                                }
                            }}
                            content={(
                                <>
                                    <LanguageList
                                        languages={languages}
                                        selected={selectedLanguages}
                                        onClick={(language) => {
                                            if (selectedLanguages.indexOf(language.shortcode) == -1) {
                                                setSelectedLanguages([
                                                    ...selectedLanguages,
                                                    language.shortcode
                                                ])
                                            } else {
                                                setSelectedLanguages(selectedLanguages.filter((language_id) => (language_id !== language.shortcode)))
                                            }
                                        }}
                                    />
                                </>
                            )}>
                            <Popover
                                content={selectedLanguages.length ? <>
                                    <Menu>
                                        {languages.filter((c) => selectedLanguages.indexOf(c.shortcode) > - 1).map((c) => (
                                            <MenuItem text={c.name} />
                                        ))}
                                    </Menu>
                                </> : null}
                                interactionKind={'hover'}
                                placement={'bottom'}
                            >
                                <Button
                                    loading={loading}
                                    icon={'translate'}
                                    text={<>Language {selectedLanguages.length ? <Tag minimal>{selectedLanguages.length}</Tag> : null}</>}
                                    onClick={() => {
                                        setOpenLanguages(!openLanguages)
                                    }}
                                    rightIcon="double-caret-vertical"
                                />
                            </Popover>
                        </Popover>

                        <Popover
                            hasBackdrop={true}
                            isOpen={openTypes}
                            backdropProps={{
                                onClick: () => {
                                    setOpenTypes(false)
                                }
                            }}
                            content={(
                                <>
                                    <TypesList
                                        types={types}
                                        selected={selectedTypes}
                                        onClick={(t) => {
                                            if (selectedTypes.indexOf(t) == -1) {
                                                setSelectedTypes([
                                                    ...selectedTypes,
                                                    t
                                                ])
                                            } else {
                                                setSelectedTypes(selectedTypes.filter((st) => (st !== t)))
                                            }
                                        }}
                                    />
                                </>
                            )}>
                            <Popover
                                content={selectedTypes.length ? <>
                                    <Menu>
                                        {types.filter((c) => selectedTypes.indexOf(c) > - 1).map((c) => (
                                            <MenuItem text={c} />
                                        ))}
                                    </Menu>
                                </> : null}
                                interactionKind={'hover'}
                                placement={'bottom'}
                            >
                                <Button
                                    loading={loading}
                                    icon={'translate'}
                                    text={<>Type {selectedTypes.length ? <Tag minimal>{selectedTypes.length}</Tag> : null}</>}
                                    onClick={() => {
                                        setOpenTypes(!openTypes)
                                    }}
                                    rightIcon="double-caret-vertical"
                                />
                            </Popover>
                        </Popover>


                        <Popover
                            hasBackdrop={true}
                            placement={'bottom'}
                            isOpen={openCategories}
                            backdropProps={{
                                onClick: () => {
                                    setOpenCategories(false)
                                }
                            }}
                            content={(
                                <>
                                    <CategoryList
                                        categories={categories}
                                        selected={selectedCategories}
                                        onClick={(sel_category) => {
                                            if (selectedCategories.indexOf(sel_category.id) == -1) {
                                                setSelectedCategories([
                                                    ...selectedCategories,
                                                    ...categories.filter((category) => (
                                                        category.id === sel_category.id
                                                    )).map((category) => (
                                                        category.id
                                                    ))
                                                ])
                                            } else {
                                                setSelectedCategories(selectedCategories.filter((category_id) => (category_id !== sel_category.id)))
                                            }
                                        }}
                                    />
                                </>
                            )}>
                            <Popover
                                content={selectedCategories.length ? <>
                                    <Menu>
                                        {categories.filter((c) => selectedCategories.indexOf(c.id) > - 1).map((c) => (
                                            <MenuItem text={c.name} />
                                        ))}
                                    </Menu>
                                </> : null}
                                interactionKind={'hover'}
                                placement={'bottom'}
                            >
                                <Button
                                    loading={loading}
                                    icon={'list-columns'}
                                    text={<>Category {selectedCategories.length ? <Tag minimal>{selectedCategories.length}</Tag> : null}</>}
                                    onClick={() => {
                                        setOpenCategories(!openCategories)
                                    }}
                                    rightIcon="double-caret-vertical"
                                />
                            </Popover>
                        </Popover>

                        <Search
                            style={{
                                width: 500
                            }}
                            onSearch={(q) => {
                                setModifier('');
                                setQuery(q);
                                setData([]);
                                setSearchIt(searchIt + 1);
                            }}
                            onChange={(q) => {
                                setQuery(q);
                            }}
                        />
                        <Popover
                            content={<>
                                <Menu>
                                    <MenuItem text={'Search Title'} onClick={() => setModifier('searchTitle=Title')} />
                                    <MenuItem text={'Search Keywords'} onClick={() => setModifier('searchWords=Keywords')} />
                                    <MenuItem text={'Search Description'} onClick={() => setModifier('searchDescription=Description')} />
                                </Menu>
                            </>}>
                            <Button rightIcon={'caret-down'} />
                        </Popover>
                    </NavbarGroup>
                    {props.onClose && (
                        <NavbarGroup style={{ marginLeft: 'auto' }}>
                            <Button icon={'cross'} minimal onClick={() => {
                                props.onClose && props.onClose();
                            }} />
                        </NavbarGroup>
                    )}
                </NavbarGroup>
            </Navbar >
            <Navbar className={'noPadding'} style={{ position: 'sticky', top: 0 }}>
                <NavbarGroup style={{ width: '100%', padding: 0 }}>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row'
                    }}>
                        <div style={{
                            flex: 6,
                            overflow: 'hidden',
                            padding: 11
                        }}>Title</div>
                        <div style={{
                            flex: 1,
                            overflow: 'hidden',
                            padding: 11
                        }}>Type</div>
                        <div style={{
                            flex: 2,
                            overflow: 'hidden',
                            padding: 11
                        }}>Country</div>
                        <div style={{
                            flex: 2,
                            overflow: 'hidden',
                            padding: 11
                        }}>Category</div>
                        <div style={{
                            flex: 1,
                            overflow: 'hidden',
                            padding: 11,
                            textAlign: 'center'
                        }}>Keywords</div>
                        <div style={{
                            width: 20,
                            padding: 11
                        }}> </div>
                    </div>
                </NavbarGroup>
            </Navbar>
            {
                loadingData ?
                    (
                        <Spinner style={{ marginTop: 50 }} />
                    ) : (
                        <>
                            <KeywordTable
                                countries={countries}
                                languages={languages}
                                categories={categories}
                                data={data}
                                onKeywordClick={(kw) => {
                                    props.onSelectKeyword && props.onSelectKeyword(kw);
                                }}
                                selectedKeywords={props.selectedKeywords}
                                append={<>
                                    {showMore ? (
                                        <Button large loading={loadingMore} fill onClick={() => {
                                            setStart(start + data.length);
                                        }}>Load more</Button>
                                    ) : null}
                                </>}
                            />

                        </>
                    )
            }

        </>
    )

}