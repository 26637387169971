import React, { useState, useEffect } from 'react';

import { Dialog, Classes, ControlGroup, InputGroup, FormGroup, Button, MenuItem } from '@blueprintjs/core'
import { Select } from "@blueprintjs/select";

import API from '../Utilities/API';

export default (props) => {

    const TypeSelect = Select.ofType();

    const [load, setLoad] = useState(0);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(props.search && props.search.title ? props.search.title : '');
    const [folder, setFolder] = useState(props.search && props.search.folder && props.search.folder.id ? {
        id: props.search.folder.id,
        title: props.search.folder.title
    } : {
        id: '0',
        title: 'Uncategorized'
    });

    const [folders, setFolders] = useState([]);
    useEffect(() => {
        API.SavedSearches().then((result) => {
            if (result && result.data && result.data.Data) {
                setFolders(result.data.Data.filter((entry) => (entry.type === 'folder')));
            }
        })
    }, [load]);

    const filter_do = (query, data, i, exact) => {
        let title = '';
        if (data && data.name) title = data.name.toLowerCase();
        if (data && data.title) title = data.title.toLowerCase();
        query = query.toLowerCase();
        if (exact) return title === query;
        else return title.length == 0 || title.indexOf(query) > -1;
    }

    const newFolder = () => {
        const title = window.prompt('New folder title?', '');
        if (title) {
            API.NewSearchFolder(title).then((response) => {
                if (response && response.data && response.data.Data && response.data.Data.id) {
                    setFolder({
                        id: response.data.Data.id,
                        title
                    });
                    setLoad(new Date().getTime());
                }
            })
        }
    }

    const DeleteSearch = () => {
        if (window.confirm('Delete the saved search?')) {
            API.DeleteIntelligenceSearch(props.search.id).then(() => {
                window.askUnload = false;
                window.location.href = '/intelligence';
            })
        }
    }

    if (props.search && props.search.user_id && props.search.user_id != window.nd.User.id) return null;

    return (
        <>
            <Dialog
                isOpen={true}
                onClose={() => {
                    props.onClose();
                }}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                enforceFocus={true}
                usePortal={true}
                className={Classes.DARK}
            >
                <div className={Classes.DIALOG_BODY}>
                    <p>
                        <ControlGroup>
                            <FormGroup label={'Search title'} style={{ width: '100%' }}>
                                <InputGroup
                                    value={title}
                                    onChange={(v) => {
                                        setTitle(v.target.value);
                                    }}
                                    placeholder={'Search title'}
                                />
                            </FormGroup>
                        </ControlGroup>
                    </p>
                    <p>
                        <FormGroup label={'Folder'} style={{ width: '100%' }}>
                            <ControlGroup>
                                <TypeSelect
                                    fill
                                    items={folders.flatMap((folder) => {
                                        let r = [];
                                        r.push(folder);
                                        if (folder.children) {
                                            folder.children.forEach((child) => {
                                                r.push({
                                                    ...child,
                                                    title: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- ' + child.title
                                                });
                                            });
                                        }
                                        return r;
                                    })}
                                    itemPredicate={filter_do}
                                    itemRenderer={(c, { handleClick }) => {
                                        return <MenuItem
                                            text={<div dangerouslySetInnerHTML={{ __html: c ? c.title : '' }}></div>}
                                            onClick={handleClick}
                                            style={props.style}
                                        />
                                    }}
                                    onItemSelect={(item) => {
                                        setFolder(item);
                                    }}
                                    menuProps={{ className: 'limit-popup' }}
                                >
                                    <Button style={props.style} fill>
                                        <span dangerouslySetInnerHTML={{ __html: folder.title }} />
                                    </Button>
                                </TypeSelect>
                                <Button icon={'plus'} onClick={newFolder}></Button>
                            </ControlGroup>
                        </FormGroup>
                    </p>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{
                                grow: 1
                            }}>
                                {props.search && props.search.id ? (
                                    <Button intent={'danger'} icon={'trash'} style={{ marginLeft: 0 }} onClick={DeleteSearch}>Delete search</Button>
                                ) : null}
                            </div>
                            <div style={{
                                grow: 1,
                                display: 'flex',
                                flexDirection: 'row-reverse'
                            }}>
                                <Button onClick={() => {
                                    props.onClose();
                                }}>Close</Button>

                                <Button
                                    loading={loading}
                                    intent={'primary'}
                                    icon={'tick'}
                                    onClick={() => {
                                        setLoading(true);
                                        props.onSave && props.onSave(title, folder.id);
                                    }}
                                >
                                    Save
                                </Button>

                            </div>
                        </div>

                    </div>
                </div>

            </Dialog>
        </>
    )

}