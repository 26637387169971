import React, { useState, useEffect } from 'react';

import { Text } from '@blueprintjs/core'

export default (props) => {

    const [showImage, setShowImage] = useState(true);

    const file = props.note.fileData;
    const image = file.meta.thumbnails ? file.meta.thumbnails['300'] : file.location;

    return (
        <div style={{ textAlign: 'center' }}>
            <a href={`https://static.pub.cesno.org${props.note.fileData.short_location}?_`} target={'_blank'}>
                {showImage ? (
                    <>
                        <img src={image} style={{ maxWidth: 200 }} onError={() => {
                            setShowImage(false)
                        }} />
                        <br />
                    </>
                ) : null}
                <div style={{ maxWidth: 250, margin: 'auto' }}>
                    <Text ellipsize={true}>{props.note.fileData.original_name}</Text>
                </div>
            </a>
        </div>
    )

}