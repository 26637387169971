import React, { useState } from 'react';

import { Dialog, Button, Tag, Classes, Menu, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2';

import API from '../Utilities/API';
import NewLink from './NewLink';
import LinkList from './List';
import LinkViews from './Views';

export default (props) => {

    const [loaded, setLoaded] = useState(false);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [doing, setDoing] = useState(false);
    const [newDialog, showNew] = useState(false);
    const [showViews, setShowViews] = useState(false);
    const [link_id, setLinkID] = useState('');

    const loadData = () => {
        API.Links(props.content_id).then((response) => {
            setLoaded(true);
            setData(response.data.Data || []);
            setDoing(false);
        })
    }

    const Close = () => {
        setOpen(false);
        showNew(false);
        setShowViews(false);
    }

    const doShowViews = (id) => {
        setLinkID(id);
        setShowViews(true);
    }

    const hideViews = () => {
        setLinkID('');
        setShowViews(false);
    }

    const RenderList = () => {
        if (!data || !data.length) return null;
        else {
            return <Menu>
                {data.map((e) => (
                    <MenuItem onClick={() => { setOpen(true) }} icon={'link'} text={e.title} />
                ))}
            </Menu>
        }
    }

    if (!loaded) loadData();

    return (!data || data.length === 0) && props.hideEmpty ? null : (
        <>
            <Popover2 position={'top'} content={RenderList()} interactionKind={'hover'}>
                <Button minimal={props.minimal} loading={!loaded} icon={'link'} onClick={() => {
                    setOpen(true);
                }}>
                    {props.hideText ? null : 'Links'}
                    {data.length === 0 ? null : (
                        <>
                            &nbsp;
                            <Tag style={{ float: 'right' }}>{data.length}</Tag>
                        </>
                    )}
                </Button>
            </Popover2>
            {loaded && open ? (
                newDialog ? (
                    <NewLink
                        onClose={() => {
                            showNew(false);
                        }}
                        showNew={showNew}
                        loadData={loadData}
                        setDoing={setDoing}
                        content_id={props.content_id}
                    />
                ) : (
                    <Dialog
                        isOpen
                        onClose={() => {
                            Close();
                        }}
                        autoFocus={true}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        enforceFocus={true}
                        usePortal={true}
                        className={Classes.DARK}
                        style={{ width: showViews && link_id.length ? 1200 : 650 }}
                    >
                        <div className={Classes.DIALOG_BODY} style={{ maxHeight: 800, overflowY: 'auto' }}>
                            {showViews && link_id.length ? (
                                <LinkViews
                                    key={`views-${link_id}`}
                                    link_id={link_id}
                                />
                            ) : (
                                <LinkList
                                    data={data}
                                    loadData={loadData}
                                    showViews={doShowViews}
                                />
                            )
                            }
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Button loading={doing} intent={'primary'} icon={'plus'} onClick={() => {
                                    setDoing(true);
                                    showNew(true);
                                    /*  */
                                }}>New link</Button>
                                <Button onClick={() => {
                                    showViews && link_id.length ? hideViews() : Close();
                                }}>Close</Button>
                            </div>
                        </div>
                    </Dialog>
                )) : null}
        </>
    )

}