import React from 'react';

import { MenuItem, ProgressBar, ControlGroup, Button } from '@blueprintjs/core'
import { Suggest } from '@blueprintjs/select';

import API from '../Utilities/API'

const LocationSearch = Suggest.ofType();

class MapSearch extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            lastq: '',
            query: '',
            loading: false,
            lastUpdate: 0
        }

        this.renderItem = this.renderItem.bind(this);
        this.TypeAhead = this.TypeAhead.bind(this);
    }

    renderItem(item, { handleClick }) {
        if (item.loading) {
            return <MenuItem
                text={<ProgressBar />}
                shouldDismissPopover={false}
                style={{
                    width: 450
                }}
            />
        }
        return (
            <MenuItem
                icon={null}
                key={item.place_id}
                label={item.type}
                text={item.display_name}
                shouldDismissPopover
                onClick={handleClick}
                style={{
                    width: 550
                }}
            />
        );
    }

    TypeAhead(q) {
        if (this.state.loading === true) return false;
        this.setState({
            loading: true
        }, () => {
            API.MapSearch(q).then((response) => {
                let ns = {
                    items: response.data,
                    loading: false,
                    query: ''
                };
                let doSearch = false;
                if (this.state.query && this.state.query.length > 2 && response.data.length) {
                    ns.selected = [response.data[0]];
                    doSearch = true;
                }
                this.setState(ns);
            });
        })
    }

    render() {
        return (
            <>
                <ControlGroup style={{width: '100%'}}>
                    <LocationSearch
                        items={this.state.items}
                        itemRenderer={this.renderItem}
                        fill
                        hasInitialContent={false}
                        resetOnSelect
                        tagMinimal
                        matchTargetWidth
                        key={'MapSearch-' + this.state.lastUpdate}
                        onQueryChange={(q) => {
                            if (q.length > 2) {
                                this.setState({
                                    items: [{ loading: true }],
                                    lastq: q,
                                    query: ''
                                }, () => {
                                    clearTimeout(this.timer);
                                    this.timer = setTimeout(() => {
                                        this.TypeAhead(q);
                                    }, 1000);
                                })
                            } else {
                                this.setState({
                                    items: [],
                                    lastq: q,
                                    query: ''
                                })
                            }
                        }}
                        onItemSelect={(item) => {
                            if (this.props.itemSelect) this.props.itemSelect(item);
                        }}
                        inputValueRenderer={(item) => (item.display_name)}
                    />
                    { this.state.items.length || this.props.showClear > 0 ? (
                    <Button icon={'cross'} intent={'danger'} onClick={() => {
                        this.setState({
                            items: [],
                            loading: false,
                            lastUpdate: new Date().getTime()
                        }, () => {
                            if (this.props.clearResults) this.props.clearResults();
                        })
                    }} />
                    ) : null }
                </ControlGroup>
            </>
        )
    }
}

export default MapSearch;