import React, { useState, useEffect } from 'react';

import { Dialog, Button, Tag, Classes, Icon } from '@blueprintjs/core'

import CasesList from './CasesList';

export default (props) => {

    const [showDialog, setShowDialog] = useState(true);

    useEffect(() => {
        if (!showDialog) {
            if (props.onHide) props.onHide();
        } else {
            if (props.onShow) props.onShow();
        }
    }, [showDialog])

    const selectCase = (c) => {
        if (window.confirm(`Move source to "${c.title}"?`)) {
            setShowDialog(false);
            if (props.onSelect) props.onSelect(c);
        }
    }

    return (
        <>
            <Dialog
                isOpen={showDialog}
                onClose={() => {
                    setShowDialog(false);
                }}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                enforceFocus={true}
                usePortal={true}
                className={Classes.DARK}
                style={{ width: 1640, paddingBottom: 0 }}
                title={'Select a case to move to'}
            >
                <div className={Classes.DIALOG_BODY} style={{ height: 700, width: '100%', overflowY: 'auto', margin: 0 }}>
                    <CasesList
                        toggleExpanded={() => { }}
                        isExpanded
                        setExpanded={() => { }}
                        setCollapsed={() => { }}
                        setActiveCase={selectCase}
                        activeCase={null}
                        closed={false}
                        shared={true}
                        setClosed={() => { }}
                        routeCase={null}
                        noClick={true}
                        hideTopBar
                    />
                </div>
            </Dialog>
        </>
    )

}