import React, { useState, useEffect } from 'react';
import { Button } from '@blueprintjs/core'
import { Tooltip2, Popover2 } from "@blueprintjs/popover2";
import API from '../Utilities/API';
import SavedSearchMenu from './Menu';

export default (props) => {

    const [searches, setSearches] = useState([]);

    const loadSearches = () => {
        API.SavedSearches().then((result) => {
            result && result.data && result.data.Data && setSearches(result.data.Data);
            setTimeout(() => {
                loadSearches();
            }, 45 * 1000);
        })
    }

    useEffect(() => {
        loadSearches();
    }, [])

    return (

        <Popover2
            content={
                <SavedSearchMenu
                    searches={searches}
                    showIntelligence={props.showIntelligence}
                    onClick={props.onClick}
                    onAddToCase={props.onAddToCase}
                />
            }
            placement={props.placement || 'right-start'}
        >
            <Button
                active={window.location.href.indexOf('/savedsearch/') > -1}
                icon={'inbox-search'}
                large={props.large || null}
                style={props.style || { marginLeft: 4, marginTop: 5 }}
            />
        </Popover2>

    )

}