import React, { useState, useEffect } from 'react';

import { Card, Button } from '@blueprintjs/core';

import API from '../Utilities/API'
import U from '../Utilities/Utilities';

import NoteText from './NoteText';
import NoteTools from './NoteTools';

import OG from '../OG/Single'

import ShareButton from '../Share/Button';
import LinkButton from '../Links/Button';

import { Popover2 } from '@blueprintjs/popover2'

import FileNote from './FileNote'
import Comments from '../Comments/Button';

export default (props) => {

    const [note, setNote] = useState(props.note);
    const [deleted, setDeleted] = useState(false);
    const [editing, setEditing] = useState(false);
    const [renderTime, setRenderTime] = useState(0);

    return deleted ? null : (
        <>
            <Card style={{ width: 'fit-content', marginBottom: 10, minWidth: '20%', maxWidth: '60%', marginLeft: 'auto', paddingTop: 0 }}>

                <div style={{ width: '100%' }}>
                    <h5>
                        {U.prettyStampFull(note.datetime)}
                        <div style={{ float: 'right' }}>
                            <ShareButton hideEmpty text={false} minimal content_id={`Note:${note.id}`} />
                            <LinkButton hideEmpty hideText minimal content_id={`Note:${note.id}`} />
                            <Popover2
                                content={
                                    <NoteTools
                                        note={note}
                                        deleteNote={() => {
                                            API.DeleteNote(note.id);
                                            setDeleted(true);
                                        }}
                                        editNote={() => {
                                            setEditing(true);
                                            setRenderTime(renderTime + 1);
                                        }}
                                        onShare={() => {
                                            if (props.reloadNotes) props.reloadNotes();
                                        }}
                                    />
                                }
                                minimal
                                autoFocus={false}
                                enforceFocus={false}
                            >
                                <Button minimal icon={'more'} />
                            </Popover2>
                        </div>
                    </h5>
                    {note.file_id && note.file_id.length ? (
                        <FileNote note={note} />
                    ) : (
                        <NoteText note={note} key={note.id + '-' + renderTime} editing={editing} setEditing={(x, note) => {
                            setEditing(x);
                            setRenderTime(renderTime + 1);
                            setNote(note);
                        }} />
                    )}
                </div>

                {note.link && note.link.length ? (
                    <OG url={note.link} />
                ) : null}

                <div style={{ marginTop: 20 }}>
                    <Comments content_id={`Note:${note.id}`} commentCount={note.commentCount} onSend={() => {
                        if (props.reloadNotes) props.reloadNotes();
                    }} />
                </div>
            </Card>
        </>
    )

}