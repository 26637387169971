import React from 'react';

import { Button, MenuItem, MenuDivider, ControlGroup } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import API from '../Utilities/API';
import NewCase from './NewCase';

const CaseSelect = Select.ofType();

class SelectAdd extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            loading: false,
            cases: [],
            lastUpdate: 0,
            selectedCase: this.props.selectedCase && this.props.selectedCase.title ? this.props.selectedCase : {
                title: 'Select case'
            }
        }
        this.loadData = this.loadData.bind(this);
        this.selectCase = this.selectCase.bind(this);
        this.renderCase = this.renderCase.bind(this);
        this.caseSaved = this.caseSaved.bind(this);
    }

    componentDidMount() {
        if (!this.state.loaded) this.loadData();
    }

    loadData() {
        this.setState({
            loaded: false
        }, () => {
            API.Cases(false, null, false, true).then((r) => {
                this.setState({
                    cases: r.data.Data,
                    loaded: true
                })
            })
        });
    }

    filter(query, data, i, exact) {
        const title = data.title.toLowerCase();
        query = query.toLowerCase();
        if (exact) return title === query;
        else return title.indexOf(query) > -1;
    }

    renderCase(c, other) {
        let isInSelected = this.props.selectedCases && this.props.selectedCases.length && this.props.selectedCases.filter((x) => (x.id == c.id)).length > 0;
        return <>
            <MenuItem
                text={<div dangerouslySetInnerHTML={{ __html: c.title }}></div>}
                shouldDismissPopover={false}
                key={`SelectAdd-${c.id}`}
                label={(
                    <>
                        {this.props.removeFromCase && isInSelected ? <Button style={{ marginRight: 10 }} small icon={'cross'} onClick={() => { this.props.removeFromCase([...this.props.articles, this.props.group_id], c.id) }}></Button> : null}
                        <Button small icon={'add'} onClick={() => { this.selectCase(c) }}></Button>
                    </>

                )}
            />
            {
                this.props.selectedCases && this.props.selectedCases.length > 0 && this.props.selectedCases.map((sc) => (sc.id)).filter((value, index, self) => (self.indexOf(value) === index)).length == other.index + 1 ? <MenuDivider key={`divider-${other.index}`} /> : null
            }
        </>
    }

    selectCase(c) {
        this.setState({
            selectedCase: {
                title: c.title || 'Select case'
            },
            lastUpdate: new Date().getTime()
        }, () => {
            if (window.nd.cases) delete window.nd.cases[this.props.group_id];
            if (this.props.articles && this.props.articles.length) {
                API.SetCase(this.props.articles, c).then(() => {
                    API.SetCase([this.props.group_id], c).then(() => {
                        if (this.props.onSelect) this.props.onSelect(c);
                    });
                });
            }
        })
    }

    caseSaved(cs) {
        this.selectCase(cs);
    }

    render() {

        let title = this.state.selectedCase.title || 'Select case';
        let items = this.state.cases;
        let uniqueIDS = this.props.selectedCases ? this.props.selectedCases.map((sc) => (sc.id)).filter((value, index, self) => (self.indexOf(value) === index)) : [];
        items.sort((a, b) => {
            let aS = this.props.selectedCases && this.props.selectedCases.length > 0 ? this.props.selectedCases.filter((x) => (x.id == a.id)).length > 0 : false
            let bS = this.props.selectedCases && this.props.selectedCases.length > 0 ? this.props.selectedCases.filter((x) => (x.id == b.id)).length > 0 : false

            if (aS && !bS) return -1;
            else if (!aS && bS) return 1;
            else return a.title.localeCompare(b.title);
        })
        return (
            <>
                {this.props.forceCase && this.props.selectedCase ? (
                    <Button loading={this.state.loading} disabled={this.state.loading || !this.props.articles || !this.props.articles.length} intent={'success'} icon={'plus'} text={'Add to ' + this.props.selectedCase.title} onClick={() => {
                        this.setState({
                            loading: true
                        })
                        if (window.nd.cases) delete window.nd.cases[this.props.group_id];
                        if (this.props.articles && this.props.articles.length) {
                            API.SetCase(this.props.articles, this.props.selectedCase).then(() => {
                                API.SetCase([this.props.group_id], this.props.selectedCase).then(() => {
                                    this.setState({
                                        loading: false
                                    })
                                    if (this.props.onSelect) this.props.onSelect(this.props.selectedCase);
                                });
                            });
                        } else {
                            this.setState({
                                loading: false
                            })
                        }
                    }} />
                ) : (
                    <ControlGroup key={this.state.lastUpdate}>
                        <CaseSelect
                            items={items}
                            key={`CaseSelect`}
                            itemPredicate={this.filter}
                            itemRenderer={this.renderCase}
                            menuProps={{ className: 'limit-popup' }}
                        >
                            <Button key={`SelectAdd`} loading={!this.state.loaded} text={title.replace(/\&nbsp;/g, '').trim() + (this.props.selectedCases && this.props.selectedCases.length > 1 ? ` (${uniqueIDS.length})` : '')} rightIcon="double-caret-vertical" disabled={this.props.articles.length === 0} />
                        </CaseSelect>
                        {this.state.loaded ? (
                            <NewCase
                                key={`NewCase`}
                                loadCases={this.loadData}
                                small
                                disabled={this.props.articles.length === 0}
                                onSave={this.caseSaved}
                            />
                        ) : null}
                    </ControlGroup>
                )}
            </>
        )
    }
}

export default SelectAdd;