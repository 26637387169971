import React from 'react';

import ReactCountryFlag from "react-country-flag"

import useAxios from 'axios-hooks'

export default (props) => {

    const ip = props.ip;

    if (ip.length === 0) return null;

    const [{ data, loading, error }, refetch] = useAxios(
        `https://ipapi.co/${ip}/json`
    )

    return !loading && data ? (
        <>
            {props.hideIP ? null : (
                <>
                    {ip}
                    <br />
                </>
            )}
            {data.city}, {data.country_name}
        </>
    ) : ip;

}