import React, { useState, useEffect } from 'react';

import { Button } from '@blueprintjs/core'

import API from '../Utilities/API';


import { useNavigate } from 'react-router-dom';

export default (props) => {

    const navigate = useNavigate();

    return (
        <>
            <Button
                icon={'archive'}
                onClick={() => {
                    if (window.confirm('Archive case?')) {
                        API.ArchiveCase(props.case_id).then(() => {
                            props.onArchiveCase && props.onArchiveCase();
                            window.askUnload = false;
                            window.location.href = '/cases';
                            window.askUnload = true;
                        });
                    }
                }}
            />
        </>
    )

}