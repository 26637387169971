import React, { useState, useEffect } from 'react';

import { Button } from '@blueprintjs/core'
import { Popover2 } from "@blueprintjs/popover2";
import { DateRange, DateRangePicker } from "@blueprintjs/datetime";
import Moment from 'react-moment';

const Calendar = (props) => {

    return (
        <DateRangePicker
            {...props}
        />
    )

}

export default (props) => {

    const [range, setRange] = useState(props.range || [null, null]);
    const [rangeText, setRangeText] = useState(<>Click here to select a date range</>);

    const [calendarVisible, setCalendarVisible] = useState(false);

    useEffect(() => {
        if (range[0] == null && range[1] == null) setRangeText(<>Latest news</>);
        else setRangeText(<>
            <Moment format={'DD.MM.YYYY.'} local>{range[0]}</Moment> - <Moment format={'DD.MM.YYYY.'} local>{range[1]}</Moment>
        </>);

        if (props.onChange) props.onChange(range);

    }, [range])

    return (
        <>
            <Popover2
                isOpen={calendarVisible}
                className={'bp5-dark'}
                content={
                    <>
                        <Calendar
                            className={'bp5-dark'}
                            allowSingleDayRange
                            contiguousCalendarMonths={false}
                            highlightCurrentDay
                            shortcuts={false}
                            defaultValue={range}
                            label={'Pick your date range'}
                            local
                            onChange={(selected) => {
                                if (selected[0] !== null && selected[1] !== null) {
                                    setRange(selected);
                                    setCalendarVisible(false);
                                }
                            }}
                        />
                        {range[0] !== null & range[1] !== null ? (
                            <div style={{ padding: 10, textAlign: 'right' }}>
                                <Button text={'Latest news'} icon={'refresh'} onClick={() => {
                                    setRange([null, null]);
                                    setCalendarVisible(false);
                                }} />
                            </div>
                        ) : null}
                    </>
                }
                position={'bottom-left'}
            >
                <Button text={rangeText} minimal={props.minimal || null} icon={'calendar'} onClick={() => {
                    setCalendarVisible(!calendarVisible);
                }} />
            </Popover2>
        </>
    )

}