import React, { Component } from 'react';

import API from '../Utilities/API';
import U from '../Utilities/Utilities';

import { Card, Spinner } from '@blueprintjs/core';

import WikiEditButton from './EditButton'

import { SocialIcon } from 'react-social-icons';

import Linkify from 'react-linkify';


class WikiCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            data: {
                id: '',
                title: '',
                image: '',
                description: ''
            }
        }

        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        if (!this.state.loaded && this.props.id) {
            this.loadData();
        }
    }

    loadData() {
        API.WikiID(this.props.id).then((response) => {
            this.setState({
                loaded: true,
                data: response.data.Data
            })
        })
    }

    render() {

        const links = [
            'facebook',
            'twitter',
            'email',
            'instagram',
            'site',
            'telegram',
            'skype',
            'linkedin',
            'wikipedia'
        ];

        return this.state.loaded ? (
            <Card style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                <div style={{ display: 'flex', flexDirection: 'horizontal' }}>
                    {this.state.data.image && this.state.data.image.length ? (
                        <div style={{ display: 'flex', flex: 1 }}>
                            <div style={{ display: 'block', width: '100%', padding: 10, paddingTop: 17 }}>
                                <img src={this.state.data.image} style={{ width: '100%' }} />
                            </div>
                        </div>
                    ) : null}
                    <div style={{ display: 'flex', flex: 3 }}>
                        <div style={{ display: 'block' }}>
                            <h2 style={{ marginTop: 10, marginBottom: 5 }}>{this.state.data.title}</h2>
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" href={decoratedHref} key={key}>
                                    {decodeURI(decoratedText)}
                                </a>)}>
                                {this.state.data.description}
                            </Linkify>
                        </div>
                    </div>
                </div>
                <div style={{ paddingTop: 20, textAlign: 'right' }}>
                    <div style={{ float: 'left' }}>
                        <WikiEditButton wiki={this.state.data} />
                    </div>
                    {links.map((network) => (
                        this.state.data[network] && this.state.data[network].length
                            ?
                            (network === 'wikipedia' ? (
                                <a target="_blank" href={this.state.data[network]} class="social-icon" style={{ "display": "inline-block", "width": "25px", "height": "25px", "position": "relative", "overflow": "hidden", "vertical-align": "middle", "margin-right": "10px" }}>
                                    <div class="social-container" style={{ "position": "absolute", "top": "0px", "left": "0px", "width": "100%", "height": "100%" }}>
                                        <img src={'/wikipedia.svg'} style={{ height: '100%', width: '100%' }} />
                                    </div>
                                </a>
                            ) : (
                                <SocialIcon target={'_blank'} url={this.state.data[network]} style={{ height: 25, width: 25, marginRight: 10 }} />
                            )) : null

                    ))}
                </div>
            </Card>
        ) : (this.props.id ? <Spinner /> : null);
    }
}

export default WikiCard;