import React, { useState, useEffect } from 'react';
import { Button, Tag, Card } from '@blueprintjs/core'

import U from '../Utilities/Utilities';
import API from '../Utilities/API';

import NotePreview from '../ContentNotes/Preview';
import IntelligencePreview from '../Intelligence/Preview'
import SingleCase from '../Cases/SingleCase'

const Preview = ({ type, data }) => {

    if (type == 'AddContentNote' || type == 'EditContentNote' || type == 'DeleteContentNote' || type == 'Publish' || type == 'AssignmentFromNote') {
        if (data.before && data.after) {
            return (
                <>
                    <h3>Before</h3>
                    <NotePreview note={data.before} />
                    <h3>After</h3>
                    <NotePreview note={data.after} />
                </>
            )
        } else {
            return data.id && <NotePreview note={data} />
        }
    }
    else if (type == 'SetCase' && data.length > 0) {
        return (
            <>
                {data.map((id) => {
                    let x = id.split(':');

                    if (x.length == 1) x = x[0];
                    else x = x[1];

                    return <IntelligencePreview load_id={`Intelligence:${x}`} />
                })}
            </>
        )
    }
    else if (type == 'AddSourceToCase') {
        return <Card><a href={data.url} target={'_blank'}>{data.url}</a></Card>
    }
    else if (type == 'NewCase' || type == 'EditCase') {
        if (data.before && data.after) {
            return (
                <>
                    <h3>Before</h3>
                    <Card><SingleCase case={data.before} simple /></Card>
                    <h3>After</h3>
                    <Card><SingleCase case={data.after} simple /></Card>
                </>
            )
        } else {
            return data.countryData && <Card><SingleCase case={data} simple /></Card>
        }
    }
}

const SingleEntry = ({ entry }) => {

    const [preview, setPreview] = useState(false);

    let data = entry.content_id;
    try {
        data = JSON.parse(entry.content_id)
    } catch (e) {
        //
    }

    return (
        <>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    paddingTop: 5,
                    paddingBottom: 5,
                    gap: 5,
                    cursor: 'pointer'
                }}
                onClick={() => {
                    setPreview(!preview);
                }}
            >
                <div style={{
                    flex: 1,
                    flexGrow: 0,
                    flexShrink: 0
                }}>
                    <Tag minimal intent={'primary'}>{U.prettyStamp(entry.datetime)}</Tag>
                </div>
                <div style={{
                    flex: 1,
                    flexGrow: 1
                }}>
                    {entry.text}
                </div>
                <div style={{
                    flex: 1,
                    flexGrow: 0,
                    flexShrink: 0
                }}>
                    <Tag minimal style={{
                        marginLeft: 'auto'
                    }}>{entry.user_name}</Tag>
                </div>
            </div>
            {preview ? (
                <div style={{
                    borderBottom: '1px solid #30404d',
                    marginBottom: 20
                }}>
                    <Preview type={entry.action} data={data} />
                </div>
            ) : null}
        </>
    )

}

export default function ({ case_id }) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(true);
        API.CaseHistory(case_id).then((response) => {
            setData(response.data?.Data ?? []);
            setLoading(false);
        });

    }, []);

    let date = '';

    return loading ? null : (
        <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            {data.map((entry) => {
                const entry_date = entry.datetime.substring(0, 10);
                let showDate = false;
                if (date != entry_date) {
                    date = entry_date;
                    showDate = true;
                } else {
                    showDate = false;
                }

                return (
                    <>
                        {showDate && (
                            <Button
                                fill
                                minimal
                                disabled
                                large
                                icon={'calendar'}
                                style={{ color: '#FFB366', cursor: 'default', position: 'sticky', top: 0, backgroundColor: '#30404d', zIndex: 1 }}
                            >{U.date(entry_date)}</Button>
                        )}
                        <SingleEntry entry={entry} />
                    </>
                )

            })}
        </div>
    );

}