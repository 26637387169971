import React, { useState, useEffect } from 'react';
import { Menu, MenuDivider, MenuItem, Tag, Button } from "@blueprintjs/core";
import U from '../Utilities/Utilities'
import { Link } from "react-router-dom";

export default (props) => {

    return (
        <>
            <Menu>
                {props.showIntelligence ? (
                    <>
                        <Link onClick={(event) => {
                            props.onClick && props.onClick(event, '', 'Intelligence')
                        }} to={`/intelligence`}><MenuItem text='Intelligence' icon={'search'} /></Link>
                        <MenuDivider />
                    </>
                ) : null}
                {props.searches.map((search) => {
                    if (search.type === 'folder') {
                        return (
                            <>
                                {
                                    search.title === 'Uncategorized' ? <MenuDivider /> : null
                                }
                                <MenuItem text={search.title} icon={'folder-close'} label={search.number > 0 ? <Tag round className={'tag-counter'}>{U.formatNumber(search.number)}</Tag> : null}>
                                    <div style={{ maxHeight: 500, overflowY: 'auto' }}>
                                        {
                                            search.children && search.children.length > 0 ? (
                                                search.children.map((child) => {
                                                    if (child.type === 'folder') {
                                                        return (
                                                            <>
                                                                <MenuItem text={child.title} icon={'folder-close'} label={child.number > 0 ? <Tag round className={'tag-counter'}>{U.formatNumber(child.number)}</Tag> : null}>
                                                                    {
                                                                        child.children && child.children.length ? (
                                                                            child.children.map((sub) => (
                                                                                <>
                                                                                    <Link
                                                                                        onClick={(event) => {
                                                                                            props.onClick && props.onClick(event, sub.id, sub.title)
                                                                                        }}
                                                                                        to={`/intelligence/${sub.id}`}
                                                                                    >
                                                                                        <MenuItem
                                                                                            text={sub.title}
                                                                                            icon={'search'}
                                                                                            labelElement={
                                                                                                <>
                                                                                                    {sub.number > 0 ? <Tag round className={'tag-counter'}>{U.formatNumber(sub.number)}</Tag> : null}
                                                                                                    {props.onAddToCase && (
                                                                                                        <>
                                                                                                            <Button
                                                                                                                icon={'plus'}
                                                                                                                intent={'success'}
                                                                                                                title={'Add saved search to case'}
                                                                                                                small
                                                                                                                minimal
                                                                                                                onClick={(event) => {
                                                                                                                    event.preventDefault();
                                                                                                                    event.stopPropagation();
                                                                                                                    props.onAddToCase(event, sub.id, sub.title)
                                                                                                                }}
                                                                                                            />
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            }
                                                                                        />
                                                                                    </Link>
                                                                                </>
                                                                            ))
                                                                        ) : null
                                                                    }
                                                                </MenuItem>
                                                            </>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <>
                                                                <Link
                                                                    onClick={(event) => {
                                                                        props.onClick && props.onClick(event, child.id, child.title)
                                                                    }}
                                                                    to={`/intelligence/${child.id}`}
                                                                >
                                                                    <MenuItem
                                                                        text={child.title}
                                                                        icon={'search'}
                                                                        labelElement={
                                                                            <>
                                                                                {child.number > 0 ? <Tag round className={'tag-counter'}>{U.formatNumber(child.number)}</Tag> : null}
                                                                                {props.onAddToCase && (
                                                                                    <>
                                                                                        <Button
                                                                                            icon={'plus'}
                                                                                            intent={'success'}
                                                                                            title={'Add saved search to case'}
                                                                                            small
                                                                                            minimal
                                                                                            onClick={(event) => {
                                                                                                event.preventDefault();
                                                                                                event.stopPropagation();
                                                                                                props.onAddToCase(event, child.id, child.title)
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        }
                                                                    />
                                                                </Link>
                                                            </>
                                                        )
                                                    }
                                                })
                                            ) : null
                                        }
                                    </div>
                                </MenuItem>
                            </>
                        )
                    }
                    else {
                        return <Link onClick={(event) => {
                            props.onClick && props.onClick(event, search.id, search.title)
                        }} to={`/intelligence/${search.id}`}><MenuItem text={search.title} icon={'search'} label={search.number > 0 ? <Tag round className={'tag-counter'}>{U.formatNumber(search.number)}</Tag> : null} /></Link>
                    }
                })}
            </Menu>
        </>
    )

}