import React from 'react';

import { Navbar, NavbarGroup, ControlGroup, Alignment } from '@blueprintjs/core';

import LinksButton from '../Links/Button';
import CaseSelect from '../Cases/SelectAdd';

export default (props) => {

    return (
        <>
            <Navbar style={{ padding: 10, paddingTop: 0, paddingBottom: 7, marginTop: 1, boxShadow: 'none' }}>
                <NavbarGroup>
                    <ControlGroup style={{ marginRight: 5 }}>
                        <CaseSelect
                            key={`CaseSelectTravelSecurity:${props.data._id}`}
                            articles={[props.data._id]}
                            selectedCases={props.data._source.cases && props.data._source.cases.length ? props.data._source.cases.map((c) => ({ id: c.id })) : []}
                        />
                    </ControlGroup>
                </NavbarGroup>
                <NavbarGroup align={Alignment.RIGHT}>
                    <ControlGroup>
                        <LinksButton key={`ButtonLinks-${props.data._id}`} content_id={`Travel:${props.data._id}`} />
                    </ControlGroup>
                </NavbarGroup>
            </Navbar>
            <div style={{ width: '100%', height: 'calc(100% - 50px)', overflow: 'auto', padding: 20 }} class="travelFull">
                <span dangerouslySetInnerHTML={{ __html: props.data._source.content }}></span>
            </div>
        </>
    )

}