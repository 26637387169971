import React, { useState, useEffect, useCallback } from 'react';

import { Button, Dialog, Classes, Switch } from '@blueprintjs/core'
import API from '../Utilities/API';

import { DatePicker, TimePicker } from "@blueprintjs/datetime";

import { Popover2 } from '@blueprintjs/popover2'

const Calendar = (props) => {
    const [date, setDate] = useState(props.date ?? new Date());
    const [isPublic, setPublic] = useState(props.free ?? true);

    return (
        <>
            <DatePicker
                highlightCurrentDay
                value={date}
                onChange={(new_date) => {
                    let newD = new Date(date);
                    newD.setFullYear(new_date.getFullYear() || newD.getFullYear(), new_date.getMonth(), new_date.getDate());
                    setDate(newD);
                    props.onChange && props.onChange(newD, isPublic);
                }}
            />
            <div style={{
                width: '100%',
                textAlign: 'center'
            }}>
                <TimePicker
                    value={date}
                    showArrowButtons
                    onChange={(new_date) => {
                        let newD = new Date(date);
                        newD.setHours(new_date.getHours());
                        newD.setMinutes(new_date.getMinutes());
                        setDate(newD);
                        props.onChange && props.onChange(newD, isPublic);
                    }}
                />
            </div>
            <div style={{
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: 10,
                marginBottom: 10
            }}>
                <Switch checked={isPublic} label={'Article is free (open)'} onChange={(e) => {
                    props.onChange && props.onChange(date, !isPublic);
                    setPublic(!isPublic);
                }} />
            </div>
            <div style={{
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: 10,
                marginBottom: 10
            }}>
                <Button intent={'primary'} text={'Publish'} icon={'tick'} onClick={props.onPublish} />
                <Button intent={'warning'} text={'Cancel'} icon={'cross'} onClick={() => {
                    setDate(new Date());
                    props.onHide && props.onHide();
                }} />
            </div>
        </>
    )
}

export default ({ data, onPublish = () => { }, date, free }) => {

    const [publishing, setPublishing] = useState(false);
    const [publishDate, setPublishDate] = useState(date || new Date());
    const [isFree, setFree] = useState(free ?? true);

    return (
        <>
            <Popover2
                content={<>
                    <>
                        <Calendar
                            date={date || null}
                            free={isFree}
                            onChange={(date, free) => {
                                setPublishDate(date);
                                setFree(free);
                            }}
                            onHide={() => {
                                setPublishing(false);
                            }}
                            onPublish={() => {
                                if (window.confirm('Publish?')) {
                                    API.Publish({
                                        ...data,
                                        datetime: publishDate.toISOString(),
                                        free: isFree ? '1' : '0'
                                    }).then((response) => {
                                        if (response.data.Data.error) alert(response.data.Data.error);
                                        else {
                                            onPublish && onPublish();
                                        }
                                    });
                                }
                            }}
                        />
                    </>
                </>
                }
                enforceFocus={true}
                minimal
                placement={'bottom-end'}
                interactionKind={'click'}
                usePortal={true}
                isOpen={publishing}
            >
                <Button
                    icon={'third-party'}
                    intent={'primary'}
                    onClick={() => {
                        if (data.text.length < 5 || data.title.length < 5) {
                            alert('You need to fill text and title to publish!');
                        } else {
                            setPublishing(true);
                        }
                    }}
                >
                    Publish
                </Button>
            </Popover2>
        </>
    )

}
/*

*/